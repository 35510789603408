import React, { useState } from 'react';
import { Transition } from '@headlessui/react';
import {
  ExclamationCircleIcon,
  CheckCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { twMerge } from 'tailwind-merge';

const defaultStyles = {
  success: {
    bgColor: 'bg-green-50',
    titleColor: 'text-green-700',
    textColor: 'text-green-600',
    borderColor: 'border-green-700',
    titleSize: 'text-base',
    descriptionSize: 'text-sm',
  },
  error: {
    bgColor: 'bg-red-50',
    titleColor: 'text-red-700',
    textColor: 'text-red-600',
    borderColor: 'border-red-700',
    titleSize: 'text-base',
    descriptionSize: 'text-sm',
  },
  info: {
    bgColor: 'bg-[#EAF2FA]', // Adjust this color if necessary
    titleColor: 'text-[#224E73]',
    textColor: 'text-[#34678C]',
    borderColor: 'border-[#224E73]',
    titleSize: 'text-base',
    descriptionSize: 'text-sm',
  },
  warning: {
    bgColor: 'bg-yellow-50',
    titleColor: 'text-yellow-700',
    textColor: 'text-yellow-600',
    borderColor: 'border-yellow-700',
    titleSize: 'text-base',
    descriptionSize: 'text-sm',
  },
};

// Define the types for the component props
interface CustomAlertProps {
  type: 'success' | 'error' | 'info' | 'warning';
  message: string;
  id?: string;
  afterClose?: () => void;
  options?: {
    bgColor?: string;
    textColor?: string;
    titleColor?: string;
    borderColor?: string;
    defaultColors?: boolean;
    iconStyles?: string;
    parentClasses?: string;
    titleSize?: string;
  };
  [key: string]: any; // To allow any additional props
}

const CustomAlert: React.FC<CustomAlertProps> = ({
  type,
  message,
  id = 'custom-alert',
  afterClose,
  options = {},
}) => {
  const [isVisible, setIsVisible] = useState(true);

  const {
    bgColor,
    textColor,
    titleColor,
    borderColor,
    defaultColors = false,
    parentClasses,
    titleSize,
    iconStyles = 'w-5 h-5 mr-2',
  } = options;

  const styles = defaultColors
    ? defaultStyles[type]
    : { bgColor, textColor, titleColor, borderColor, titleSize };

  const iconMap = {
    success: <CheckCircleIcon className={iconStyles + ` ${styles.titleColor}`} />,
    error: <XCircleIcon className={iconStyles + ` ${styles.titleColor}`} />,
    info: <InformationCircleIcon className={iconStyles + ` ${styles.titleColor}`} />,
    warning: <ExclamationCircleIcon className={iconStyles + ` ${styles.titleColor}`} />,
  };

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(() => {
      if (afterClose) afterClose();
    }, 300); // Wait for transition to complete
  };

  return (
    <Transition
      show={isVisible}
      enter='transition-opacity duration-300'
      enterFrom='opacity-0'
      enterTo='opacity-100'
      leave='transition-opacity duration-300'
      leaveFrom='opacity-100'
      leaveTo='opacity-0'
    >
      <div
        className={twMerge(
          `${styles.bgColor} ${styles.borderColor} mb-4 rounded-lg border-2 ${parentClasses}`,
        )}
        style={{ width: '100%', borderStyle: 'solid' }}
        id={id}
      >
        <div className='flex items-center justify-between p-4'>
          <div className={'flex items-center ' + styles.titleSize}>
            {iconMap[type]}
            <span className={`${styles.titleColor}`}>{message}</span>
          </div>
          <button
            onClick={handleClose}
            className={`${styles.titleColor} hover:opacity-70 transition-opacity`}
          >
            <XMarkIcon className='w-5 h-5' />
          </button>
        </div>
      </div>
    </Transition>
  );
};

export default CustomAlert;
