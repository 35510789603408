import React, { useEffect, useState, useCallback } from 'react';
import {
  TrashIcon,
  DocumentDuplicateIcon,
  EyeIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  PencilIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import CustomPopover from '../components/common/CustomPopover';
import SlideOver from '../components/common/SlideOver';
import HeaderWithArrow from '../components/common/HeaderWithArrow';
import AddItemsInBox from './AddItemsInBox';
import CustomPopconfirm from '../components/common/CustomPopconfirm';
import ViewPackedItems from './ViewPackedItems';
import EditBoxItems from './EditBoxItems';
import ModalV2 from '../components/common/ModalV2';
import Button from '../components/common/Button';
import ConfirmationModal from '../components/common/ConfirmationModal';
import CustomAlert from '../components/common/CustomAlert';

const FBA_BOX_WEIGHT_LIMIT = 50;
const FBA_BOX_MECHANICAL_LIFT_WARNING_WEIGHT_LIMIT = 100;

// Type definitions
interface Dimensions {
  length: number;
  width: number;
  height: number;
}

interface BoxItem {
  asin: string;
  msku: string;
  fnSku: string;
  name: string;
  quantity: number;
  image?: string;
  bestByDate?: string;
  fnsku?: string;
  prepInstructions?: any;
}

interface Box {
  boxName: string;
  items: BoxItem[];
  dimensions: Dimensions;
  weight: number;
  assignedQuantity: number;
  source: string;
}

interface Group {
  groupName: string;
  totalBoxes: number;
  totalItems: number;
  items: BoxItem[];
  packingGroupId: string;
  assignedItemsNo: number;
  assignedItems: BoxItem[];
  boxes: Box[];
  currentSource?: string;
}

interface BoxType {
  name: string;
  length: number;
  width: number;
  height: number;
}

interface PackingBoxInformationProps {
  packingOptions: any[]; // Define specific type if possible
  selectedPackingOption: string;
  boxTypes: BoxType[];
  formattedGroups: any[];
  setFormattedGroups: (group: any) => void;
  isLtlFlow?: boolean;
  setShowNotification: (notification: { show: boolean; type: string; content: string }) => void;
}

interface EntityForViewItems {
  header: string;
  description: string;
  items: BoxItem[];
  totalItems: number;
  totalProducts: number;
  isGroup?: boolean;
}

interface BoxDimensions {
  height: string;
  width: string;
  length: string;
}

interface ConfirmationConfig {
  title: string;
  content: React.ReactNode;
  onConfirm: () => void;
  onClose?: () => void;
  confirmText?: string;
  cancelText?: string;
}

const PackingBoxInformation: React.FC<PackingBoxInformationProps> = ({
  packingOptions,
  selectedPackingOption,
  boxTypes,
  formattedGroups,
  setFormattedGroups,
  isLtlFlow = false,
  setShowNotification,
}) => {
  // Custom hooks
  const preferredDimensionUnit = 'in';
  const preferredWeightUnit = 'lbs';

  // State
  const [expandedGroups, setExpandedGroups] = useState<string[]>([]);
  const [expandedBoxes, setExpandedBoxes] = useState<Record<string, string[]>>({});
  const [originalFormattedGroups, setOriginalFormattedGroups] = useState<any[]>([]);
  const [groupSelectedToManualAddItems, setGroupSelectedToManualAddItems] = useState<{
    group: any;
    boxIndex: number;
  } | null>(null);
  const [openManualAddItemsSlideOverPanel, setOpenManualAddItemsSlideOverPanel] =
    useState<boolean>(false);
  const [openViewItemsSlideOverPanel, setOpenViewItemsSlideOverPanel] = useState<boolean>(false);
  const [entitySelectedForViewItems, setEntitySelectedForViewItems] = useState<EntityForViewItems>({
    header: '',
    description: '',
    items: [],
    totalItems: 0,
    totalProducts: 0,
  });
  const [openEditBoxItemsPanel, setOpenEditBoxItemsPanel] = useState<boolean>(false);
  const [selectedBoxForEdit, setSelectedBoxForEdit] = useState<{
    box: Box;
    group: any;
  } | null>(null);
  const [isBoxDimensionsModalOpen, setIsBoxDimensionsModalOpen] = useState<boolean>(false);
  const [boxDimensionsDetails, setBoxDimensionsDetails] = useState<BoxDimensions>({
    height: '',
    width: '',
    length: '',
  });
  const [selectedGroup, setSelectedGroup] = useState<any | null>(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationConfig | null>(null);

  // Handler Functions
  const handleBoxTypes = useCallback(
    (group: any) => {
      return boxTypes?.map((type: BoxType) => ({
        name: `${type.name} ${type.length} * ${type.width} * ${type.height} (in)`,
        icon: '',
        onClick: () => handleFitInOneBoxConfirmation(type, group),
      }));
    },
    [boxTypes],
  );

  const showConfirmation = (config: ConfirmationConfig) => {
    setConfirmationConfig(config);
    setShowConfirmationModal(true);
  };

  const handleSourceChange = (groupName: string, newSource: string): void => {
    setFormattedGroups((prevGroups) =>
      prevGroups.map((group) => {
        if (group.groupName !== groupName) return group;

        const updatedBoxes = group.boxes.map((box) => ({
          ...box,
          source: newSource,
        }));

        return {
          ...group,
          currentSource: newSource,
          boxes: updatedBoxes,
        };
      }),
    );
  };

  const checkFbaBoxWeightLimits = (box, workingList) => {
    if (!box || !workingList) {
      return { isValid: true };
    }
    const boxWeight = parseFloat(box.weight);
    const itemsInBox = workingList.filter((item) => item.boxName === box.name);

    if (boxWeight > FBA_BOX_WEIGHT_LIMIT) {
      if (itemsInBox.length > 1) {
        // Multiple items in box exceeding 50 lbs - not allowed
        return {
          isValid: false,
          message: 'Boxes with multiple items cannot exceed 50 pounds.',
        };
      } else {
        // Single item exceeding weight limit - show appropriate warning
        if (boxWeight > FBA_BOX_MECHANICAL_LIFT_WARNING_WEIGHT_LIMIT) {
          return {
            isValid: true,
            warning: 'Mechanical Lift labels required on top and sides of box',
          };
        } else {
          return {
            isValid: true,
            warning: 'Team Lift labels required on top and sides of box',
          };
        }
      }
    }

    return { isValid: true };
  };

  const generateBoxName = useCallback((group: Group): string => {
    const existingNumbers = group.boxes
      .map((box) => {
        const match = box.boxName.match(/^Box-(\d+)$/);
        return match ? parseInt(match[1]) : 0;
      })
      .filter((num) => !isNaN(num));

    let newNumber = 1;
    while (existingNumbers.includes(newNumber)) {
      newNumber++;
    }

    return `Box-${newNumber}`;
  }, []);

  const handleEditBoxItems = useCallback(
    (changes: { updatedItems: BoxItem[]; quantityChanges: Record<string, number> }) => {
      const { updatedItems, quantityChanges } = changes;

      setFormattedGroups((prevGroups) =>
        prevGroups.map((prevGroup) => {
          if (
            !selectedBoxForEdit ||
            prevGroup.packingGroupId !== selectedBoxForEdit.group.packingGroupId
          )
            return prevGroup;

          // Update group items to reflect returns to inventory
          const updatedGroupItems = prevGroup.items.map((groupItem) => {
            const quantityChange = quantityChanges[groupItem.msku];
            if (quantityChange === undefined) return groupItem;

            return {
              ...groupItem,
              quantity:
                groupItem.quantity +
                (quantityChange < 0 ? Math.abs(quantityChange) : -quantityChange),
            };
          });

          // Update boxes
          const updatedBoxes = prevGroup.boxes.map((box) => {
            if (box.boxName !== selectedBoxForEdit.box.boxName) return box;

            return {
              ...box,
              items: updatedItems,
              assignedQuantity: updatedItems.reduce(
                (total, item) => total + Number(item.quantity),
                0,
              ),
            };
          });

          // Recalculate assigned items from all boxes
          const allBoxItems = updatedBoxes.flatMap((box) => box.items || []);
          const consolidatedAssignedItems = allBoxItems.reduce((acc: BoxItem[], item) => {
            const existingItem = acc.find((i) => i.asin === item.asin);
            if (existingItem) {
              existingItem.quantity += Number(item.quantity);
            } else {
              acc.push({ ...item, quantity: Number(item.quantity) });
            }
            return acc;
          }, []);

          const totalAssignedQuantity = consolidatedAssignedItems.reduce(
            (total, item) => total + Number(item.quantity),
            0,
          );

          return {
            ...prevGroup,
            boxes: updatedBoxes,
            items: updatedGroupItems,
            assignedItems: consolidatedAssignedItems,
            assignedItemsNo: totalAssignedQuantity,
          };
        }),
      );

      setOpenEditBoxItemsPanel(false);
      setSelectedBoxForEdit(null);
      setShowNotification({
        show: true,
        type: 'success',
        content: 'Box items updated successfully',
      });
    },
    [selectedBoxForEdit, setShowNotification],
  );

  const handleManualItemAddition = useCallback(
    (selectedItems: any[], group: any, boxIndex: number) => {
      setFormattedGroups((prevGroups) =>
        prevGroups.map((prevGroup) => {
          if (prevGroup.packingGroupId !== group.packingGroupId) return prevGroup;

          const updatedBoxes = prevGroup.boxes.map((box, index) => {
            if (index !== boxIndex) return box;

            const updatedBoxItems = [...box.items];
            const addedQuantity = selectedItems.reduce((total, item) => {
              const existingItemIndex = updatedBoxItems.findIndex(
                (boxItem) => boxItem.asin === item.asin,
              );

              if (existingItemIndex !== -1) {
                updatedBoxItems[existingItemIndex].quantity += item.quantity;
              } else {
                updatedBoxItems.push({
                  ...item,
                  quantity: item.quantity,
                });
              }
              return total + item.quantity;
            }, 0);

            return {
              ...box,
              items: updatedBoxItems,
              assignedQuantity: box.assignedQuantity + addedQuantity,
            };
          });

          const allBoxItems = updatedBoxes.flatMap((box) => box.items);
          const consolidatedAssignedItems = allBoxItems.reduce((acc: BoxItem[], item) => {
            const existingItem = acc.find((i) => i.asin === item.asin);
            if (existingItem) {
              existingItem.quantity += item.quantity;
            } else {
              acc.push({ ...item });
            }
            return acc;
          }, []);

          const updatedItems = prevGroup.items.map((groupItem) => {
            const selectedItem = selectedItems.find((item) => item.asin === groupItem.asin);
            return selectedItem
              ? {
                  ...groupItem,
                  quantity: Math.max(0, groupItem.quantity - selectedItem.quantity),
                }
              : groupItem;
          });

          return {
            ...prevGroup,
            boxes: updatedBoxes,
            items: updatedItems,
            assignedItemsNo:
              prevGroup.assignedItemsNo +
              selectedItems.reduce((total, item) => total + item.quantity, 0),
            assignedItems: consolidatedAssignedItems,
          };
        }),
      );
    },
    [],
  );

  const handleItemsInBoxes = useCallback((assignAll: boolean, group: Group): void => {
    setFormattedGroups((prevGroups) =>
      prevGroups.map((prevGroup) => {
        if (prevGroup.packingGroupId !== group.packingGroupId) return prevGroup;

        const lastBoxIndex = prevGroup.boxes.length - 1;
        if (lastBoxIndex < 0) return prevGroup;

        const remainingItems = group.items
          .filter((groupItem) => groupItem.quantity > 0)
          .map((groupItem) => ({ ...groupItem }));

        const assignedItemCount = assignAll
          ? remainingItems.reduce((total, item) => total + item.quantity, 0)
          : 0;

        const updatedLastBox = {
          ...prevGroup.boxes[lastBoxIndex],
          items: assignAll ? remainingItems : prevGroup.boxes[lastBoxIndex].items,
          assignedQuantity:
            prevGroup.boxes[lastBoxIndex].assignedQuantity + (assignAll ? assignedItemCount : 0),
        };

        const allBoxItems = [
          ...prevGroup.boxes.slice(0, lastBoxIndex).flatMap((box) => box.items),
          ...updatedLastBox.items,
        ];

        const consolidatedAssignedItems = allBoxItems.reduce((acc: BoxItem[], item) => {
          const existingItem = acc.find((i) => i.asin === item.asin);
          if (existingItem) {
            existingItem.quantity += item.quantity;
          } else {
            acc.push({ ...item });
          }
          return acc;
        }, []);

        return {
          ...prevGroup,
          items: assignAll
            ? prevGroup.items.map((item) => ({ ...item, quantity: 0 }))
            : prevGroup.items,
          assignedItemsNo: prevGroup.assignedItemsNo + (assignAll ? assignedItemCount : 0),
          assignedItems: consolidatedAssignedItems,
          boxes: [...prevGroup.boxes.slice(0, lastBoxIndex), updatedLastBox],
        };
      }),
    );
  }, []);

  const handleAddCustomBox = useCallback((group: Group): void => {
    setSelectedGroup(group);
    setBoxDimensionsDetails({ height: '', width: '', length: '' });
    setIsBoxDimensionsModalOpen(true);
  }, []);

  const handleBoxInGroups = useCallback(
    (type, group) => {
      setFormattedGroups((prevGroups) =>
        prevGroups.map((prevGroup) => {
          if (prevGroup.packingGroupId !== group.packingGroupId) return prevGroup;

          const currentGroupSource =
            prevGroup.currentSource ||
            (prevGroup.boxes.length > 0 ? prevGroup.boxes[0].source : 'BOX_CONTENT_PROVIDED');

          const newBoxName = generateBoxName(prevGroup);

          const newBox = {
            boxName: newBoxName,
            items: [],
            dimensions: {
              length: type.length,
              width: type.width,
              height: type.height,
            },
            weight: 0,
            assignedQuantity: 0,
            source: currentGroupSource,
          };

          // Only expand this group
          setExpandedGroups([prevGroup.groupName]);

          // Only expand the new box
          setExpandedBoxes({
            [prevGroup.groupName]: [newBoxName],
          });

          return {
            ...prevGroup,
            boxes: [...prevGroup.boxes, newBox],
          };
        }),
      );
    },
    [generateBoxName],
  );

  const handleDimensionsSubmit = (): void => {
    if (
      !boxDimensionsDetails.height ||
      !boxDimensionsDetails.length ||
      !boxDimensionsDetails.width
    ) {
      setShowNotification({
        show: true,
        type: 'warning',
        content: 'Please enter all dimensions',
      });
      return;
    }

    if (!selectedGroup) return;

    handleFitInOneBoxConfirmation(
      {
        name: 'CustomBoxAdded',
        height: parseFloat(boxDimensionsDetails.height) || 0,
        width: parseFloat(boxDimensionsDetails.width) || 0,
        length: parseFloat(boxDimensionsDetails.length) || 0,
      },
      selectedGroup,
    );
    setIsBoxDimensionsModalOpen(false);
  };

  const handleFitInOneBoxConfirmation = useCallback(
    (type: BoxType, group: Group): void => {
      if (type.name === 'Add Custom') {
        handleAddCustomBox(group);
        return;
      }

      const totalQuantityRemaining = group.items.reduce((sum, item) => sum + item.quantity, 0);

      if (group.boxes.length === 0 && totalQuantityRemaining >= 1) {
        showConfirmation({
          title: 'Box Fit Confirmation',
          content: 'Will all these Items fit into 1 box?',
          onConfirm: () => {
            handleBoxInGroups(type, group);
            handleItemsInBoxes(false, group);
          },
          onClose: () => {
            handleBoxInGroups(type, group);
            handleItemsInBoxes(true, group);
          },
          cancelText: 'Yes, all the items can be added into one box',
          confirmText: 'No, Multiple Boxes will be needed',
        });
      } else {
        // For subsequent boxes or when group has only 1 item, just create the box without confirmation
        handleBoxInGroups(type, group);
      }
    },
    [handleAddCustomBox, handleBoxInGroups, handleItemsInBoxes, showConfirmation],
  );

  const transformPackingGroupsData = useCallback(
    (data: any[]): Group[] => {
      return data?.map((group, groupIndex) => {
        const totalItems = group.items.reduce((total: number, item: BoxItem) => {
          const itemQuantity = Number(item.quantity);
          return total + (isNaN(itemQuantity) ? 0 : itemQuantity);
        }, 0);

        return {
          groupName: `Group ${groupIndex + 1}`,
          totalBoxes: 0,
          totalItems,
          items: group.items.map((item: BoxItem) => ({
            ...item,
            prepOwner: item.prepInstructions?.[0]?.prepOwner,
            quantity: Number(item.quantity) || 0,
          })),
          packingGroupId: isLtlFlow ? group.shipmentId : group.packingGroupId,
          assignedItemsNo: 0,
          assignedItems: [],
          boxes:
            group.boxes?.map((box: Box) => ({
              ...box,
              assignedQuantity: Number(box.assignedQuantity) || 0,
              items:
                box.items?.map((item: BoxItem) => ({
                  ...item,
                  quantity: Number(item.quantity) || 0,
                })) || [],
              source: 'BOX_CONTENT_PROVIDED',
            })) || [],
        };
      });
    },
    [isLtlFlow],
  );

  const handleConfirmationForUncpackTheBoxes = (group: Group): void => {
    showConfirmation({
      title: 'Confirm',
      content: 'Are you sure you want to unpack all boxes?',
      onConfirm: () => {
        handleUnpackAllBoxes(group);
      },
      onClose: () => {},
    });
  };

  const handleUnpackAllBoxes = useCallback(
    (group: Group): void => {
      setFormattedGroups((prevGroups) =>
        prevGroups.map((prevGroup) => {
          if (prevGroup.packingGroupId !== group.packingGroupId) return prevGroup;

          const originalGroup = originalFormattedGroups.find(
            (g) => g.packingGroupId === prevGroup.packingGroupId,
          );

          if (!originalGroup) return prevGroup;

          return {
            ...originalGroup,
            boxes: prevGroup.boxes.map((box) => ({
              ...box,
              items: [],
              assignedQuantity: 0,
            })),
          };
        }),
      );
      setShowNotification({
        show: true,
        type: 'success',
        content: `All boxes unpacked successfully from ${group.groupName}`,
      });
    },
    [originalFormattedGroups, setShowNotification],
  );

  const handleDeleteBox = useCallback(
    (group: Group, box: Box): void => {
      setFormattedGroups((prevGroups) =>
        prevGroups.map((prevGroup) => {
          if (prevGroup.packingGroupId !== group.packingGroupId) return prevGroup;

          const originalGroup = originalFormattedGroups.find(
            (g) => g.packingGroupId === group.packingGroupId,
          );

          if (!originalGroup) return prevGroup;

          const updatedItems = prevGroup.items.map((item) => {
            const boxItem = box.items.find((bi) => bi.msku === item.msku);
            if (!boxItem) return item;

            const originalQuantity = originalGroup.items.find((oi) => oi.msku === item.msku)
              ?.quantity;

            return {
              ...item,
              quantity: Math.min(
                item.quantity + boxItem.quantity,
                originalQuantity || item.quantity,
              ),
            };
          });

          const remainingBoxes = prevGroup.boxes.filter((b) => b.boxName !== box.boxName);

          const allRemainingBoxItems = remainingBoxes.flatMap((box) => box.items);
          const consolidatedAssignedItems = allRemainingBoxItems.reduce((acc: BoxItem[], item) => {
            const existingItem = acc.find((i) => i.asin === item.asin);
            if (existingItem) {
              existingItem.quantity += item.quantity;
            } else {
              acc.push({ ...item });
            }
            return acc;
          }, []);

          const newAssignedItemsNo = consolidatedAssignedItems.reduce(
            (total, item) => total + (Number(item.quantity) || 0),
            0,
          );

          return {
            ...prevGroup,
            totalBoxes: prevGroup.totalBoxes - 1,
            items: updatedItems,
            assignedItems: consolidatedAssignedItems,
            assignedItemsNo: newAssignedItemsNo,
            boxes: remainingBoxes,
          };
        }),
      );

      setShowNotification({
        show: true,
        type: 'success',
        content: `Box "${box.boxName}" has been deleted.`,
      });
    },
    [originalFormattedGroups, setShowNotification],
  );

  const handleDuplicateBox = useCallback(
    (group: Group, boxToDuplicate: Box): void => {
      const canDuplicate = boxToDuplicate.items.every((item) => {
        const remainingQuantity = group.items.find((gi) => gi.msku === item.msku)?.quantity || 0;
        return remainingQuantity >= item.quantity;
      });

      if (!canDuplicate) {
        setShowNotification({
          show: true,
          type: 'warning',
          content: 'Not enough remaining items in the group to duplicate box contents',
        });
        return;
      }

      setFormattedGroups((prevGroups) =>
        prevGroups.map((prevGroup) => {
          if (prevGroup.packingGroupId !== group.packingGroupId) return prevGroup;

          const duplicatedBox: Box = {
            boxName: generateBoxName(prevGroup),
            items: [],
            dimensions: { ...boxToDuplicate.dimensions },
            weight: boxToDuplicate.weight,
            assignedQuantity: 0,
            source: boxToDuplicate.source,
          };

          duplicatedBox.items = boxToDuplicate.items.map((item) => ({
            ...item,
            quantity: item.quantity,
          }));

          duplicatedBox.assignedQuantity = duplicatedBox.items.reduce(
            (total, item) => total + item.quantity,
            0,
          );

          // Update group inventory...
          const updatedItems = prevGroup.items.map((groupItem) => {
            const duplicatedItem = duplicatedBox.items.find((bi) => bi.msku === groupItem.msku);
            if (duplicatedItem) {
              return {
                ...groupItem,
                quantity: groupItem.quantity - duplicatedItem.quantity,
              };
            }
            return groupItem;
          });

          // Calculate consolidated items...
          const allBoxItems = [...prevGroup.boxes.flatMap((b) => b.items), ...duplicatedBox.items];

          const consolidatedAssignedItems = allBoxItems.reduce((acc, item) => {
            const existingItem = acc.find((i) => i.asin === item.asin);
            if (existingItem) {
              existingItem.quantity += item.quantity;
            } else {
              acc.push({ ...item });
            }
            return acc;
          }, []);

          const totalAssignedQuantity = consolidatedAssignedItems.reduce(
            (total, item) => total + item.quantity,
            0,
          );

          return {
            ...prevGroup,
            boxes: [...prevGroup.boxes, duplicatedBox],
            items: updatedItems,
            assignedItems: consolidatedAssignedItems,
            assignedItemsNo: totalAssignedQuantity,
          };
        }),
      );

      setShowNotification({
        show: true,
        type: 'success',
        content: 'Box duplicated successfully',
      });
    },
    [generateBoxName, setShowNotification],
  );

  const handleBoxWeightChange = useCallback((group: Group, box: Box, newWeight: string): void => {
    setFormattedGroups((prevGroups) =>
      prevGroups.map((prevGroup) => {
        if (prevGroup.packingGroupId !== group.packingGroupId) return prevGroup;

        const updatedBoxes = prevGroup.boxes.map((prevBox) => {
          if (prevBox.boxName !== box.boxName) return prevBox;

          return {
            ...prevBox,
            weight: parseFloat(newWeight) || 0,
          };
        });

        return {
          ...prevGroup,
          boxes: updatedBoxes,
        };
      }),
    );
  }, []);

  const hasRemainingInventory = useCallback((group: Group): boolean => {
    return group.items.some((item) => (Number(item.quantity) || 0) > 0);
  }, []);

  const toggleGroup = useCallback((groupName: string): void => {
    setExpandedGroups((prev) => {
      if (prev.includes(groupName)) {
        return [];
      }
      return [groupName];
    });
    setExpandedBoxes({});
  }, []);

  const toggleBox = useCallback((groupName: string, boxName: string): void => {
    setExpandedBoxes((prev) => {
      if (prev[groupName]?.includes(boxName)) {
        return {
          ...prev,
          [groupName]: [],
        };
      }
      return {
        ...prev,
        [groupName]: [boxName],
      };
    });
  }, []);

  useEffect(() => {
    if (packingOptions?.length > 0 && !isLtlFlow) {
      const selectedOption = packingOptions.find(
        (option) => option.packingOptionId === selectedPackingOption,
      );
      if (selectedOption) {
        const formatted = transformPackingGroupsData(selectedOption.packingGroupsWithGroupItems);
        setFormattedGroups(formatted);
        setOriginalFormattedGroups(formatted);
      }
    } else if (packingOptions?.length > 0 && isLtlFlow) {
      const selectedOption = packingOptions.find(
        (option) => option.placementOptionId === selectedPackingOption,
      );
      if (selectedOption) {
        const formatted = transformPackingGroupsData(selectedOption.shipmentsWithBoxesAndItems);
        setFormattedGroups(formatted);
        setOriginalFormattedGroups(formatted);
      }
    }
  }, [packingOptions, selectedPackingOption, transformPackingGroupsData, isLtlFlow]);

  return (
    <div className='font-inter'>
      {formattedGroups.map((group) => (
        <div key={group.groupName} className='mb-4 rounded-lg border border-gray-200 bg-gray-50'>
          {/* Group Header */}
          <div className='flex cursor-pointer items-center justify-between p-3 hover:bg-gray-100'>
            <div className='flex items-center'>
              {/* Expand Icon */}
              {group.boxes.length > 0 &&
                (expandedGroups.includes(group.groupName) ? (
                  <ChevronDownIcon
                    onClick={() => toggleGroup(group.groupName)}
                    className='mr-2 h-5 w-5 cursor-pointer text-gray-600'
                  />
                ) : (
                  <ChevronRightIcon
                    onClick={() => toggleGroup(group.groupName)}
                    className='mr-2 h-5 w-5 cursor-pointer text-gray-600'
                  />
                ))}
              <span className='text-base font-semibold text-gray-800'>{group.groupName}</span>
            </div>
            <div className='flex items-center gap-8 text-sm text-gray-500'>
              <div className='flex items-center gap-2'>
                <span>
                  {group.assignedItemsNo}/{group.totalItems} Items Assigned
                </span>
                <EyeIcon
                  onClick={() => {
                    // Create a map of assigned quantities per SKU
                    const groupGroupedItems = group?.assignedItems?.reduce((acc, item) => {
                      const key = item.sku;
                      if (!acc[key]) {
                        // Find the original item to get total quantity
                        const originalItem = group.items.find(
                          (origItem) => origItem.sku === item.sku,
                        );

                        // Calculate available quantity (unprocessed items)
                        const availableQuantity = originalItem?.quantity || 0;
                        const assignedQuantity = group.assignedItems.filter(
                          (assignedItem) => assignedItem.sku === item.sku,
                        ).length;

                        acc[key] = {
                          ...item,
                          assignedQuantity: assignedQuantity,
                          quantity: availableQuantity, // This is available quantity
                          productName: originalItem?.name || item.name,
                          image: originalItem?.image || item.image,
                          fnSku: originalItem?.fnsku || item.fnsku,
                          sku: item.sku,
                          asin: item.asin,
                          prepInstructions: originalItem?.prepInstructions || item.prepInstructions,
                        };
                      }
                      return acc;
                    }, {});
                    group.items.forEach((item) => {
                      if (!groupGroupedItems[item.sku]) {
                        const assignedQuantity = group.assignedItems.filter(
                          (assignedItem) => assignedItem.sku === item.sku,
                        ).length;

                        groupGroupedItems[item.sku] = {
                          ...item,
                          assignedQuantity: assignedQuantity,
                          quantity: item.quantity, // Available quantity
                          productName: item.name,
                          prepInstructions: item.prepInstructions,
                        };
                      }
                    });

                    setEntitySelectedForViewItems({
                      header: `${group.groupName} : Item Details`,
                      description:
                        'Review the products and their quantities packed into this box...',
                      items: Object.values(groupGroupedItems),
                      totalItems: group.totalItems,
                      totalProducts: group.items.length,
                      isGroup: true,
                    });
                    setOpenViewItemsSlideOverPanel(true);
                  }}
                  className='h-5 w-5 cursor-pointer text-primaryAccent'
                />
              </div>
              <div className='flex items-center gap-2'>
                <label htmlFor={`source-${group.groupName}`} className='text-sm text-gray-500'>
                  Box Content Source:
                </label>
                <select
                  id={`source-${group.groupName}`}
                  value={group.boxes[0]?.source || 'BOX_CONTENT_PROVIDED'} // Get source from first box
                  onChange={(e) => handleSourceChange(group.groupName, e.target.value)}
                  className='rounded-md border border-gray-300 bg-white px-2 py-1 text-sm text-gray-700 focus:border-hopstack-blue-700 focus:outline-none focus:ring-1 focus:ring-hopstack-blue-700'
                >
                  <option value='BOX_CONTENT_PROVIDED'>Hopstack</option>
                  <option value='BARCODE_2D'>2D Barcode</option>
                  {/* <option value='MANUAL_PROCESS'>Amazon</option> */}
                </select>
              </div>
              <CustomPopover
                actions={handleBoxTypes(group)}
                styles={{ top: '1rem' }}
                bgColorVisible={false}
                triggerElement={(open) => (
                  <Button
                    disabled={!hasRemainingInventory(group)}
                    variant='secondary'
                    className=':focus:border-none border-none text-sm underline'
                  >
                    {open ? 'Select Box type' : '+Add Box'}
                    {open ? (
                      <ChevronRightIcon className='ml-1 h-4 w-4' />
                    ) : (
                      <ChevronDownIcon className='ml-1 h-4 w-4' />
                    )}
                  </Button>
                )}
              />
            </div>
          </div>
          {/* Group Content */}
          {expandedGroups.includes(group.groupName) && (
            <div className='space-y-4 bg-white p-4'>
              {group.boxes.length > 0 && (
                <div className='flex items-center gap-2'>
                  <span className='text-sm text-gray-500'>Multiple Boxes</span>
                  <button
                    onClick={() => {
                      handleConfirmationForUncpackTheBoxes(group);
                    }}
                    className='text-sm font-medium text-hopstack-blue-700 underline'
                  >
                    Unpack All Boxes
                  </button>
                </div>
              )}
              {group.boxes.map((box, boxIndex) => (
                <div key={box.boxName} className='rounded-lg border border-gray-200'>
                  {/* Box Header */}
                  <div className='flex cursor-pointer items-center justify-between bg-gray-50 p-4 hover:bg-gray-100'>
                    <div className='flex items-center'>
                      {/* Expand Icon */}
                      {expandedBoxes[group.groupName]?.includes(box.boxName) ? (
                        <ChevronDownIcon
                          onClick={() => toggleBox(group.groupName, box.boxName)}
                          className='mr-2 h-5 w-5 text-gray-600'
                        />
                      ) : (
                        <ChevronRightIcon
                          onClick={() => toggleBox(group.groupName, box.boxName)}
                          className='mr-2 h-5 w-5 text-gray-600'
                        />
                      )}
                      <span className='text-base font-semibold text-gray-800'>{box.boxName}</span>
                      <div className='ml-4 flex items-center gap-2'>
                        <span className='text-sm text-gray-500'>
                          {box.assignedQuantity} Items Assigned
                        </span>
                      </div>
                    </div>
                    <div className='flex items-center gap-4'>
                      {box.items.length > 0 && (
                        <>
                          <EyeIcon
                            onClick={() => {
                              setEntitySelectedForViewItems({
                                header: `${group.groupName} : ${box.boxName} : Item Details`,
                                description:
                                  'Review the products and their quantities packed into this box...',
                                items: box.items,
                                totalItems: box.assignedQuantity,
                                totalProducts: box.items.length,
                              });
                              setOpenViewItemsSlideOverPanel(true);
                            }}
                            className='h-5 w-5 cursor-pointer text-hopstack-blue-700'
                          />
                          <PencilIcon
                            onClick={() => {
                              setSelectedBoxForEdit({ box, group });
                              setOpenEditBoxItemsPanel(true);
                            }}
                            className='h-5 w-5 cursor-pointer text-hopstack-blue-700'
                          />
                        </>
                      )}
                      <DocumentDuplicateIcon
                        onClick={() => {
                          if (!hasRemainingInventory(group)) {
                            setShowNotification({
                              show: true,
                              type: 'warning',
                              content:
                                'All items in this group have already been assigned to boxes',
                            });
                            return;
                          }
                          handleDuplicateBox(group, box);
                        }}
                        className='h-5 w-5 cursor-pointer text-hopstack-blue-700'
                      />
                      <CustomPopconfirm
                        title='Are you sure to delete this box?'
                        onConfirm={() => handleDeleteBox(group, box)}
                        onCancel={() => {}}
                        okText='Yes'
                        cancelText='No'
                        okButtonProps={{
                          className: 'bg-hopstack-blue-700 text-white',
                        }}
                        cancelButtonProps={{
                          className:
                            'bg-white border rounded border-hopstack-blue-700 text-hopstack-blue-700',
                        }}
                      >
                        <TrashIcon className='h-5 w-5 cursor-pointer text-hopstack-blue-700' />
                      </CustomPopconfirm>
                    </div>
                  </div>

                  {/* Box Details */}
                  {expandedBoxes[group.groupName]?.includes(box.boxName) && (
                    <div className='bg-white p-4 px-6'>
                      <div className='grid grid-cols-12 items-center gap-4'>
                        <div className='col-span-4'>
                          <span className='text-sm font-medium text-gray-500'>
                            Dimensions (l*w*h) ({preferredDimensionUnit})
                          </span>
                          <div className='mt-2 flex flex-col p-2'>
                            {' '}
                            <span>
                              {box.dimensions.length +
                                '*' +
                                box.dimensions.width +
                                '*' +
                                box.dimensions.height}
                            </span>
                          </div>
                        </div>

                        <div className='col-span-6'>
                          <div className='mt-2 flex items-center gap-2'>
                            <span className='text-sm font-medium text-gray-500'>
                              Add Items Into Box
                            </span>
                            <button>
                              <PlusIcon
                                onClick={() => {
                                  if (!hasRemainingInventory(group)) {
                                    setShowNotification({
                                      show: true,
                                      type: 'warning',
                                      content:
                                        'All items in this group have already been assigned to boxes',
                                    });
                                    return;
                                  }
                                  setGroupSelectedToManualAddItems({
                                    group,
                                    boxIndex,
                                  });
                                  setOpenManualAddItemsSlideOverPanel(true);
                                }}
                                className='h-5 w-5'
                              />
                            </button>
                          </div>
                        </div>

                        <div className='col-span-2'>
                          <span className='text-sm font-normal text-gray-500'>
                            Weight ({preferredWeightUnit})
                          </span>
                          <input
                            type='number'
                            className='mt-2 w-full rounded-md border border-gray-300 p-2 text-gray-700'
                            value={box.weight || ''}
                            onChange={(e) => {
                              const newWeight = parseFloat(e.target.value);
                              // Optional: Add validation
                              if (newWeight < 0) {
                                setShowNotification({
                                  show: true,
                                  type: 'warning',
                                  content: 'Weight cannot be negative',
                                });
                                return;
                              }
                              if (newWeight > 1000) {
                                setShowNotification({
                                  show: true,
                                  type: 'warning',
                                  content: 'Weight exceeds maximum limit',
                                });
                                return;
                              }
                              handleBoxWeightChange(group, box, newWeight.toString());
                            }}
                            min='0'
                            step='0.01'
                            placeholder='Enter weight'
                          />
                        </div>
                      </div>
                      {box?.weight ? (
                        <div className='mt-3'>
                          {(() => {
                            const weightCheck = checkFbaBoxWeightLimits(box, group.items);
                            if (!weightCheck.isValid) {
                              return (
                                <CustomAlert
                                  type='error'
                                  message={weightCheck.message}
                                  id={`weight-alert-${box.boxName}`}
                                  options={{ defaultColors: true }}
                                />
                              );
                            }
                            if (weightCheck.warning) {
                              return (
                                <CustomAlert
                                  type='warning'
                                  message={weightCheck.warning}
                                  id={`weight-alert-${box.boxName}`}
                                  options={{ defaultColors: true }}
                                />
                              );
                            }
                            return null;
                          })()}
                        </div>
                      ) : null}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
      <SlideOver
        open={openManualAddItemsSlideOverPanel}
        XLarge={true}
        setOpen={setOpenManualAddItemsSlideOverPanel}
        isCrossIconVisible={false}
        title={
          <HeaderWithArrow
            headerTitle={'Add Items to Box'}
            description={
              'Select the required items and enter their quantities to pack into this box. Ensure the quantities match the available stock to maintain accuracy and avoid discrepancies.'
            }
            isLearnMore={false}
            arrowAction={() => setOpenManualAddItemsSlideOverPanel(false)}
            isArrow
            mainClasses='mb-0'
          />
        }
      >
        <AddItemsInBox
          itemsData={groupSelectedToManualAddItems?.group?.items}
          onCancel={() => {
            setOpenManualAddItemsSlideOverPanel(false);
          }}
          onConfirm={(addedItems) => {
            handleManualItemAddition(
              addedItems,
              groupSelectedToManualAddItems.group,
              groupSelectedToManualAddItems.boxIndex,
            );
            setOpenManualAddItemsSlideOverPanel(false);
          }}
          setShowNotification={setShowNotification}
        />
      </SlideOver>
      <SlideOver
        open={openViewItemsSlideOverPanel}
        XLarge={true}
        setOpen={setOpenViewItemsSlideOverPanel}
        isCrossIconVisible={false}
        title={
          <HeaderWithArrow
            headerTitle={entitySelectedForViewItems.header}
            description={entitySelectedForViewItems.description}
            isLearnMore={false}
            isArrow
            arrowAction={() => setOpenViewItemsSlideOverPanel(false)}
            mainClasses='mb-0'
          />
        }
      >
        <div className='p-6 font-inter'>
          <ViewPackedItems
            items={entitySelectedForViewItems?.items}
            isGroup={entitySelectedForViewItems?.isGroup}
          />
        </div>
      </SlideOver>
      <SlideOver
        open={openEditBoxItemsPanel}
        XLarge={true}
        setOpen={setOpenEditBoxItemsPanel}
        isCrossIconVisible={false}
        title={
          <HeaderWithArrow
            headerTitle={`${selectedBoxForEdit?.group.groupName} : ${selectedBoxForEdit?.box.boxName} : Edit Items`}
            description='Modify item quantities or remove items from this box. Changes will update both box contents and available group inventory.'
            isLearnMore={false}
            arrowAction={() => setOpenEditBoxItemsPanel(false)}
            isArrow
            mainClasses='mb-0'
          />
        }
      >
        {selectedBoxForEdit && (
          <EditBoxItems
            box={selectedBoxForEdit.box}
            group={selectedBoxForEdit.group}
            onCancel={() => {
              setOpenEditBoxItemsPanel(false);
              setSelectedBoxForEdit(null);
            }}
            onSave={handleEditBoxItems}
            setShowNotification={setShowNotification}
          />
        )}
      </SlideOver>
      <ModalV2
        isOpen={isBoxDimensionsModalOpen}
        onClose={() => setIsBoxDimensionsModalOpen(false)}
        title='Enter Custom Box Dimensions'
        maxHeight='600px'
        className={'z-[1000]'}
      >
        <div className='py-4 font-inter'>
          <div className='flex flex-col gap-4'>
            {['length', 'width', 'height'].map((dim) => (
              <div key={dim} className='flex items-center'>
                <label className='w-24 font-medium'>
                  {dim.charAt(0).toUpperCase() + dim.slice(1)} (in):
                </label>
                <input
                  type='number'
                  className='ml-2 w-24 rounded-md border border-gray-300 px-2 py-1'
                  value={boxDimensionsDetails[dim]}
                  onChange={(e) => {
                    const value = Number(e.target.value);
                    if (value < 0) {
                      setShowNotification({
                        show: true,
                        type: 'warning',
                        content: 'Dimensions cannot be negative',
                      });
                      return;
                    }
                    setBoxDimensionsDetails((prev) => ({
                      ...prev,
                      [dim]: parseFloat(value.toString()),
                    }));
                  }}
                />
              </div>
            ))}
          </div>
          <div className='mt-6 flex justify-end gap-4'>
            <Button variant='secondary' onClick={() => setIsBoxDimensionsModalOpen(false)}>
              Cancel
            </Button>
            <Button variant='primary' onClick={handleDimensionsSubmit}>
              Confirm
            </Button>
          </div>
        </div>
      </ModalV2>

      <ConfirmationModal
        isOpen={showConfirmationModal}
        onClose={() => {
          if (confirmationConfig?.onClose) {
            confirmationConfig.onClose();
          }
          setShowConfirmationModal(false);
        }}
        onConfirm={() => {
          if (confirmationConfig?.onConfirm) {
            confirmationConfig.onConfirm();
          }
          setShowConfirmationModal(false);
        }}
        title={confirmationConfig?.title || ''}
        content={confirmationConfig?.content}
        cancelText={confirmationConfig?.cancelText}
        confirmText={confirmationConfig?.confirmText}
      />
    </div>
  );
};

export default PackingBoxInformation;
