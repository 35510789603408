import React, { useEffect, useState } from 'react';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import CustomTable from '../components/common/CustomTable';
import groupIcon from '../assets/images/groupIcon.png';
import Button from '../components/common/Button';
import CustomBadge from '../components/common/CustomBadge';
import { regenerateTransportationOptions } from '../redux/FbaPacking/fbaPackingActions';
import { useAppDispatch } from '../redux/hooks/useDispatch';
import FbaPalletManagement from './FbaPalletManagement';
import dayjs from 'dayjs';
import ConfirmationModal from '../components/common/ConfirmationModal';
import getSymbolFromCurrency from 'currency-symbol-map';

interface BadgeStyle {
  bgColor: string;
  textColor: string;
}

interface BadgeStyles {
  default: BadgeStyle;
  bestRate: BadgeStyle;
  fastest: BadgeStyle;
  bestValue: BadgeStyle;
  other: BadgeStyle;
}

interface ConfirmationConfig {
  title: string;
  content: React.ReactNode;
  onConfirm: () => void;
  onClose?: () => void;
  confirmText?: string;
  cancelText?: string;
}

const BADGE_STYLES: BadgeStyles = {
  default: {
    bgColor: '#EFF6FF',
    textColor: '#1E40AF',
  },
  bestRate: {
    bgColor: '#DCFCE7',
    textColor: '#166534',
  },
  fastest: {
    bgColor: '#E0E7FF',
    textColor: '#3730A3',
  },
  bestValue: {
    bgColor: '#F3E8FF',
    textColor: '#6B21A8',
  },
  other: {
    bgColor: '#F3F4F6',
    textColor: '#1F2937',
  },
};

const formatRates = (
  transportationOptions: any,
  shipmentId: string,
  selectedRates: any = {},
): any => {
  const shipment = transportationOptions.find((option) => option.shipmentId === shipmentId);
  if (!shipment || !shipment.transportationOptions)
    return {
      defaultRate: null,
      bestRate: [],
      fastestRate: [],
      bestValue: [],
      otherRates: [],
    };

  const rates = shipment.transportationOptions.filter(
    (option) => option.carrier.name.toLowerCase() !== 'other',
  );

  const defaultRate = selectedRates[shipmentId]
    ? rates.find(
        (rate) => rate.transportationOptionId === selectedRates[shipmentId].transportationOptionId,
      )
    : null;

  const lowestPrice = Math.min(...rates.map((rate) => rate.quote.cost.amount));

  const bestRates = rates.filter((rate) => rate.quote.cost.amount === lowestPrice);

  const ratesWithDeliveryDays = rates.filter((rate) => rate.quote.estimated_delivery_days);
  const fastestRates =
    ratesWithDeliveryDays.length > 0
      ? ratesWithDeliveryDays.filter(
          (rate) =>
            rate.quote.estimated_delivery_days ===
            Math.min(...ratesWithDeliveryDays.map((r) => r.quote.estimated_delivery_days!)),
        )
      : [];

  const priceThreshold = lowestPrice * 1.2;
  const eligibleRates = rates.filter((rate) => rate.quote.cost.amount <= priceThreshold);
  const bestValueRate = eligibleRates.reduce((best, current) => {
    if (!best) return current;

    const bestDelivery = best.quote.estimated_delivery_days || 0;
    const currentDelivery = current.quote.estimated_delivery_days || 0;

    if (currentDelivery < bestDelivery) return current;
    if (currentDelivery === bestDelivery && current.quote.cost.amount < best.quote.cost.amount)
      return current;

    return best;
  }, null);

  return {
    defaultRate,
    bestRate: bestRates,
    fastestRate: fastestRates,
    bestValue: bestValueRate ? [bestValueRate] : [],
    otherRates: rates.filter(
      (rate) =>
        !bestRates.includes(rate) &&
        !fastestRates.includes(rate) &&
        (!bestValueRate || rate.transportationOptionId !== bestValueRate.transportationOptionId),
    ),
  };
};

const createUniqueRateId = (transportationOptionId: string, alphaCode: string): string =>
  `${transportationOptionId}_${alphaCode}`;

const FbaRateShopping: React.FC<any> = ({
  placementOption,
  transportationOptions,
  selectedRates,
  onRateSelect,
  onCancel,
  onConfirm,
  pollTransportationOptions,
  setSlideOverOpen,
  isLtlFlow,
  setTransportationOptions,
  setShowNotification,
  order,
  getToken,
}) => {
  const dispatch = useAppDispatch();

  const [selectedLocation, setSelectedLocation] = useState<string>(
    placementOption?.shipmentsWithBoxesAndItems
      ? placementOption?.shipmentsWithBoxesAndItems[0]?.shipmentId
      : '',
  );
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationConfig | null>(null);

  const [tempSelectedRates, setTempSelectedRates] = useState<any>(() => {
    // Use existing selectedRates if available
    if (Object.keys(selectedRates).length > 0) {
      return selectedRates;
    }

    const initialRates: any = {};
    placementOption?.shipmentsWithBoxesAndItems?.forEach((shipment) => {
      const formattedRates = formatRates(transportationOptions, shipment.shipmentId);
      if (formattedRates.bestValue?.[0]) {
        const rate = formattedRates.bestValue[0];
        initialRates[shipment.shipmentId] = {
          transportationOptionId: rate.transportationOptionId,
          uniqueId: createUniqueRateId(rate.transportationOptionId, rate.carrier.alphaCode),
          carrierName: rate.carrier.name,
          isPartnered: rate.shippingSolution === 'AMAZON_PARTNERED_CARRIER',
        };
      }
    });
    return initialRates;
  });

  const showConfirmation = (config: ConfirmationConfig) => {
    setConfirmationConfig(config);
    setShowConfirmationModal(true);
  };

  const deliveryEtaRender = (_: any, row: any) => {
    if (row.carrierAppointment) {
      const startTime = dayjs(row.carrierAppointment.startTime);
      const endTime = dayjs(row.carrierAppointment.endTime);
      return (
        <span className='text-gray-700'>
          {startTime.format('MMM D, YYYY h:mm A')} - {endTime.format('h:mm A')}
        </span>
      );
    }
    return <span className='text-gray-700'>N/A</span>;
  };

  const handleLocalRateSelect = (
    shipmentId: string,
    transportationOptionId: string,
    alphaCode: string,
    carrierName: string,
    isPartnered: boolean,
  ) => {
    setTempSelectedRates((prev) => ({
      ...prev,
      [shipmentId]: {
        transportationOptionId,
        uniqueId: createUniqueRateId(transportationOptionId, alphaCode),
        carrierName,
        isPartnered,
      },
    }));
  };

  const handleRegenerateTransportationOptions = (palletInfo: any): void => {
    const payload = {
      input: {
        outboundOrderId: order.outboundOrderId,
        palletsPerShipment: palletInfo,
      },
    };

    const successCallback = (data: { status: string; errors?: Array<{ message: string }> }) => {
      if (data.status === 'SUCCESS') {
        setShowNotification({
          show: true,
          type: 'success',
          content: 'Regeneration of transportation options for LTL started successfully',
        });
        pollTransportationOptions();
        setSlideOverOpen(false);
        if (setTransportationOptions) setTransportationOptions([]);
      } else if (data.errors && data.errors.length > 0) {
        setShowNotification({
          show: true,
          type: 'error',
          content: data.errors[0].message,
        });
      } else {
        setShowNotification({
          show: true,
          type: 'error',
          content: 'An unexpected error occurred while regenerating transportation options',
        });
      }
    };

    const errorCallback = (error: any) => {
      setShowNotification({
        show: true,
        type: 'error',
        content:
          error.message || 'An unexpected error occurred while regenerating transportation options',
      });
    };

    dispatch(
      regenerateTransportationOptions({
        variables: payload,
        successCallback,
        errorCallback,
        getToken,
      }),
    );
  };

  const handleConfirm = () => {
    Object.keys(tempSelectedRates).forEach((shipmentId) => {
      const { transportationOptionId, uniqueId, carrierName, isPartnered } =
        tempSelectedRates[shipmentId];
      onRateSelect(shipmentId, transportationOptionId, uniqueId, carrierName, isPartnered);
    });
    onConfirm();
  };

  const handleEnterTrackingManually = () => {
    const newRates = {};

    placementOption?.shipmentsWithBoxesAndItems?.forEach((shipment) => {
      const shipmentOptions = transportationOptions.find(
        (option) => option.shipmentId === shipment.shipmentId,
      );

      if (shipmentOptions) {
        const otherOption = shipmentOptions.transportationOptions.find(
          (option) => option.carrier.name.toLowerCase() === 'other',
        );

        if (otherOption) {
          newRates[shipment.shipmentId] = {
            transportationOptionId: otherOption.transportationOptionId,
            uniqueId: createUniqueRateId(
              otherOption.transportationOptionId,
              otherOption.carrier.alphaCode,
            ),
            carrierName: otherOption.carrier.name,
            isPartnered: otherOption.shippingSolution === 'AMAZON_PARTNERED_CARRIER',
          };
        }
      }
    });

    // Set all rates at once and use the callback to call handleConfirm
    setTempSelectedRates((prev) => {
      const updatedRates = { ...prev, ...newRates };
      // Use setTimeout to ensure state is updated before calling handleConfirm
      setTimeout(() => {
        Object.keys(updatedRates).forEach((shipmentId) => {
          const { transportationOptionId, uniqueId, carrierName, isPartnered } =
            updatedRates[shipmentId];
          onRateSelect(shipmentId, transportationOptionId, uniqueId, carrierName, isPartnered);
        });
        onConfirm();
      }, 0);
      return updatedRates;
    });
  };

  const getLTLRates = (shipmentId: string): any[] => {
    const shipment = transportationOptions.find((option) => option.shipmentId === shipmentId);

    if (!shipment || !shipment.transportationOptions) return [];

    return shipment.transportationOptions.filter(
      (option) =>
        option.shippingMode.includes('LTL') && option.carrier.name.toLowerCase() !== 'other',
    );
  };

  const locations: any[] = placementOption?.shipmentsWithBoxesAndItems?.map((shipment) => ({
    shipmentId: shipment.shipmentId,
    cityName: shipment.destination.address.city,
    boxes: shipment.boxes.length,
    items: shipment.items?.reduce((sum, item) => sum + item.quantity, 0),
  }));

  const formattedRates = formatRates(transportationOptions, selectedLocation, selectedRates);

  const selectedLocationOptions =
    transportationOptions.find((option) => option.shipmentId === selectedLocation)
      ?.transportationOptions || [];

  const getSelectedRateAmount = (shipmentId: string): number => {
    const selectedOption = tempSelectedRates[shipmentId];
    if (!selectedOption?.uniqueId) return 0;

    const shipmentRates =
      transportationOptions.find((option) => option.shipmentId === shipmentId)
        ?.transportationOptions || [];

    // Find the rate using the uniqueId instead of just transportationOptionId
    const selectedRate = shipmentRates.find(
      (rate) =>
        createUniqueRateId(rate.transportationOptionId, rate.carrier.alphaCode) ===
        selectedOption.uniqueId,
    );

    return selectedRate?.quote.cost.amount || 0;
  };

  useEffect(() => {
    if (isLtlFlow) {
      Object.keys(tempSelectedRates).forEach((shipmentId) => {
        const { transportationOptionId, uniqueId, carrierName, isPartnered } =
          tempSelectedRates[shipmentId];
        onRateSelect(shipmentId, transportationOptionId, uniqueId, carrierName, isPartnered);
      });
    }
  }, [isLtlFlow, tempSelectedRates]);

  const columns = [
    {
      key: 'carrier',
      title: 'Carrier',
      render: (_, row) => (
        <div className='flex items-center gap-2'>
          <input
            type='radio'
            checked={
              tempSelectedRates[selectedLocation]?.uniqueId ===
              createUniqueRateId(row.transportationOptionId, row.carrier.alphaCode)
            }
            onChange={() =>
              handleLocalRateSelect(
                selectedLocation,
                row.transportationOptionId,
                row.carrier.alphaCode,
                row.carrier.name,
                undefined,
              )
            }
            className='mr-2 h-5 w-5 cursor-pointer text-primaryAccent focus:ring-primaryAccent'
          />
          <span>{row.carrier.name}</span>
        </div>
      ),
    },
    {
      key: 'carrierType',
      title: 'Carrier Type',
      render: (_, row) => (
        <span>
          {row.shippingSolution === 'AMAZON_PARTNERED_CARRIER' ? 'Partnered' : 'Non-Partnered'}
        </span>
      ),
    },
    {
      key: 'shippingMode',
      title: 'Service',
      render: (value) => value.replace(/_/g, ' '),
    },
    {
      key: 'cost',
      title: 'Rate',
      render: (_, row) => {
        const symbol = getSymbolFromCurrency(row.quote.cost.code) || '$';
        return `${symbol}${row.quote.cost.amount}`;
      },
    },
    {
      key: 'deliveryEta',
      title: 'Delivery ETA',
      render: (_, row) => (
        <span className='text-gray-700'>
          {row.quote.estimated_delivery_days ? `${row.quote.estimated_delivery_days} Days` : 'N/A'}
        </span>
      ),
    },
    {
      key: 'pickUpEta',
      title: 'Pick Up ETA',
      render: deliveryEtaRender,
    },
    {
      key: 'rateType',
      title: 'Rate Type',
      render: (_, row) => {
        const rateTypes = [];

        if (formattedRates.defaultRate === row) {
          rateTypes.push(
            <CustomBadge
              key='default'
              label='Default'
              bgColor={BADGE_STYLES.default.bgColor}
              textColor={BADGE_STYLES.default.textColor}
              textSize='text-xs'
              className='font-medium'
            />,
          );
        }

        if (
          formattedRates.bestRate.some(
            (rate) => rate.transportationOptionId === row.transportationOptionId,
          )
        ) {
          rateTypes.push(
            <CustomBadge
              key='best'
              label='Best Rate'
              bgColor={BADGE_STYLES.bestRate.bgColor}
              textColor={BADGE_STYLES.bestRate.textColor}
              textSize='text-xs'
              className='font-medium'
            />,
          );
        }

        if (
          formattedRates.fastestRate.some(
            (rate) => rate.transportationOptionId === row.transportationOptionId,
          )
        ) {
          rateTypes.push(
            <CustomBadge
              key='fastest'
              label='Fastest'
              bgColor={BADGE_STYLES.fastest.bgColor}
              textColor={BADGE_STYLES.fastest.textColor}
              textSize='text-xs'
              className='font-medium'
            />,
          );
        }

        if (
          formattedRates.bestValue.some(
            (rate) => rate.transportationOptionId === row.transportationOptionId,
          )
        ) {
          rateTypes.push(
            <CustomBadge
              key='value'
              label='Best Value'
              bgColor={BADGE_STYLES.bestValue.bgColor}
              textColor={BADGE_STYLES.bestValue.textColor}
              textSize='text-xs'
              className='font-medium'
            />,
          );
        }

        if (rateTypes.length === 0) {
          rateTypes.push(
            <CustomBadge
              key='other'
              label='Other'
              bgColor={BADGE_STYLES.other.bgColor}
              textColor={BADGE_STYLES.other.textColor}
              textSize='text-xs'
              className='font-medium'
            />,
          );
        }

        return <div className='flex flex-wrap gap-1'>{rateTypes}</div>;
      },
    },
  ];

  const ltlColumns = [
    {
      key: 'carrier',
      title: 'Carrier',
      render: (_, row) => (
        <div className='flex items-center gap-2'>
          <input
            type='radio'
            checked={
              tempSelectedRates[selectedLocation]?.uniqueId ===
              createUniqueRateId(row.transportationOptionId, row.carrier.alphaCode)
            }
            onChange={() =>
              handleLocalRateSelect(
                selectedLocation,
                row.transportationOptionId,
                row.carrier.alphaCode,
                row.carrier.name,
                undefined,
              )
            }
            className='mr-2 h-5 w-5 cursor-pointer text-primaryAccent focus:ring-primaryAccent'
          />
          <span>{row.carrier.name}</span>
        </div>
      ),
    },
    {
      key: 'carrierType',
      title: 'Carrier Type',
      render: (_, row) => (
        <span>
          {row.shippingSolution === 'AMAZON_PARTNERED_CARRIER' ? 'Partnered' : 'Non-Partnered'}
        </span>
      ),
    },
    {
      key: 'shippingMode',
      title: 'Service',
      render: (value) => value.replace(/_/g, ' '),
    },
    {
      key: 'cost',
      title: 'Rate',
      render: (_, row) => `$${row.quote.cost.amount}`,
    },
    {
      key: 'deliveryEta',
      title: 'Delivery ETA',
      render: (_, row) => (
        <span className='text-gray-700'>
          {row.quote.estimated_delivery_days ? `${row.quote.estimated_delivery_days} Days` : 'N/A'}
        </span>
      ),
    },
    {
      key: 'pickUpEts',
      title: 'Pick Up ETA',
      render: deliveryEtaRender,
    },
    {
      key: 'rateType',
      title: 'Rate Type',
      render: (_, row) => {
        const rateTypes = [];
        const formattedLTLRates = formatRates(
          transportationOptions.map((opt) => ({
            ...opt,
            transportationOptions: getLTLRates(opt.shipmentId),
          })),
          selectedLocation,
          selectedRates,
        );

        if (formattedLTLRates.defaultRate === row) {
          rateTypes.push(
            <CustomBadge
              key='default'
              label='Default'
              bgColor={BADGE_STYLES.default.bgColor}
              textColor={BADGE_STYLES.default.textColor}
              textSize='text-xs'
              className='font-medium'
            />,
          );
        }

        if (
          formattedLTLRates.bestRate.some(
            (rate) => rate.transportationOptionId === row.transportationOptionId,
          )
        ) {
          rateTypes.push(
            <CustomBadge
              key='best'
              label='Best Rate'
              bgColor={BADGE_STYLES.bestRate.bgColor}
              textColor={BADGE_STYLES.bestRate.textColor}
              textSize='text-xs'
              className='font-medium'
            />,
          );
        }

        if (
          formattedLTLRates.fastestRate.some(
            (rate) => rate.transportationOptionId === row.transportationOptionId,
          )
        ) {
          rateTypes.push(
            <CustomBadge
              key='fastest'
              label='Fastest'
              bgColor={BADGE_STYLES.fastest.bgColor}
              textColor={BADGE_STYLES.fastest.textColor}
              textSize='text-xs'
              className='font-medium'
            />,
          );
        }

        if (
          formattedLTLRates.bestValue.some(
            (rate) => rate.transportationOptionId === row.transportationOptionId,
          )
        ) {
          rateTypes.push(
            <CustomBadge
              key='value'
              label='Best Value'
              bgColor={BADGE_STYLES.bestValue.bgColor}
              textColor={BADGE_STYLES.bestValue.textColor}
              textSize='text-xs'
              className='font-medium'
            />,
          );
        }

        if (rateTypes.length === 0) {
          rateTypes.push(
            <CustomBadge
              key='other'
              label='Other'
              bgColor={BADGE_STYLES.other.bgColor}
              textColor={BADGE_STYLES.other.textColor}
              textSize='text-xs'
              className='font-medium'
            />,
          );
        }

        return <div className='flex flex-wrap gap-1'>{rateTypes}</div>;
      },
    },
  ];

  return (
    <div className='flex h-full flex-col'>
      <div className='flex-1'>
        <div className='flex h-full flex-col p-8 font-inter'>
          {/* Location Selection */}
          <div className='mb-6 space-y-4 border-gray-200'>
            <h3 className='text-sm font-medium'>Select Location</h3>
            <div className='flex flex-wrap gap-8'>
              {locations.map((location) => (
                <div
                  key={location.shipmentId}
                  onClick={() => setSelectedLocation(location.shipmentId)}
                  className='flex cursor-pointer items-start gap-2' // Reduced gap
                >
                  <input
                    type='radio'
                    checked={selectedLocation === location.shipmentId}
                    onChange={() => setSelectedLocation(location.shipmentId)}
                    className='mt-0.5 h-5 w-5 cursor-pointer text-primaryAccent focus:ring-primaryAccent'
                  />
                  <div className='flex flex-col items-center'>
                    <span className='mb-2 text-xs font-medium'> {location.cityName}</span>
                    <img src={groupIcon} alt='' className='mb-2 h-8 w-8 rounded-md object-cover' />
                    <span className='text-[10px] text-gray-600'>
                      {' '}
                      {location.boxes} Boxes | {location.items} Items
                    </span>
                    <span className='mt-0.5 text-[10px] font-medium'>
                      {getSelectedRateAmount(location.shipmentId) > 0 ? (
                        <span className='mt-0.5 text-[10px] font-medium'>
                          {getSymbolFromCurrency(
                            transportationOptions
                              .find((opt) => opt.shipmentId === location.shipmentId)
                              ?.transportationOptions.find(
                                (rate) =>
                                  rate.transportationOptionId ===
                                  tempSelectedRates[location.shipmentId]?.transportationOptionId,
                              )?.quote.cost.code,
                          ) || '$'}
                          {getSelectedRateAmount(location.shipmentId)}
                        </span>
                      ) : null}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Shipping Type Tabs */}
          <TabGroup defaultIndex={isLtlFlow ? 1 : 0}>
            <TabList className='flex space-x-4 border-b border-gray-200'>
              {!isLtlFlow && (
                <Tab
                  className={({ selected }) => `
                  px-4 py-2 text-sm font-medium outline-none
                  ${
                    selected
                      ? 'border-b-2 border-primaryAccent text-primaryAccent'
                      : 'text-gray-500 hover:text-gray-700'
                  }
                `}
                >
                  Small Parcel
                </Tab>
              )}
              <Tab
                className={({ selected }) => `
                px-4 py-2 text-sm font-medium outline-none
                ${
                  selected
                    ? 'border-b-2 border-primaryAccent text-primaryAccent'
                    : 'text-gray-500 hover:text-gray-700'
                }
              `}
              >
                LTL
              </Tab>
            </TabList>

            <TabPanels className='mt-4'>
              {!isLtlFlow && (
                <TabPanel>
                  <CustomTable
                    columns={columns}
                    data={selectedLocationOptions.filter(
                      (option) =>
                        !option.shippingMode.includes('LTL') &&
                        option.carrier.name.toLowerCase() !== 'other',
                    )}
                    isSearchable={false}
                    isFilters={false}
                    isPagination={false}
                  />
                </TabPanel>
              )}
              <TabPanel>
                <div>
                  {getLTLRates(selectedLocation).length > 0 && (
                    <div className='mb-6'>
                      <h3 className='mb-4 text-sm font-medium'>Available LTL Rates</h3>
                      <CustomTable
                        columns={ltlColumns}
                        data={getLTLRates(selectedLocation)}
                        isSearchable={false}
                        isFilters={false}
                        isPagination={false}
                      />
                    </div>
                  )}
                  <FbaPalletManagement
                    onSave={(palletInfo) => {
                      handleRegenerateTransportationOptions(palletInfo);
                    }}
                    locations={locations}
                    setShowNotification={setShowNotification}
                  />
                </div>
              </TabPanel>
            </TabPanels>
          </TabGroup>
        </div>
      </div>
      {!isLtlFlow && (
        <div className='sticky bottom-0 z-30 flex w-full items-center justify-between bg-white p-4 shadow-lg'>
          <div className='flex w-full justify-end gap-6'>
            <Button variant='secondary' className='text-sm font-medium' onClick={onCancel}>
              Cancel
            </Button>
            <Button
              variant='primary'
              className='text-sm font-medium'
              onClick={() => {
                showConfirmation({
                  title: 'Enter Tracking Manually',
                  content: 'Are you sure you want to enter tracking manually?',
                  onConfirm: handleEnterTrackingManually,
                });
              }}
            >
              Enter Tracking Manually
            </Button>
            <Button variant='primary' className='text-sm font-medium' onClick={handleConfirm}>
              Confirm
            </Button>
          </div>
        </div>
      )}
      <ConfirmationModal
        isOpen={showConfirmationModal}
        onClose={() => {
          if (confirmationConfig?.onClose) {
            confirmationConfig.onClose();
          }
          setShowConfirmationModal(false);
        }}
        onConfirm={() => {
          if (confirmationConfig?.onConfirm) {
            confirmationConfig.onConfirm();
          }
          setShowConfirmationModal(false);
        }}
        title={confirmationConfig?.title || ''}
        content={confirmationConfig?.content}
        cancelText={confirmationConfig?.cancelText}
        confirmText={confirmationConfig?.confirmText}
      />
    </div>
  );
};

export default FbaRateShopping;
