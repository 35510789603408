import React, { useContext, useEffect, useState } from 'react';
import useFetch from '../../hooks/useFetch';
import BaseContext from '../common/BaseContext';
import { OnboardingStep } from '../../pages/Onboarding';
import { SignOutButton } from '@clerk/clerk-react';

type PlanData = {
  price: {
    value: number;
    currency: string;
  };
  stripeData: {
    productId: string;
    priceId: string;
  };
  unitQuota: {
    outboundOrderQuota: number;
  };
  _id: string;
  name: string;
  subscriptionPlanId: string;
  duration: number;
  isDeleted: boolean;
  deletedAt: string | null;
  createdAt: string;
  updatedAt: string;
  features: string[];
  __v: number;
};
interface SelectPlanProps {
  setOnboardingStep: (step: OnboardingStep) => void;
}
const SelectPlan = (props: SelectPlanProps) => {
  const authenticatedFetch = useFetch();
  const [plans, setPlans] = useState<PlanData[]>([]);
  const { organization, setLoading } = useContext(BaseContext);
  useEffect(() => {
    setLoading(true);
    authenticatedFetch('/api/v1/payments/subscription-plans').then((res) => {
      if (res.success) {
        setPlans(res.data);
        setLoading(false);
      }
    });
  }, []);

  const [selectedPlan, setSelectedPlan] = useState<PlanData | null>(null);
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const { setShowNotification } = useContext(BaseContext);

  const handleSelectPlan = (plan: PlanData) => {
    setSelectedPlan(plan);
  };

  const handleCheckout = () => {
    setLoading(true);
    if (selectedPlan) {
      authenticatedFetch('/api/v1/payments/checkout', {
        method: 'POST',
        body: JSON.stringify({
          subscriptionPlanId: selectedPlan.subscriptionPlanId,
          organizationId: organization.organizationId,
        }),
      }).then((res) => {
        if (res.success) {
          window.location.href = res.data.url;
        } else {
          setLoading(false);
          setShowNotification({
            show: true,
            type: 'error',
            content: 'Something went wrong. Please try again later!',
          });
        }
      });
    }
  };

  const handleGoBack = () => {
    props.setOnboardingStep(OnboardingStep.createWarehouse);
  };
  const LoadingModal: React.FC = () => (
    <div className='fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-90 flex justify-center items-center z-50'>
      <div className='text-white text-lg text-center'>
        Processing payment... Please wait. <br></br> If you have cancelled the payment please come
        back after 1 Minute
      </div>
    </div>
  );

  useEffect(() => {
    const interval = setInterval(() => {
      authenticatedFetch('/api/v1/organizations?include=subscription').then((res) => {
        if (res.success && res.data && res.data.length > 0) {
          const org = res.data.find((o) => o.organizationId === organization.organizationId);

          document.title = `${org.name} - Ignite Fulfill`;
          if (org && org.subscription === undefined) {
            setIsPaymentProcessing(false);
            clearInterval(interval);
          } else if (org && org.subscription.status === 'active') {
            setIsPaymentProcessing(false);
            window.location.href = `/${org.organizationId}`;
          } else if (org && org.subscription.status === 'payment-awaiting') {
            setIsPaymentProcessing(true);
          }
        } else {
          if (!location.pathname.includes('onboarding')) location.replace('/onboarding');
          setShowNotification({
            show: true,
            type: 'error',
            content: 'Looks like you do not have any organizations!',
          });
        }
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      {isPaymentProcessing && <LoadingModal />}
      <div className=' py-12'>
        <div className='max-w-4xl mx-auto px-4 sm:px-6 lg:px-8'>
          <div className='text-center pb-12'>
            <h2 className='text-base font-semibold text-hopstack-blue-600 tracking-wide uppercase'>
              Choose a plan
            </h2>
            <p className='mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl'>
              All plans come with a 15-day free trial. You wont be charged until your trial ends.
            </p>
          </div>

          <div className='mt-10 flex justify-center gap-6'>
            {plans.map((plan) => (
              <div
                key={plan.name}
                className={`relative p-6 border ${
                  selectedPlan?.subscriptionPlanId === plan.subscriptionPlanId
                    ? 'border-hopstack-blue-500 bg-hopstack-blue-100'
                    : 'border-gray-300'
                } rounded-lg max-w-sm w-full bg-white shadow-sm flex flex-col`}
                onClick={() => handleSelectPlan(plan)}
              >
                <div>
                  <h3 className='text-xl leading-6 font-semibold text-gray-900'>{plan.name}</h3>
                  <p className='mt-4'>
                    <span className='text-4xl font-extrabold text-gray-900'>Contact Sales</span>
                  </p>
                </div>
                <ul className='mt-6 flex-1 space-y-4'>
                  {plan.features?.map((feature) => (
                    <li key={feature} className='flex items-start'>
                      <div className='flex-shrink-0'>
                        <svg
                          className='h-6 w-6 text-hopstack-blue-500'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 24 24'
                          stroke='currentColor'
                          aria-hidden='true'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth={2}
                            d='M5 13l4 4L19 7'
                          />
                        </svg>
                      </div>
                      <p className='ml-3 text-base font-medium text-gray-500'>{feature}</p>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          <div className='mt-10 text-center flex flex-row justify-center gap-10  '>
            <button
              className=' align-middle  top-[100px] left-4 bg-red-400 duration-500 hover:bg-red-500 text-white font-bold py-2 px-4 rounded'
              onClick={handleGoBack}
            >
              Back
            </button>
            {selectedPlan ? (
              <button
                className=' bg-hopstack-blue-500 hover:bg-hopstack-blue-600 transition-colors duration-300  text-white font-bold py-2 px-4 rounded'
                onClick={handleCheckout}
              >
                Continue to Checkout - {selectedPlan.name}
              </button>
            ) : (
              <button
                className=' bg-hopstack-blue-300 hover:disabled:cursor-not-allowed transition-colors duration-300  text-white font-bold py-2 px-4 rounded'
                disabled
              >
                Select a Plan
              </button>
            )}
          </div>
        </div>

        <div className='w-full flex justify-center mt-8'>
          <SignOutButton>
            <button className='rounded-md bg-red-400 flex gap-2 px-8 py-2 text-white font-bold shadow-sm hover:opacity-80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hopstack-blue-600'>
              Sign Out
            </button>
          </SignOutButton>
        </div>
      </div>
    </>
  );
};

export default SelectPlan;
