// Confirmation.tsx

import React, { useContext, useState } from 'react'
// import { ArrowRightIcon } from '@heroicons/react/20/solid'
import { UploadStage } from '../../pages/Inventory'
import BaseContext from '../common/BaseContext'
import { Warehouse } from '../common/types'

interface ConfirmationProps {
  setUploadStage: (stage: UploadStage) => void
  selectedFile: File | null
  totalEntries: number
  mappedFields: Record<string, { name: string; value: string }>
  warehouse: Warehouse
  fileName: string
  setOpenNewUploadSlideOver: (open: boolean) => void
  setSelectedFile: (file: File | null) => void
  setMappedFields: (fields: Record<string, unknown>) => void
  setUploading: (uploading: boolean) => void
  setFileHeaders: (headers: string[]) => void
  setTotalEntries: (entries: number) => void
  fetchInventory: () => void
  authenticatedFetch: (input: RequestInfo, init?: RequestInit) => Promise<any>
}
/**
 * The `Confirmation` component is responsible for rendering the confirmation step of the bulk inventory update process. It displays the mapped fields, the number of entries, and a textarea for the user to provide a reason for the inventory adjustment. When the user submits the form, the `handleConfirmSubmit` function is called, which sends the necessary data to the server to start the file processing.
 */
const Confirmation: React.FC<ConfirmationProps> = ({
  setUploadStage,
  selectedFile,
  totalEntries,
  mappedFields,
  warehouse,
  fileName,
  setOpenNewUploadSlideOver,
  setSelectedFile,
  setMappedFields,
  setUploading,
  setFileHeaders,
  setTotalEntries,
  fetchInventory,
  authenticatedFetch,
}) => {
  const { setLoading, setShowNotification } = useContext(BaseContext)
  const [reason, setReason] = useState<string>('')
  const handleConfirmSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setLoading(true)
    e.preventDefault()

    try {
      // Mapping data to send in API request
      const mapping = Object.fromEntries(
        Object.entries(mappedFields).map(([key, value]) => [key, value.value]),
      )

      const response = await authenticatedFetch('/api/v1/inventory/bulk-update', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fileName: selectedFile?.name,
          warehouseId: warehouse.warehouseId,
          mapping,
          s3Key: fileName,
          reason: reason,
        }),
      })

      if (!response.success) {
        setLoading(false)
        setShowNotification({
          show: true,
          type: 'error',
          content: response.message,
        })
        return
      }
      setLoading(false)
      setShowNotification(null)
      setShowNotification({
        show: true,
        type: 'success',
        content:
          'Validation started successfully. We will notify you about the upload status after completion.',
      })
      // Clear all state after submission
      setOpenNewUploadSlideOver(false)
      setSelectedFile(null)
      setMappedFields({})
      setUploading(false)
      setFileHeaders([])
      setTotalEntries(0)

      // Fetch inventory
      fetchInventory()
    } catch (error) {
      setShowNotification({
        show: true,
        type: 'error',
        content: error.message,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='max-w-3xl mx-auto p-4'>
      <h2 className='text-2xl font-semibold mb-4'>Confirmation</h2>
      <p className='mb-4 text-gray-500'>
        Please confirm the mapped fields before uploading the file. You can view the mapped fields
        below.
      </p>

      <div className='mb-4'>
        <label className='block text-sm font-medium text-gray-700'>Name of the file</label>
        <div className='mt-1 block w-full py-2 px-3 border border-gray-300 bg-gray-50 rounded-md shadow-sm sm:text-sm'>
          {selectedFile?.name}
        </div>
      </div>

      <div className='mb-4'>
        <label className='block text-sm font-medium text-gray-700'>Number of entries</label>
        <div className='mt-1 block w-14 rounded-full py-2 font-semibold px-3 text-center text-green-600 bg-green-100 shadow-sm sm:text-sm'>
          {totalEntries}
        </div>
      </div>

      <div className='mb-8'>
        <h3 className='text-lg font-semibold mb-2'>Mapped file headers</h3>
        <div className='space-y-4 max-w-max'>
          {Object.entries(mappedFields).map(
            ([fieldId, field]: [
              string,
              {
                name: string
                value: string
              },
            ]) => (
              <div key={fieldId} className='flex justify-between items-center gap-[150px]'>
                <label className='block text-sm font-medium capitalize text-gray-700'>
                  {field.name}
                </label>
                <div className='mt-1 block py-2 px-3 border border-gray-300 bg-gray-50 rounded-md shadow-sm sm:text-sm w-[230px]'>
                  {field.value}
                </div>
              </div>
            ),
          )}
        </div>
      </div>

      <form className='mb-8 w-[30rem]' onSubmit={handleConfirmSubmit}>
        <div className='mb-8 w-[30rem]'>
          <label htmlFor='reason' className='block text-sm font-medium text-gray-700'>
            Reason for Inventory Adjustment <span className='text-red-500'>*</span>
          </label>
          <textarea
            id='reason'
            rows={4}
            className='mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-[#224E73] focus:border-[#224E73] sm:text-sm'
            placeholder='Please provide a reason'
            required
            value={reason}
            onChange={(event) => {
              setReason(event.target.value)
            }}
          />
        </div>

        <div className='flex gap-4'>
          <button
            className='px-4 py-2 text-sm font-medium text-gray-600 bg-gray-100 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500'
            onClick={() => {
              // go back to previous step
              setUploadStage(UploadStage.MapFields)
            }}
          >
            Go back
          </button>
          <button
            className='px-6 py-3 text-base font-medium text-white bg-[#224E73] rounded-md hover:bg-[#224f73c0] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#224E73]'
            type='submit'
          >
            Run Validation Check
          </button>
        </div>
      </form>
    </div>
  )
}

export default Confirmation
