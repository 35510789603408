import React, { useEffect, useRef, useState } from 'react';
import Tooltip from './Tooltip';
import LoadingIndicator from './LoadingIndicator';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

export type Column<T> = {
  key: string;
  title: string | (() => React.ReactNode);
  accessor: (row: T, index?: number) => React.ReactNode;
  headerClassnamePerColumn?: string;
  infoText?: string;
  isSort?: boolean;
};

interface InfiniteDynamicTableProps<T> {
  columns: Column<T>[];
  data: T[];
  onSort: (key: string, direction: 'ascending' | 'descending') => void;
  loadingData?: boolean;
  fetchMoreData: () => void;
  hasMore: boolean;
  noResultsText?: string;
  totalDataCount: number;
  headerClassname?: string;
  rowsClassname?: string;
  onRowClick?: (data: T) => void;
}

const InfiniteScrollingTable = <T,>({
  columns,
  data,
  onSort,
  loadingData = false,
  fetchMoreData,
  hasMore,
  noResultsText = 'No results found',
  totalDataCount,
  headerClassname,
  rowsClassname,
  onRowClick = () => {},
}: InfiniteDynamicTableProps<T>) => {
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: 'ascending' | 'descending';
  } | null>(null);
  const infiniteLoader = useRef<HTMLDivElement | null>(null);
  const tableRef = useRef<HTMLDivElement>(null);
  const [tableHeight, setTableHeight] = useState('68rem');
  const [isVisible, setIsVisible] = useState(false);
  const [showNoResults, setShowNoResults] = useState(false);

  const handleSort = (key: string) => {
    let direction: 'ascending' | 'descending' = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
    onSort(key, direction);
  };

  const attachObserver = () => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && hasMore) {
            fetchMoreData();
          }
        });
      },
      {
        root: null,
        threshold: 0.5,
      },
    );

    if (infiniteLoader.current) {
      observer.observe(infiniteLoader.current);
    }

    return observer;
  };

  // Use an effect to set a timer for showing "No results" text if there's no data
  useEffect(() => {
    if (!loadingData && data.length === 0) {
      const timer = setTimeout(() => {
        setShowNoResults(true);
        setIsVisible(true);
      }, 1000); // 2 seconds delay

      // Clear the timeout if the component unmounts or if loadingData or data changes
      return () => clearTimeout(timer);
    } else {
      setShowNoResults(false);
    }
  }, [loadingData, data]);
  useEffect(() => {
    const observer = attachObserver();

    return () => {
      if (infiniteLoader.current) {
        observer.unobserve(infiniteLoader.current);
      }
    };
  }, [data, totalDataCount, hasMore]);

  useEffect(() => {
    const updateTableHeight = () => {
      if (tableRef.current) {
        const windowHeight = window.innerHeight;
        const tableTop = tableRef.current.getBoundingClientRect().top;
        const newHeight = Math.max(windowHeight - tableTop - 20, 400);
        setTableHeight(`${newHeight}px`);
      }
    };

    updateTableHeight();
    window.addEventListener('resize', updateTableHeight);

    return () => {
      window.removeEventListener('resize', updateTableHeight);
    };
  }, []);

  const renderHeader = (header: string | (() => React.ReactNode)) => {
    if (typeof header === 'string') {
      return header;
    } else {
      return header();
    }
  };

  return (
    <div className='h-full w-full font-inter scroll-smooth'>
      <div
        ref={tableRef}
        className='relative h-full w-full rounded-lg overflow-auto'
        style={{ height: tableHeight }}
      >
        <div
          ref={tableRef}
          className='relative w-full overflow-auto bg-white '
          style={{ height: tableHeight }}
        >
          <table className='w-full  border-collapse'>
            <thead className='sticky top-0 z-[9] bg-gray-50 shadow-sm'>
              <tr>
                {columns.map((column) => (
                  <th
                    key={column.key}
                    className={`p-4 text-left ${headerClassname} ${column.headerClassnamePerColumn}`}
                    onClick={() => column.isSort && handleSort(column.key)}
                  >
                    <div className='flex items-center'>
                      {renderHeader(column.title)}
                      {column.infoText && (
                        <Tooltip title={column.infoText}>
                          <ExclamationCircleIcon className='ml-1 h-4 w-4 text-gray-500' />
                        </Tooltip>
                      )}
                      {column.isSort && (
                        <svg
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          className='ml-1 h-6 w-6 cursor-pointer'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M8 9L12 5L16 9M16 15L12 19L8 15'
                            stroke='#717679'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {loadingData ? (
                <tr>
                  <td colSpan={columns.length} className='text-center py-4'>
                    <LoadingIndicator />
                  </td>
                </tr>
              ) : data.length === 0 && !showNoResults ? (
                // Show loader for a short duration before "No results"
                <tr>
                  <td colSpan={columns.length} className='text-center py-4'>
                    <LoadingIndicator />
                  </td>
                </tr>
              ) : data.length === 0 && showNoResults ? (
                // Show "No results" text after the delay
                <tr className='absolute inset-0'>
                  <td
                    colSpan={columns.length}
                    className={`
                      h-full
                      flex items-center justify-center
                      transition-opacity duration-1000 ease-in-out
                      ${isVisible ? 'opacity-100' : 'opacity-0'}
                    `}
                    style={{ height: tableHeight }}
                  >
                    <p className='text-xl font-semibold text-gray-600'>{noResultsText}</p>
                  </td>
                </tr>
              ) : (
                data.map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    className={`h-16 ${
                      rowIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                    } hover:bg-gray-100 transition-colors duration-150 ease-in-out ${rowsClassname}`}
                    onClick={() => onRowClick(row)}
                  >
                    {columns.map((column) => (
                      <td
                        key={column.key}
                        className='p-4 text-ellipsis whitespace-nowrap border-b-[0.5px]'
                      >
                        {column.accessor(row, rowIndex)}
                      </td>
                    ))}
                  </tr>
                ))
              )}
            </tbody>
          </table>
          {hasMore && (
            <div className='flex h-14 w-full items-center justify-center' ref={infiniteLoader}>
              <LoadingIndicator shouldShowOnPage={false} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InfiniteScrollingTable;
