import React from 'react'
import Select from 'react-select'

import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager'

const SearchableListbox = (props: StateManagerProps) => {
  return <Select {...props} />
}

export default SearchableListbox
