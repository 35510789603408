import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import type { ThunkDispatch } from '@reduxjs/toolkit';
import type { Action } from '@reduxjs/toolkit';
import type { FbaPackingState } from '../FbaPacking/fbaPackingSlice';

interface RootState {
  fbaPacking: FbaPackingState;
}

export type AppDispatch = ThunkDispatch<RootState, undefined, Action>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
