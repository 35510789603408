import React from 'react'
import BaseTable from '../components/common/BaseTable'

const Orders = () => {
  return (
    <div className='p-2'>
      <BaseTable />
    </div>
  )
}

export default Orders
