// <reference no-default-lib="true"/>
import { useAuth } from '@clerk/clerk-react'

const downloadBlob = (blob, filename) => {
  // Create a temporary URL for the blob
  const url = window.URL.createObjectURL(blob)

  // Create an anchor element
  const a = document.createElement('a')
  a.href = url

  // Specify the filename
  a.download = filename

  // Programmatically click the anchor to initiate the download
  a.click()

  // Cleanup: remove the temporary URL
  window.URL.revokeObjectURL(url)
}

export default function useDownload() {
  const { getToken } = useAuth()
  const baseUrl = process.env.REACT_APP_BACKEND_API_ENDPOINT

  const authenticatedDownload = async (fileName: string, ...args: any) => {
    const response = await fetch(baseUrl + args[0], {
      ...args[1],
      headers: {
        Authorization: `Bearer ${await getToken()}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })

    const contentType = response.headers.get('content-type')

    if (contentType.includes('application/pdf')) {
      // PDF file
      const blob = await response.blob()
      downloadBlob(blob, `${fileName}.pdf`)
    } else if (contentType.includes('text/csv')) {
      // CSV file
      const text = await response.text()
      const blob = new Blob([text], { type: 'text/csv' })
      downloadBlob(blob, `${fileName}.csv`)
    } else {
      throw new Error('Unsupported file type')
    }
  }

  return authenticatedDownload
}
