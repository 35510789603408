import { Disclosure, Transition } from '@headlessui/react';
import React from 'react';
import { capitalize } from './utils';

interface FilterAccordionProps {
  title: string;
  options: any[];
  checked: any;
  onChange: any;
  uniqueIndex: string | null;
}

const FilterAccordion = ({
  title,
  options,
  checked,
  onChange,
  uniqueIndex,
}: FilterAccordionProps) => {
  return (
    <Disclosure>
      <Disclosure.Button className='py-2 w-full'>
        <div className='text-left text-[#224E73] border-b border-[#E0E0E0] rounded p-2 w-full'>
          {title}
        </div>
      </Disclosure.Button>
      <Transition
        enter='transition duration-100 ease-out'
        enterFrom='transform scale-95 opacity-0'
        enterTo='transform scale-100 opacity-100'
        leave='transition duration-75 ease-out'
        leaveFrom='transform scale-100 opacity-100'
        leaveTo='transform scale-95 opacity-0'
      >
        <Disclosure.Panel className='text-gray-500'>
          {options?.map((option, index) => {
            return (
              <div key={index} className='flex gap-2'>
                <input
                  checked={uniqueIndex ? checked(option[uniqueIndex]) : checked(option)}
                  onChange={() => onChange(uniqueIndex ? option[uniqueIndex] : option)}
                  id={`${title}_${index}`}
                  name='comments'
                  type='checkbox'
                  className='h-4 w-4 my-2 rounded border-gray-300 text-hopstack-blue-600 focus:ring-hopstack-blue-600'
                />
                <label
                  htmlFor={`${title}_${index}`}
                  className='font-medium text-[#224E73] my-auto text-[14px]'
                >
                  {uniqueIndex ? option.name : capitalize(option)}
                </label>
              </div>
            );
          })}
        </Disclosure.Panel>
      </Transition>
    </Disclosure>
  );
};

export default FilterAccordion;
