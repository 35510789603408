// MapFields.tsx

import React, { useContext, useState } from 'react'
import { ArrowRightIcon } from '@heroicons/react/20/solid'
import { UploadStage, ColumnHeader } from '../../pages/Inventory'
import BaseContext from '../common/BaseContext'

interface MapFieldsProps {
  setUploadStage: (stage: UploadStage) => void
  fileHeaders: string[]
  mappedFields: Record<string, { name: string; value: string }>
  setMappedFields: (value: React.SetStateAction<any>) => void
  selectedFile: File | null
}

/**
 * Renders a component that allows the user to map fields from an uploaded file to system-supported fields.
 * The component handles the state of the form data and the mapped fields, and provides a submit button
 * that checks if all fields have been mapped before allowing the user to proceed to the next stage.
 */
const MapFields: React.FC<MapFieldsProps> = ({
  setUploadStage,
  fileHeaders,
  mappedFields,
  setMappedFields,
  selectedFile,
}) => {
  const [formData, setFormData] = useState<{ [key: string]: string }>({})
  const { setShowNotification } = useContext(BaseContext)

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }))
    setMappedFields((prevMappedFields: Record<string, any>) => ({
      ...prevMappedFields,
      [name]: {
        name,
        value,
      },
    }))
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (Object.entries(mappedFields).length < ColumnHeader.length) {
      setShowNotification({
        show: true,
        type: 'warning',
        content: 'Please map all fields before continuing',
      })
      return
    }

    setUploadStage(UploadStage.Confirmation)
  }

  return (
    <div className='max-w-3xl mx-auto p-4'>
      <h2 className='text-2xl font-semibold mb-4'>Map Fields</h2>
      <p className='mb-4 text-gray-500'>
        Please map the fields from the chosen file to the system-supported fields. We have already
        mapped some headers based on similarity.
      </p>

      <div className='mb-4'>
        <p className='mb-1 text-gray-500'>
          Uploaded file:{' '}
          <span className='text-[#224E73] font-semibold underline'>{selectedFile.name}</span>
        </p>
      </div>

      <form onSubmit={handleSubmit}>
        <div className='mb-8'>
          <h3 className='text-lg text-gray-600 font-semibold mb-2'>Mapped field headers</h3>
          <div className='space-y-4 flex flex-col w-full justify-around'>
            {ColumnHeader.map((header) => (
              <div key={header.accessor} className='flex items-center gap-4'>
                <label
                  htmlFor={header.accessor}
                  className='block text-sm w-[15rem] font-medium text-gray-700'
                >
                  {header.name}
                </label>
                <select
                  id={header.accessor}
                  name={header.accessor}
                  className='mt-1 block w-[15rem] py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-[#224E73] focus:border-[#224E73] sm:text-sm'
                  onChange={handleChange}
                  value={formData[header.accessor] || mappedFields[header.accessor]?.value || ''}
                >
                  <option value='' disabled>
                    Column name for {header.name}
                  </option>
                  {fileHeaders.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            ))}
          </div>
        </div>

        <div className='flex gap-4'>
          <button
            className='px-4 py-2 text-sm font-medium text-gray-600 bg-gray-100 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500'
            onClick={(e) => {
              // Go back to the previous step
              e.preventDefault()
              setUploadStage(UploadStage.UploadFile)
            }}
          >
            Go back
          </button>
          <button
            type='submit'
            className='px-6 flex py-3 text-base font-medium text-white bg-[#224E73] rounded-md hover:bg-[#224f73c0] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#224E73]'
          >
            <div className=''>Continue</div>
            <ArrowRightIcon className='w-4 h-4 my-auto ml-2' />
          </button>
        </div>
      </form>
    </div>
  )
}

export default MapFields
