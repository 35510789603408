import React from 'react';
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline';

interface WarningModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
}

const WarningModal: React.FC<WarningModalProps> = ({ isOpen, onClose, onDelete }) => {
  if (!isOpen) return null;

  return (
    <div className='fixed inset-0 z-[30] bg-black bg-opacity-50 flex items-center justify-center p-4'>
      <div className='bg-white rounded-lg shadow-xl max-w-md w-full'>
        <div className='p-6'>
          <div className='flex justify-between items-start'>
            <div className='flex items-center'>
              <ExclamationTriangleIcon className='h-6 w-6 text-yellow-400 mr-3' />
              <h3 className='text-lg font-semibold text-gray-900'>Warning!</h3>
            </div>
            <button onClick={onClose} className='text-gray-400 hover:text-gray-500'>
              <XMarkIcon className='h-5 w-5' />
            </button>
          </div>
          <p className='mt-4 text-sm text-gray-500'>
            Deleting this connection will disconnect the chosen service from Hopstack Ignite. Any
            activities and information dependent on this connection will no longer work.
          </p>
          <p className='mt-4 text-sm text-gray-700 font-medium'>
            Are you sure you want to proceed?
          </p>
          <div className='mt-6 flex justify-end space-x-3'>
            <button
              onClick={onClose}
              className='px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50'
            >
              Cancel
            </button>
            <button
              onClick={onDelete}
              className='px-4 py-2 bg-red-700/90 border border-transparent rounded-md text-sm font-medium text-white hover:bg-red-800'
            >
              Delete Connection
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarningModal;
