import React, { useState } from 'react';
// import useFetch from './useFetch'
import CreateWarehouse from '../Onboarding/CreateWarehouse';
// import BaseContext from './BaseContext'

interface WarehouseProps {
  warehouses: any[];
}
const Warehouses = ({ warehouses }: WarehouseProps) => {
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);

  return selectedWarehouse ? (
    <div className='py-4'>
      <CreateWarehouse
        warehouse={selectedWarehouse}
        setSelectedWarehouse={setSelectedWarehouse}
        allowUsingOrgData={false}
      />
    </div>
  ) : (
    <div className=''>
      <div className='sm:flex sm:items-center'>
        <div className='sm:flex-auto'>
          <h1 className='text-base font-semibold leading-6 text-gray-900'>Warehouses</h1>
          <p className='mt-2 text-sm text-gray-700'>
            A list of all the warehouses in your account.
          </p>
        </div>
        <div className='mt-4 sm:ml-16 sm:mt-0 sm:flex-none'>
          {/* <button
            type='button'
            className='block rounded-md bg-hopstack-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-hopstack-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hopstack-blue-600'
          >
            Add warehouse
          </button> */}
        </div>
      </div>
      <div className='mt-8 flow-root'>
        <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
            <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg'>
              <table className='min-w-full divide-y divide-gray-300'>
                <thead className='bg-gray-50'>
                  <tr>
                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                    >
                      Name
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      Number
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      Email
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      Address
                    </th>
                    <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                      <span className='sr-only'>Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200 bg-white'>
                  {warehouses.map((warehouse) => (
                    <tr key={warehouse.email}>
                      <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                        {warehouse.name}
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        {'+' +
                          warehouse.mobileNumber.countryCode +
                          ' ' +
                          warehouse.mobileNumber.number}
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        {warehouse.email}
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        {warehouse.shippingAddress.line1}
                      </td>
                      <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
                        <button
                          onClick={() => setSelectedWarehouse(warehouse)}
                          className='text-hopstack-blue-600 hover:text-hopstack-blue-900'
                        >
                          Edit<span className='sr-only'>, {warehouse.name}</span>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Warehouses;
