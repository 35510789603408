import { createApiThunk } from '../ReduxUtils/fetchApi';

// Define your API endpoints
const API_ENDPOINTS = {
  CONFIRM_PACKING: '/api/v1/outbound-orders/{outboundOrderId}/confirm-packing-option',
  SET_PACKING_INFO: '/api/v1/outbound-orders/packing-information',
  GET_ERRORS: '/api/v1/outbound-orders/{outboundOrderId}/errors',
  CANCEL_INBOUND_PLAN: '/api/v1/outbound-orders/{outboundOrderId}/amazon-inbound-plan/cancel',
  GET_PLACEMENTS: '/api/v1/outbound-orders/{outboundOrderId}/placement-options',
  GET_TRANSPORTATION: '/api/v1/outbound-orders/{outboundOrderId}/transportation-options',
  LIST_SHIPMENTS: '/api/v1/fulfillments',
  START_INBOUND_PLAN: '/api/v1/outbound-orders/{outboundOrderId}/amazon-inbound-plan',
  GET_PACKING_OPTIONS: '/api/v1/outbound-orders/{outboundOrderId}/packing-options',
  CONFIRM_TRANSPORTATION: '/api/v1/outbound-orders/confirm-transportation-options',
  CONFIRM_PLACEMENT: '/api/v1/outbound-orders/{outboundOrderId}/confirm-placement-option',
  GET_DELIVERY_WINDOW_OPTIONS: '/api/v1/outbound-orders/{outboundOrderId}/delivery-window-options',
  CONFIRM_DELIVERY_WINDOW_OPTIONS: '/api/v1/outbound-orders/confirm-delivery-window-options',
  CONFIRM_PACKING_COMPLETED: '/api/v1/outbound-orders/confirm-packing-completed',
  REGENERATE_TRANSPORTATION_OPTIONS: '/api/v1/outbound-orders/regenerate-transportation-options',
};

export const startAmazonInboundPlanCreation = createApiThunk(
  'fbaPacking/startAmazonInboundPlanCreation',
  API_ENDPOINTS.START_INBOUND_PLAN,
);

export const confirmPackingOptions = createApiThunk(
  'fbaPacking/confirmPackingOptions',
  API_ENDPOINTS.CONFIRM_PACKING,
  (response) => response.data,
);

export const setPackingInformation = createApiThunk(
  'fbaPacking/setPackingInformation',
  API_ENDPOINTS.SET_PACKING_INFO,
  (response) => response.data,
);

export const getErrorsInInboundPlan = createApiThunk(
  'fbaPacking/getErrorsInInboundPlan',
  API_ENDPOINTS.GET_ERRORS,
  (response) => response.data,
);

export const getPackingOptions = createApiThunk(
  'fbaPacking/getPackingOptions',
  API_ENDPOINTS.GET_PACKING_OPTIONS,
  (response) => response.data,
);

export const cancelAmazonInboundPlan = createApiThunk(
  'fbaPacking/cancelAmazonInboundPlan',
  API_ENDPOINTS.CANCEL_INBOUND_PLAN,
  (response) => response.data,
);

export const getPlacementsOptions = createApiThunk(
  'fbaPacking/getPlacementsOptions',
  API_ENDPOINTS.GET_PLACEMENTS,
  (response) => response.data,
);

export const getTransportationOptions = createApiThunk(
  'fbaPacking/getTransportationOptions',
  API_ENDPOINTS.GET_TRANSPORTATION,
  (response) => response.data,
);

export const confirmTransportationOptions = createApiThunk(
  'fbaPacking/confirmTransportationOptions',
  API_ENDPOINTS.CONFIRM_TRANSPORTATION,
  (response) => response.data,
);

export const confirmPlacementOption = createApiThunk(
  'fbaPacking/confirmPlacementOption',
  API_ENDPOINTS.CONFIRM_PLACEMENT,
  (response) => response.data,
);

export const getDeliveryWindowOptions = createApiThunk(
  'fbaPacking/getDeliveryWindowOptions',
  API_ENDPOINTS.GET_DELIVERY_WINDOW_OPTIONS,
  (response) => response.data,
);

export const confirmDeliveryWindowOptions = createApiThunk(
  'fbaPacking/confirmDeliveryWindowOptions',
  API_ENDPOINTS.CONFIRM_DELIVERY_WINDOW_OPTIONS,
  (response) => response.data,
);

export const listShipments = createApiThunk(
  'fbaPacking/listShipments',
  API_ENDPOINTS.LIST_SHIPMENTS,
);

export const regenerateTransportationOptions = createApiThunk(
  'fbaPacking/regenerateTransportationOptions',
  API_ENDPOINTS.REGENERATE_TRANSPORTATION_OPTIONS,
  (response) => response.data,
);

export const confirmPackingCompleted = createApiThunk(
  'fbaPacking/confirmPackingCompleted',
  API_ENDPOINTS.CONFIRM_PACKING_COMPLETED,
  (response) => response.data,
);
