import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import BaseContext from '../components/common/BaseContext';
import { ShopifyLocationSelector } from '../components/OAuth/ShopifyLocationSelector';

const ShopifyAuthRedirection = () => {
  const cookies = new Cookies(null, { path: '/' });
  const { setShowNotification, setLoading } = useContext(BaseContext);
  const integrationData = cookies.get('integration_data');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [integrationId, setIntegrationId] = useState('');

  useEffect(() => {
    const addIntegrationAsync = async () => {
      try {
        setLoading(true);
        if (!searchParams.get('accessTokenKey') || !searchParams.get('shop')) {
          setShowNotification({
            show: true,
            type: 'failure',
            content: 'Unable to add integration, please try again.',
          });
          navigate(`/${integrationData.organizationId}/connections`);
        }
        setIntegrationId(integrationData.integrationId);
      } catch (error) {
        console.log(error);
        setShowNotification({
          show: true,
          type: 'failure',
          content: error.message || 'An error occurred',
        });
        navigate(`/${integrationData.organizationId}/connections`);
      } finally {
        setLoading(false);
      }
    };

    addIntegrationAsync();
  }, [integrationData?.organizationId]);

  return (
    <>
      {integrationId ? (
        <ShopifyLocationSelector
          integrationId={integrationId}
          organizationId={integrationData.organizationId}
        />
      ) : (
        <>Adding integration</>
      )}
    </>
  );
};

export default ShopifyAuthRedirection;
