import React, { useEffect, useState } from 'react';
import { ClockIcon, ArrowsPointingOutIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);
import groupIcon from '../assets/images/groupIcon.png';
import PlacementOptionOverview from './PlacementOptionOverview';
import SlideOver from '../components/common/SlideOver';
import HeaderWithArrow from '../components/common/HeaderWithArrow';
import { useSelector } from 'react-redux';
import Button from '../components/common/Button';
import FbaRateShopping from './FbaRateShopping';
import getSymbolFromCurrency from 'currency-symbol-map';

interface Fee {
  target: string;
  value: {
    amount: string;
  };
}

interface Address {
  city: string;
}

interface Destination {
  address: Address;
}

interface Item {
  quantity: number;
}

interface Dimensions {
  length: number;
  width: number;
  height: number;
}

interface BoxItem {
  asin: string;
  msku: string;
  fnSku: string;
  name: string;
  quantity: number;
  image?: string;
  bestByDate?: string;
}

interface Box {
  boxName: string;
  items: BoxItem[];
  dimensions: Dimensions;
  weight: number;
  assignedQuantity: number;
  source: string;
}

interface Shipment {
  shipmentId: string;
  destination: Destination;
  boxes: Box[];
  items: Item[];
}

interface TransportationQuote {
  cost: {
    amount: string;
  };
}

interface TransportationOption {
  transportationOptionId: string;
  quote: TransportationQuote;
  carrier: {
    alphaCode: string;
    name: string;
  };
}

interface TransportationOptionsPerShipment {
  shipmentId: string;
  transportationOptions: TransportationOption[];
}

interface PlacementOption {
  placementOptionId: string;
  fees: Fee[];
  shipmentIds: string[];
  shipmentsWithBoxesAndItems: Shipment[];
  expiration: string;
  discounts?: Array<{ value: string } | string>;
  value: {
    amount: string;
    code: string;
  };
}

interface Location {
  cityName: string;
  cityImage: string;
  boxes: number;
  items: number;
  cost: number;
  shipmentId: string;
  hasTransportation: boolean;
  code?: string;
}

interface SelectedRates {
  [key: string]: {
    transportationOptionId: string;
    uniqueId?: string;
  };
}

interface SelectPlacementOptionsProps {
  placementOptions: PlacementOption[];
  selectedPlacementOption: string;
  setSelectedPlacementOption: (id: string) => void;
  selectedRates: SelectedRates;
  setSelectedRates: React.Dispatch<React.SetStateAction<SelectedRates>>;
  pollTransportationOptions: () => void;
  isLtlFlow: boolean;
  setShowNotification: (notification: { show: boolean; type: string; content: string }) => void;
}

const SelectPlacementOptions: React.FC<SelectPlacementOptionsProps> = ({
  placementOptions,
  selectedPlacementOption,
  setSelectedPlacementOption,
  selectedRates,
  setSelectedRates,
  pollTransportationOptions,
  isLtlFlow,
  setShowNotification,
}) => {
  const { transportationResult: getTransportationsOptionsResult } = useSelector(
    (state: any) => state.fbaPacking,
  );

  const [transportationOptions, setTransportationOptions] = useState<
    TransportationOptionsPerShipment[]
  >([]);
  const [openPlacmentOptionOverview, setOpenPlacementOptionOverview] = useState<boolean>(false);
  const [placementOptionForOverview, setPlacementOptionForOverview] =
    useState<PlacementOption | null>(null);

  const [showRateShopping, setShowRateShopping] = useState<boolean>(false);
  const [selectedOptionForRates, setSelectedOptionForRates] = useState<PlacementOption | null>(
    null,
  );

  useEffect(() => {
    if (getTransportationsOptionsResult?.data?.transportationOptionsPerShipment) {
      const newOptions = getTransportationsOptionsResult.data.transportationOptionsPerShipment;

      setTransportationOptions((prev) => {
        const updatedOptions = [...prev];

        newOptions.forEach((newOption) => {
          const existingIndex = updatedOptions.findIndex(
            (option) => option.shipmentId === newOption.shipmentId,
          );

          if (existingIndex > -1) {
            // Replace existing option
            updatedOptions[existingIndex] = newOption;
          } else {
            // Add new option
            updatedOptions.push(newOption);
          }
        });

        return updatedOptions;
      });
    }
  }, [getTransportationsOptionsResult]);

  const formatExpiryDate = (expiryDate: string): string => {
    const date = dayjs(expiryDate);
    return `Expires ${date.fromNow()}`;
  };

  const getCurrencySymbol = (amount, currency) => {
    if (!amount || !currency) return '$'; // Default
    const symbol = getSymbolFromCurrency(currency) || '$';
    return symbol;
  };

  // Update handleRateSelection to accept uniqueId
  const handleRateSelection = (
    shipmentId: string,
    transportationOptionId: string,
    uniqueId: string,
    carrierName: string,
    isPartnered: boolean,
  ) => {
    setSelectedRates((prev) => ({
      ...prev,
      [shipmentId]: {
        transportationOptionId,
        uniqueId:
          uniqueId ||
          `${transportationOptionId}_${transportationOptions
            .find((opt) => opt.shipmentId === shipmentId)
            ?.transportationOptions.find(
              (rate) => rate.transportationOptionId === transportationOptionId,
            )?.carrier.alphaCode}`,
        alphaCode: uniqueId?.split('_')?.[1],
        carrierName,
        isPartnered,
      },
    }));
  };

  const handleRateShoppingCancel = (): void => {
    setShowRateShopping(false);
  };

  const handleRateShoppingConfirm = (): void => {
    setShowRateShopping(false);
  };

  const getLocationRate = (shipmentId: string): number => {
    const selectedOption = selectedRates[shipmentId];
    if (!selectedOption?.transportationOptionId) return 0;

    const shipmentRates =
      transportationOptions.find((option) => option.shipmentId === shipmentId)
        ?.transportationOptions || [];

    // Find rate using both transportationOptionId and carrier alphaCode
    const selectedRate = shipmentRates.find((rate) => {
      if (selectedOption.uniqueId) {
        // If uniqueId exists, use it for comparison
        return (
          `${rate.transportationOptionId}_${rate.carrier.alphaCode}` === selectedOption.uniqueId
        );
      }
      // Fallback to just transportationOptionId for backward compatibility
      return rate.transportationOptionId === selectedOption.transportationOptionId;
    });

    // Don't display rate if carrier is "other"
    if (selectedRate?.carrier?.name?.toLowerCase() === 'other') {
      return null;
    }

    return selectedRate ? parseFloat(selectedRate.quote.cost.amount) : 0;
  };

  const getFeeAmount = (option: PlacementOption): number => {
    const placementFee = option.fees.find((fee) => fee.target === 'Placement Services');
    return placementFee ? parseFloat(placementFee.value.amount) : 0;
  };

  const transformShipmentToLocation = (shipment: Shipment): Location => {
    const hasTransportation = hasTransportationData(shipment.shipmentId);
    const selectedRate = getLocationRate(shipment.shipmentId);

    return {
      cityName: shipment.destination.address.city,
      cityImage: groupIcon,
      boxes: shipment.boxes?.length,
      items: shipment.items?.reduce((sum, item) => sum + item.quantity, 0),
      cost: selectedRate,
      shipmentId: shipment.shipmentId,
      hasTransportation,
    };
  };

  const hasTransportationData = (shipmentId: string): boolean => {
    return transportationOptions.some((option) => option.shipmentId === shipmentId);
  };

  const hasAllTransportationData = (option: PlacementOption): boolean => {
    return option.shipmentIds.every((shipmentId) => hasTransportationData(shipmentId));
  };

  return (
    <div className='p-6 font-inter'>
      <div className='mb-4 text-lg font-semibold'>
        Total No of Options: {placementOptions.length}
      </div>
      <div className='flex flex-col gap-12'>
        {placementOptions.map((option, index) => {
          const feeAmount = getFeeAmount(option);
          const locations = option.shipmentsWithBoxesAndItems.map(transformShipmentToLocation);
          const hasAllTransport = hasAllTransportationData(option);

          return (
            <div
              key={option.placementOptionId}
              className={`flex flex-col rounded-lg border px-4 pt-4 ${
                selectedPlacementOption === option.placementOptionId
                  ? 'border-primaryAccent-500 shadow-lg'
                  : 'border-gray-300'
              }`}
            >
              <div
                key={option.placementOptionId}
                style={{ minHeight: '12rem' }}
                className={`relative flex min-h-60 items-center`}
              >
                {/* Discount Tag */}
                {option.discounts?.length > 0 && (
                  <div
                    style={{
                      borderTopRightRadius: '1rem',
                      borderBottomRightRadius: '1rem',
                      left: '-1rem',
                      top: '0rem',
                    }}
                    className='absolute inline-block rounded-r-lg bg-blue-100 px-2 py-1 text-xs font-semibold text-blue-600'
                  >
                    {`${'option?.discounts[0]?.value || option.discounts[0]'}% Discount Applied`}{' '}
                  </div>
                )}

                {/* Radio Button */}
                <div className='mt-2'>
                  <input
                    type='radio'
                    name='placementOption'
                    checked={selectedPlacementOption === option.placementOptionId}
                    onChange={() => setSelectedPlacementOption(option.placementOptionId)}
                    className='h-5 w-5 cursor-pointer text-primaryAccent focus:ring-primaryAccent'
                  />
                </div>

                {/* Option Details */}
                <div className='ml-4 w-60'>
                  <div className='mt-2'>
                    <div className='text-sm text-gray-500'>Option {index + 1}</div>
                    <div className='text-lg font-medium'>
                      {feeAmount > 0
                        ? `${getCurrencySymbol(
                            feeAmount,
                            option.value?.code,
                          )}${feeAmount} Placement Fee`
                        : 'No Placement Fee'}
                    </div>
                    <div className='text-sm text-gray-500'>
                      Applicable for {locations.length} warehouses
                    </div>
                  </div>
                </div>

                {/* Locations */}
                <div className='ml-8 flex flex-1 flex-wrap gap-8'>
                  {locations.map((location, locationIndex) => (
                    <div
                      key={locationIndex}
                      className='flex min-w-[140px] flex-col items-center justify-center text-center'
                    >
                      <div className='flex min-h-[40px] items-center justify-center'>
                        <span className='whitespace-nowrap text-sm font-medium'>
                          {location.cityName}
                        </span>
                      </div>
                      <div className='relative'>
                        <img
                          src={location.cityImage}
                          alt={location.cityName}
                          className='my-2 h-12 w-12 rounded-md object-cover'
                        />
                      </div>
                      <span className='text-xs text-gray-500'>
                        {location.boxes} Boxes | {location.items} Items
                      </span>
                      {/* Add this line to show selected rate */}
                      <span className='mt-1 text-xs font-medium text-gray-900'>
                        {getLocationRate(location.shipmentId) !== null &&
                        getLocationRate(location.shipmentId) > 0
                          ? `${getCurrencySymbol(location.cost, location.code)}${getLocationRate(
                              location.shipmentId,
                            )}`
                          : getLocationRate(location.shipmentId) === null
                            ? 'Manual Tracking'
                            : ''}
                      </span>
                    </div>
                  ))}
                </div>

                {/* Expiry Tag */}
                <div
                  style={{
                    top: '-2.5rem',
                    borderTopRightRadius: '1rem',
                    borderBottomLeftRadius: '2rem',
                    right: '1rem',
                  }}
                  className='absolute right-4 top-2 flex -translate-y-6 items-center rounded-t-lg bg-yellow-100 px-2 py-1 text-xs text-yellow-700'
                >
                  <ClockIcon className='mr-1 inline-block h-4 w-4' />
                  {formatExpiryDate(option.expiration)}
                </div>

                {/* Expand Icon */}
                <div className='absolute right-4 top-4 cursor-pointer text-gray-400 hover:text-gray-600'>
                  <ArrowsPointingOutIcon
                    onClick={() => {
                      setPlacementOptionForOverview(option);
                      setOpenPlacementOptionOverview(true);
                    }}
                    className='h-5 w-5'
                  />
                </div>
              </div>
              <div className='px-4 py-3'>
                <div className='flex items-center justify-between'>
                  <div className='flex items-center gap-2'>
                    <span className='text-sm font-medium text-gray-600'>Total Cost</span>
                    <span className='text-lg font-semibold text-gray-900'>
                      {`${getCurrencySymbol(option.value?.amount, option.value?.code)}${(
                        getFeeAmount(option) +
                        option.shipmentsWithBoxesAndItems.reduce((sum, shipment) => {
                          const rateAmount = getLocationRate(shipment.shipmentId);
                          return sum + (rateAmount !== null ? rateAmount : 0);
                        }, 0)
                      ).toFixed(2)}`}
                      {option.shipmentsWithBoxesAndItems.some(
                        (shipment) => getLocationRate(shipment.shipmentId) === null,
                      ) && ' + Manual Tracking'}
                    </span>
                  </div>
                  {!isLtlFlow && (
                    <div>
                      {!hasAllTransport ? (
                        <span className='text-sm text-gray-500'>
                          Transportation options loading...
                        </span>
                      ) : (
                        <Button
                          variant='primary'
                          className='text-sm font-medium'
                          onClick={() => {
                            setSelectedOptionForRates(option);
                            setShowRateShopping(true);
                          }}
                        >
                          Select Transportation Options
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <SlideOver
        open={openPlacmentOptionOverview}
        XLarge={true}
        setOpen={setOpenPlacementOptionOverview}
        isCrossIconVisible={false}
        title={
          <HeaderWithArrow
            headerTitle={`Placement Option ${
              placementOptions?.findIndex(
                (option) => option.placementOptionId === selectedPlacementOption,
              ) + 1
            } - Overview`}
            description={`Review the breakdown of boxes assigned under Placement Option ${
              placementOptions?.findIndex(
                (option) => option.placementOptionId === selectedPlacementOption,
              ) + 1
            }.`}
            isLearnMore={false}
            isArrow={true}
            arrowAction={() => setOpenPlacementOptionOverview(false)}
            mainClasses='mb-0'
          />
        }
      >
        <PlacementOptionOverview placementOption={placementOptionForOverview} />
      </SlideOver>
      <SlideOver
        open={showRateShopping}
        XLarge={true}
        setOpen={setShowRateShopping}
        isCrossIconVisible={false}
        title={
          <HeaderWithArrow
            headerTitle='Rate Shopping Details'
            description='Select your preferred shipping rate for each location'
            isLearnMore={false}
            isArrow={true}
            arrowAction={() => setShowRateShopping(false)}
            mainClasses='mb-0'
          />
        }
      >
        {selectedOptionForRates && (
          <FbaRateShopping
            placementOption={selectedOptionForRates}
            transportationOptions={transportationOptions}
            selectedRates={selectedRates}
            onRateSelect={handleRateSelection}
            onCancel={handleRateShoppingCancel}
            onConfirm={handleRateShoppingConfirm}
            pollTransportationOptions={pollTransportationOptions}
            setSlideOverOpen={setShowRateShopping}
            setTransportationOptions={setTransportationOptions}
            setShowNotification={setShowNotification}
          />
        )}
      </SlideOver>
    </div>
  );
};

export default SelectPlacementOptions;
