export const apiPaths = {
  GET_ORGANIZATIONS: '/api/v1/organizations',
  GET_FULFILLMENTS: '/api/v1/fulfillments',
  CANCEL_FULFILLMENT: '/api/v1/fulfillments/{fulfillmentId}/cancel',
  GET_WAREHOUSES: '/api/v1/warehouses',
  GET_MERCHANTS: '/api/v1/merchants',
  UPDATE_MERCHANT: '/api/v1/merchants/{merchantId}',
  GET_INTEGRATIONS: '/api/v1/integrations',
  UPDATE_INTEGRATIONS: '/api/v1/integrations/{integrationId}',
  SEND_INVITATION: '/api/v1/user-invitations',
  GET_INVITATION: '/api/v1/user-invitations/me',
  GET_ORDER: '/api/v1/fulfillments/{orderId}',
  GET_OUTBOUND_ORDER: '/api/v1/outbound-orders/{orderId}',
  CANCEL_TRANSPORT: '/api/v1/cancel-shipment',
  GENERATE_LABELS: '/api/v1/label-generation-workflow',
  LABEL_GENERATION_WORKFLOW_STATUS: '/api/v1/label-generation-workflow-status',
  RATE_SHOPPING_WORKFLOW: '/api/v1/rate-shopping-workflow',
  WORK_FLOW_CARRIER_STATUS: '/api/v1/workflow-carrier-rates',
  INBOUND_ORDERS: '/api/v1/inbound-orders',
  LISTINGS: '/api/v1/listings',
  BUNDLES: '/api/v1/listings/bundles',
  SEARCH_MARKETPLACE_CATALOG: '/api/v1/search-marketplace-catalog/',
  GET_WAREHOUSE_LOCATIONS: '/api/v1/warehouses/{warehouseId}/locations',
  GET_INVENTORY: '/api/v1/inventory',
  UPDATE_INVENTORY_TRANSFER: '/api/v1/inventory/transfer',
};
