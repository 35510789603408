import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, ReactNode } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';

interface SlideOverProps {
  open: boolean;
  setOpen?: (open: boolean) => void;
  children: ReactNode;
  large?: boolean;
  XLarge?: boolean;
  title?: ReactNode;
  isCrossIconVisible?: boolean;
  XXLarge?: boolean;
}

const SlideOver: React.FC<SlideOverProps> = ({
  open,
  setOpen,
  large,
  XLarge,
  XXLarge,
  children,
  title,
  isCrossIconVisible = false,
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-50' onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter='ease-in-out duration-500'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in-out duration-500'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-hidden'>
          <div className='absolute inset-0 overflow-hidden'>
            <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10'>
              <Transition.Child
                as={Fragment}
                enter='transform transition ease-in-out duration-500 sm:duration-700'
                enterFrom='translate-x-full'
                enterTo='translate-x-0'
                leave='transform transition ease-in-out duration-500 sm:duration-700'
                leaveFrom='translate-x-0'
                leaveTo='translate-x-full'
              >
                <Dialog.Panel
                  className={`${
                    large
                      ? 'max-w-[1000px]'
                      : XLarge
                        ? 'max-w-[1200px]'
                        : XXLarge
                          ? 'max-w-[1500px]'
                          : 'max-w-md'
                  }  pointer-events-auto w-screen`}
                >
                  <div className='flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl'>
                    <div className='flex items-center px-2'>
                      {title && (
                        <div className='m-4 flex-1 text-xl font-semibold text-gray-900'>
                          {title}
                        </div>
                      )}
                      {isCrossIconVisible && (
                        <button
                          type='button'
                          className='relative rounded-md text-2xl text-[#111827] hover:text-black focus:outline-none focus:ring-2 focus:ring-white'
                          onClick={() => setOpen(false)}
                        >
                          <span className='absolute -inset-2.5' />
                          <span className='sr-only'>Close panel</span>
                          <XMarkIcon className='h-8 w-8' aria-hidden='true' />
                        </button>
                      )}
                    </div>
                    <div className='relative mt-6 flex-1 px-4 sm:px-6'>{children}</div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SlideOver;
