import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { Fragment } from 'react';

interface ModalV2Props {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title?: string;
  subtitle?: string;
  minWidth?: string;
  maxWidth?: string;
  maxHeight?: string;
  resetModal?: React.ReactNode;
  className?: string;
}

const ModalV2 = ({
  children,
  isOpen,
  onClose,
  title,
  subtitle,
  minWidth = '640px',
  maxWidth = '1280px',
  maxHeight = '480px',
  resetModal,
  className,
}: ModalV2Props) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as='div'
        className={`fixed inset-0 z-30 overflow-y-auto ${className}`}
        onClose={onClose}
      >
        <div className='min-h-screen px-4 text-center'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='duration-0'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black opacity-50' />
          </Transition.Child>

          <div className='flex min-h-screen items-center justify-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='duration-0'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div
                className='mx-auto inline-block transform overflow-visible rounded-3xl border-blue-500 bg-white p-8 text-left align-middle shadow-xl transition-all'
                style={{ minWidth, maxWidth }}
              >
                <div className='flex items-center justify-between pb-4 font-inter'>
                  <div>
                    <span className='font-inter text-2xl font-bold text-[#454A4F]'>
                      {title || 'Modal Title'}
                    </span>
                    {subtitle && <div className='text-gray-500'>{subtitle}</div>}
                  </div>
                  <div className='flex flex-row items-center justify-between'>
                    {resetModal}
                    <XMarkIcon className='h-6 w-6 cursor-pointer' onClick={onClose} />
                  </div>
                </div>
                <div
                  style={{ maxHeight }}
                  className='max-w-content flex flex-col gap-8 space-y-4 overflow-auto'
                >
                  {children}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ModalV2;
