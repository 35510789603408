import React, { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';

interface ButtonProps {
  className?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
}

interface CustomPopconfirmProps {
  title: string;
  onConfirm: () => void;
  onCancel?: () => void;
  okText?: string;
  cancelText?: string;
  icon?: React.ReactNode;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  children: React.ReactNode;
  okButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
}

const CustomPopconfirm: React.FC<CustomPopconfirmProps> = ({
  title,
  onConfirm,
  onCancel,
  okText = 'Yes',
  cancelText = 'No',
  icon = <QuestionMarkCircleIcon className='h-5 w-5 text-yellow-500' />,
  placement = 'top',
  children,
  okButtonProps,
  cancelButtonProps,
}) => {
  const placementClasses = {
    top: 'bottom-full mb-2',
    bottom: 'top-full mt-2',
    left: 'right-full mr-2',
    right: 'left-full ml-2',
  }[placement];

  return (
    <Popover className='relative'>
      {({ close }) => (
        <>
          <Popover.Button as='div'>{children}</Popover.Button>
          <Transition
            as={Fragment}
            enter='transition ease-out duration-200'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition ease-in duration-150'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Popover.Panel
              className={`absolute z-50 w-64 rounded-md bg-white p-4 shadow-lg ring-1 ring-black ring-opacity-5 ${placementClasses}`}
            >
              <div className='flex items-start space-x-3'>
                <div className='flex-shrink-0'>{icon}</div>
                <div className='flex-grow'>
                  <p className='text-sm text-gray-700'>{title}</p>
                  <div className='mt-3 flex justify-end space-x-2'>
                    <button
                      type='button'
                      className={`rounded-md border border-gray-300 bg-white px-3 py-1.5 text-sm font-medium text-gray-700 hover:bg-gray-50 ${
                        cancelButtonProps?.className || ''
                      }`}
                      onClick={() => {
                        onCancel?.();
                        close();
                      }}
                      {...cancelButtonProps}
                    >
                      {cancelText}
                    </button>
                    <button
                      type='button'
                      className={`rounded-md bg-red-600 px-3 py-1.5 text-sm font-medium text-white hover:bg-red-700 ${
                        okButtonProps?.className || ''
                      }`}
                      onClick={() => {
                        onConfirm();
                        close();
                      }}
                      {...okButtonProps}
                    >
                      {okText}
                    </button>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default CustomPopconfirm;
