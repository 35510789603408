import { DateTime } from 'luxon';

export interface DateRangeShortcut {
  value?: number;
  label?: string;
  code: string;
  dateRange?: DateRange;
}
export interface DateRange {
  startDate: string;
  endDate: string;
}
export const DateRangeShortcuts: DateRangeShortcut[] = [
  {
    value: 7,
    label: 'Last 7 Days',
    code: '7d',
  },
  {
    value: 15,
    label: 'Last 15 Days',
    code: '15d',
  },
  {
    value: 30,
    label: 'Last 30 Days',
    code: '30d',
  },
  {
    value: 90,
    label: 'Last 3 Months',
    code: '90d',
  },
  {
    value: 180,
    label: 'Last 6 Months',
    code: '180d',
  },
  {
    value: 0,
    label: 'Custom Range',
    code: 'custom',
  },
];

export const formatDateRange = (selectedDateRange: DateRange) => {
  const { startDate: startDateString, endDate: endDateString } = selectedDateRange;
  const startDate = DateTime.fromJSDate(new Date(startDateString));
  const endDate = DateTime.fromJSDate(new Date(endDateString));

  if (!startDate.isValid || !endDate.isValid) {
    return '';
  }
  const formatDate = (date) => {
    return date.toFormat('LLL d yyyy');
  };

  return `${formatDate(startDate)} to ${formatDate(endDate)}`;
};
