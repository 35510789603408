import React, { useEffect } from 'react';
import { getMarketplace } from '../../utils/ConnectionConfig';
import DynamicTable, { Column } from '../common/DynamicTable';
import { Product } from '../common/types';

interface NotificationProps {
  show: boolean;
  type: 'success' | 'failure';
  content: string;
}

interface ReceivedProductsTableProps {
  order: {
    products: Product[];
  };
  receivedProducts: (Product & {
    receivedEntries?: { receivedQuantity: number; expiry?: string }[];
  })[];
  setReceivedProducts: React.Dispatch<
    React.SetStateAction<
      (Product & { receivedEntries?: { receivedQuantity: number; expiry?: string }[] })[]
    >
  >;
  setShowNotification: React.Dispatch<React.SetStateAction<NotificationProps>>;
}

const ReceivedProductsTable: React.FC<ReceivedProductsTableProps> = ({
  order,
  receivedProducts,
  setReceivedProducts,
  setShowNotification,
}) => {
  useEffect(() => {
    setReceivedProducts((prevReceivedProducts) => {
      return order.products.map((product) => {
        const existingProduct = prevReceivedProducts.find((p) => p.listingId === product.listingId);
        return {
          ...product,
          listingId: product.listingId,
          receivedEntries:
            existingProduct?.receivedEntries && existingProduct.receivedEntries.length > 0
              ? existingProduct.receivedEntries
              : [{ receivedQuantity: 0, expiry: '' }],
        };
      });
    });
  }, [order.products, setReceivedProducts]);

  const handleQuantityChange = (
    listingId: string,
    entryIndex: number,
    value: string,
    expectedQuantity: number,
  ) => {
    setReceivedProducts((prevProducts) =>
      prevProducts.map((rProduct) => {
        if (rProduct.listingId === listingId) {
          const updatedEntries = rProduct.receivedEntries ? [...rProduct.receivedEntries] : [];
          const newQuantity = parseInt(value) || 0;

          const totalQuantity = updatedEntries.reduce(
            (sum, entry, i) => sum + (i === entryIndex ? newQuantity : entry.receivedQuantity || 0),
            0,
          );

          if (totalQuantity > expectedQuantity) {
            setShowNotification({
              show: true,
              type: 'failure',
              content: `Total quantity cannot exceed ${expectedQuantity}`,
            });
            return rProduct;
          }

          updatedEntries[entryIndex].receivedQuantity = newQuantity;
          return { ...rProduct, receivedEntries: updatedEntries };
        }
        return rProduct;
      }),
    );
  };

  const handleExpiryDateChange = (listingId: string, entryIndex: number, value: string) => {
    setReceivedProducts((prevProducts) =>
      prevProducts.map((rProduct) => {
        if (rProduct.listingId === listingId) {
          const updatedEntries = rProduct.receivedEntries ? [...rProduct.receivedEntries] : [];
          updatedEntries[entryIndex].expiry = value;
          return { ...rProduct, receivedEntries: updatedEntries };
        }
        return rProduct;
      }),
    );
  };

  const addNewEntry = (listingId: string, isPerishable: boolean, expectedQuantity: number) => {
    setReceivedProducts((prevProducts) =>
      prevProducts.map((rProduct) => {
        if (rProduct.listingId === listingId) {
          const entryCount = rProduct.receivedEntries ? rProduct.receivedEntries.length : 0;

          const totalReceivedQuantity = (rProduct.receivedEntries || []).reduce(
            (sum, entry) => sum + (entry.receivedQuantity || 0),
            0,
          );

          const allEntriesFilled = (rProduct.receivedEntries || []).every(
            (entry) => (entry.receivedQuantity || 0) > 0,
          );

          if (
            entryCount >= expectedQuantity ||
            totalReceivedQuantity >= expectedQuantity ||
            !allEntriesFilled
          ) {
            setShowNotification({
              show: true,
              type: 'failure',
              content: `Please ensure all existing entries have a quantity before adding a new one.`,
            });
            return rProduct;
          }

          const newEntry = { receivedQuantity: 0, expiry: isPerishable ? '' : undefined };
          const updatedEntries = rProduct.receivedEntries
            ? [...rProduct.receivedEntries, newEntry]
            : [newEntry];
          return { ...rProduct, receivedEntries: updatedEntries };
        }
        return rProduct;
      }),
    );
  };

  const columns: Column<Product>[] = [
    {
      header: ' ',
      accessor: (product) =>
        product.images && product.images.length > 0 ? (
          <img
            className='rounded-md object-scale-down h-20'
            src={product.images[0]}
            height={100}
            width={100}
            alt={product.name}
          />
        ) : null,
      headerClassnamePerColumn:
        'py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6',
    },
    {
      header: 'Product Name & ASIN',
      accessor: (product) => (
        <div className='max-w-48 line-clamp-3 '>
          {product?.name}
          <br />
        </div>
      ),
    },
    {
      header: 'Identifiers',
      accessor: (product) => (
        <>
          {product.marketplaceData?.marketplaceId && product.marketplaceData?.asin && (
            <>
              <a
                href={`${
                  getMarketplace(product.marketplaceData.marketplaceId)?.marketplaceUrl || ''
                }/dp/${product.marketplaceData.asin}`}
                target='_blank'
                rel='noopener noreferrer'
                className='hover:underline'
              >
                <span className='font-bold'>ASIN: </span>
                {product.marketplaceData.asin}
              </a>
              <br />
            </>
          )}

          {product.marketplaceData?.fnSku && (
            <>
              <span className='font-bold'>FNSKU: </span>
              {product.marketplaceData.fnSku}
              <br />
            </>
          )}

          {product.marketplaceData?.sellerSku && (
            <>
              <span className='font-bold'>Seller SKU: </span>
              {product.marketplaceData.sellerSku}
            </>
          )}
        </>
      ),
    },
    {
      header: 'Expected Qty.',
      accessor: (product) => (
        <div className='px-5 py-1 text-green-800 bg-green-200 w-fit rounded-full text-center mx-auto'>
          {product?.expectedQuantity || 0}
        </div>
      ),
    },
    {
      header: 'Expiry Dates & Quantities',
      accessor: (product) => {
        const receivedProduct = receivedProducts.find(
          (rProduct) => rProduct.listingId === product.listingId,
        );

        const totalReceivedQuantity = (receivedProduct?.receivedEntries || []).reduce(
          (sum, entry) => sum + (entry.receivedQuantity || 0),
          0,
        );

        return (
          <>
            {(receivedProduct?.receivedEntries || []).map((entry, index) => (
              <div key={index} className='flex items-center space-x-2 mb-2'>
                <input
                  type='number'
                  placeholder='Qty'
                  value={entry.receivedQuantity || ''}
                  onChange={(e) =>
                    handleQuantityChange(
                      product.listingId,
                      index,
                      e.target.value,
                      product.expectedQuantity,
                    )
                  }
                  className='block w-[70px] rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-hopstack-blue-600 sm:text-sm sm:leading-6 h-[40px]'
                />
                {product.marketplaceData.perishable && (
                  <input
                    type='date'
                    placeholder='Expiry'
                    value={entry.expiry || ''}
                    onChange={(e) =>
                      handleExpiryDateChange(product.listingId, index, e.target.value)
                    }
                    className='block w-[130px] rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-hopstack-blue-600 sm:text-sm sm:leading-6 h-[40px]'
                  />
                )}
              </div>
            ))}
            {product.marketplaceData.perishable &&
              receivedProduct?.receivedEntries?.length < product.expectedQuantity &&
              totalReceivedQuantity < product.expectedQuantity && (
                <button
                  type='button'
                  onClick={() =>
                    addNewEntry(
                      product.listingId,
                      product.marketplaceData.perishable,
                      product.expectedQuantity,
                    )
                  }
                  className='text-blue-500 hover:underline mt-2'
                >
                  Add Entry
                </button>
              )}
          </>
        );
      },
    },
  ];

  return (
    <DynamicTable
      columns={columns}
      data={order?.products || []}
      headerClassname='bg-gray-50 !p-3.5'
      rowsClassname='h-20'
    />
  );
};

export default ReceivedProductsTable;
