import React from 'react';

import DynamicTable, { Column } from '../common/DynamicTable';
import { TrashIcon } from '@heroicons/react/20/solid';

interface Service {
  serviceName: string;
  listingId: string;
  serviceCost: {
    value: number;
  };
}

interface Product {
  listingId: string;
  name: string;
}

interface ServicesTableProps {
  services: Service[];
  setServices: React.Dispatch<React.SetStateAction<Service[]>>;
  order: {
    products: Product[];
  };
}

const ServicesTable: React.FC<ServicesTableProps> = ({ services, setServices, order }) => {
  const handleServiceChange = (index: number, field: keyof Service, value: any) => {
    setServices(
      services.map((service, sIndex) =>
        index === sIndex
          ? field === 'serviceCost'
            ? { ...service, serviceCost: { ...service.serviceCost, value: parseInt(value) } }
            : { ...service, [field]: value }
          : service,
      ),
    );
  };

  const columns: Column<Service>[] = [
    {
      header: 'Service Name',
      accessor: (service, index) => (
        <input
          type='text'
          value={service.serviceName}
          onChange={(e) => handleServiceChange(index, 'serviceName', e.target.value)}
          className='block w-[200px] rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6'
        />
      ),
      headerClassnamePerColumn:
        'py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6',
    },
    {
      header: 'Line Items',
      accessor: (service, index) => (
        <select
          value={service.listingId}
          className='block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6'
          onChange={(e) => handleServiceChange(index, 'listingId', e.target.value)}
        >
          <option>Entire Order</option>
          {order.products.map((product) => (
            <option key={product.listingId} value={product.listingId}>
              {product.name}
            </option>
          ))}
        </select>
      ),
    },
    {
      header: 'Price in USD',
      accessor: (service, index) => (
        <input
          type='number'
          value={service.serviceCost.value}
          onChange={(e) => handleServiceChange(index, 'serviceCost', e.target.value)}
          className='block w-[100px] rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6'
        />
      ),
    },
    {
      header: 'Action',
      accessor: (_, index) => (
        <button
          onClick={() => {
            setServices(services.filter((_, sIndex) => index !== sIndex));
          }}
        >
          <TrashIcon className='w-8 h-8 text-red-400 hover:text-red-600' />
        </button>
      ),
    },
  ];

  return (
    <DynamicTable
      columns={columns}
      data={services}
      headerClassname='bg-gray-50'
      rowsClassname='h-20'
    />
  );
};

export default ServicesTable;
