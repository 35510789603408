import { Disclosure, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { Range, getTrackBackground } from 'react-range';

interface RangeValues {
  min?: number;
  max?: number;
}

enum SOURCE_TYPE {
  SLIDER = 'slider',
  INPUT = 'input',
}
const RangeSliderFilter: React.FC<{
  minValue?: number;
  maxValue?: number;
  maxLimit?: number;
  onChange?: (values: RangeValues) => void;
  label: string;
}> = ({ minValue = 0, maxValue = 1000, onChange, maxLimit = 1000, label }) => {
  const [values, setValues] = useState<RangeValues>({ min: minValue, max: maxValue });
  const [rangeValues, setRangeValues] = useState<number[]>([minValue, maxValue]);
  const [maxValueSource, setMaxValueSource] = useState<SOURCE_TYPE>(SOURCE_TYPE.SLIDER); //define enum

  const handleValuesChange = (newValues: RangeValues, source: SOURCE_TYPE) => {
    if (
      newValues.max !== undefined &&
      (newValues.max !== values.max || source == SOURCE_TYPE.INPUT)
    ) {
      setMaxValueSource(source);
    }
    const updatedValues = { ...values, ...newValues };
    setValues(updatedValues);
    setRangeValues([updatedValues.min, updatedValues.max]);
    if (source == SOURCE_TYPE.SLIDER && updatedValues.max === maxLimit) delete updatedValues.max;
    onChange(updatedValues);
  };
  const getMaxDisplayValue = () => {
    if (rangeValues[1] === maxLimit && maxValueSource == SOURCE_TYPE.SLIDER) {
      return `${maxValue}+`;
    }
    return rangeValues[1];
  };

  return (
    <div>
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className='flex justify-between text-gray-700 w-full px-4 py-2 font-medium text-left focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75'>
              <span>{label}</span>
              <ChevronDownIcon
                className={`${open ? 'transform rotate-180' : ''} w-5 h-5 text-hopstack-blue-700`}
              />
            </Disclosure.Button>
            <Transition
              enter='transition duration-300 ease-out'
              enterFrom='transform scale-95 opacity-0'
              enterTo='transform scale-100 opacity-100'
              leave='transition duration-75 ease-out'
              leaveFrom='transform scale-100 opacity-100'
              leaveTo='transform scale-95 opacity-0'
            >
              <Disclosure.Panel className='px-4 pt-4 pb-2 text-sm text-gray-500 h-20 '>
                <div className='relative w-full'>
                  <Range
                    step={1}
                    min={0}
                    max={maxLimit}
                    values={[rangeValues[0], Math.min(rangeValues[1], maxLimit)]}
                    onFinalChange={(event) => {
                      handleValuesChange({ min: event[0], max: event[1] }, SOURCE_TYPE.SLIDER);
                    }}
                    onChange={(event) => {
                      setRangeValues(event);
                    }}
                    renderTrack={({ props, children }) => (
                      <div
                        {...props}
                        className='w-full  rounded-lg relative'
                        style={{
                          height: '0.5rem',
                          outline: 'none',
                          background: getTrackBackground({
                            values: rangeValues,
                            colors: ['#D9E4ED', '#224E73', '#D9E4ED'],
                            min: 0,
                            max: maxLimit,
                          }),
                        }}
                      >
                        {children}
                      </div>
                    )}
                    renderThumb={({ props }) => {
                      return (
                        <div
                          {...props}
                          className='w-5 h-5 bg-hopstack-blue-700 rounded-full shadow focus:outline-none focus:ring-2 focus:ring-blue-500'
                        />
                      );
                    }}
                  />
                  <div className='flex justify-between mt-2'>
                    <input
                      type='number'
                      className='w-24 px-2 py-1 border border-gray-300 rounded'
                      value={rangeValues[0]}
                      onChange={(e) =>
                        handleValuesChange({ min: Number(e.target.value) }, SOURCE_TYPE.INPUT)
                      }
                    />
                    <span className='my-auto text-gray-600/50 '>
                      <svg
                        width='16'
                        height='2'
                        viewBox='0 0 16 2'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <rect width='16' height='2' rx='1' fill='#BCB7B7' />
                      </svg>
                    </span>
                    <input
                      type='text'
                      className='w-24 px-2 py-1 border border-gray-300 rounded'
                      value={getMaxDisplayValue()}
                      onChange={(e) => {
                        const newValue = e.target.value.replace('+', '');
                        handleValuesChange({ max: Number(newValue) }, SOURCE_TYPE.INPUT);
                      }}
                    />
                  </div>
                </div>
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default RangeSliderFilter;
