import { ArrowRightIcon, ArrowUpIcon, ArrowDownIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { Link } from 'react-router-dom';
import Shimmer from '../common/Shimmer';

type ColorScheme = {
  text: string;
  bg: string;
  icon: string;
};

type ColorMap = {
  zero: ColorScheme;
  positive: ColorScheme;
  negative: ColorScheme;
};
interface LogisticsItem {
  label: string;
  description: JSX.Element;
  value: string;
  change: string;
  period: string;
}

const getChangeColor = (
  change: string | null | undefined,
  isReversePrimaryColor: boolean,
): ColorScheme => {
  const changeValue = change ? parseFloat(change) : null;

  const colorMap: ColorMap = {
    zero: { text: 'text-yellow-700', bg: 'bg-yellow-100', icon: '' },
    positive: isReversePrimaryColor
      ? { text: 'text-red-800', bg: 'bg-red-100', icon: 'text-red-500' }
      : { text: 'text-green-800', bg: 'bg-green-100', icon: 'text-green-500' },
    negative: isReversePrimaryColor
      ? { text: 'text-green-800', bg: 'bg-green-100', icon: 'text-green-500' }
      : { text: 'text-red-800', bg: 'bg-red-100', icon: 'text-red-500' },
  };

  let colorKey: keyof ColorMap = 'zero';
  if (changeValue !== null) {
    if (changeValue > 0) colorKey = 'positive';
    else if (changeValue < 0) colorKey = 'negative';
  }

  return colorKey === 'zero' ? { ...colorMap[colorKey], icon: '' } : colorMap[colorKey];
};
const LogisticOverview = ({
  item,
  isLoading,
  path,
  isReversePrimaryColor = false,
}: {
  item: LogisticsItem;
  isLoading: boolean;
  path: string;
  isReversePrimaryColor?: boolean;
}) => {
  const { text, bg, icon } = getChangeColor(item.change, isReversePrimaryColor);
  const changeValue = item.change ? parseFloat(item.change) : null;

  return isLoading ? (
    <Shimmer
      key={'logistic.' + item.label}
      height='h-[10rem]'
      className='rounded-md shadow-lg'
      backgroundColor='bg-slate-300 opacity-50'
    />
  ) : (
    <div className='bg-white pl-8 pr-3 py-4 rounded-2xl  shadow-lg'>
      {/* More Button */}
      <Link
        to={path}
        className='text-gray-600 text-sm inline-flex justify-end w-full font-semibold items-center gap-1'
      >
        More <ArrowRightIcon className='h-[12px] stroke-[3px] text-gray-400 mt-[2px]' />
      </Link>

      <div className='pb-5 -mt-2'>
        <div className='text-gray-700 font-medium'>{item.label}</div>
        <div className='text-gray-800 text-sm'>{item.description}</div>
      </div>

      {isLoading ? (
        <span>Loading...</span>
      ) : (
        <div className='flex items-center gap-5 mt-4 -mb-5'>
          <div className='text-2xl font-bold'>{item.value}</div>
          <div
            className={`text-sm flex font-semibold items-center ${text} ${bg} rounded-full px-2 py-1`}
          >
            {changeValue !== null && changeValue !== 0 && (
              <span className='mr-1'>
                {changeValue > 0 ? (
                  <ArrowUpIcon height={20} className={icon} />
                ) : (
                  <ArrowDownIcon height={20} className={icon} />
                )}
              </span>
            )}
            {item.change}
          </div>
        </div>
      )}

      <div className='text-gray-700 text-sm inline-flex justify-end -mr-10 w-full'>
        {item.period}
      </div>
    </div>
  );
};
export default LogisticOverview;
