import React, { useState } from 'react';
import { ClockIcon, ArrowsPointingOutIcon } from '@heroicons/react/24/outline';
import groupIcon from '../assets/images/groupIcon.png';
import PackingGroupReview from './PackingGroupReview';
import HeaderWithArrow from '../components/common/HeaderWithArrow';
import SlideOver from '../components/common/SlideOver';

interface PackingConfiguration {
  shippingMode: string;
}

interface Fee {
  description?: string;
  value?: {
    amount: number;
  };
}

interface Discount {
  description?: string;
}

interface GroupItem {
  quantity: number;
}

interface PackingGroup {
  items?: GroupItem[];
}

interface PackingOption {
  packingOptionId: string;
  discounts?: Discount[];
  fees?: Fee[];
  supportedShippingConfigurations?: PackingConfiguration[];
  packingGroupsWithGroupItems?: PackingGroup[];
  expiration?: string;
}

interface SelectPackingOptionsProps {
  packingOptions: PackingOption[];
  selectedPackingOption: string;
  setSelectedPackingOption: (id: string) => void;
}

const SelectPackingOptions: React.FC<SelectPackingOptionsProps> = ({
  packingOptions,
  selectedPackingOption,
  setSelectedPackingOption,
}) => {
  const [openGroupOverview, setOpenGroupOverview] = useState(false);
  const [packingOptionForOverview, setPackingOptionForOverview] = useState<any>({});

  const getApplicableText = (configurations) => {
    if (!configurations || configurations.length === 0) return 'Not Applicable';

    const modes = configurations.map((config) => config.shippingMode);

    if (
      modes.some((mode) => mode.includes('SMALL_PARCEL')) &&
      modes.some((mode) => mode.includes('LTL'))
    ) {
      return 'Applicable for SP and LTL';
    } else if (modes.some((mode) => mode.includes('SMALL_PARCEL'))) {
      return 'Applicable for SP';
    } else if (modes.some((mode) => mode.includes('LTL'))) {
      return 'Applicable for LTL';
    }

    return 'Not Applicable';
  };

  return (
    <div className='font-inter'>
      <div className='mb-4 text-lg font-semibold'>Total No of Options: {packingOptions.length}</div>
      <div className='flex flex-col gap-12'>
        {packingOptions.map((option, index) => (
          <div
            key={index}
            className={`relative flex items-center rounded-lg border p-4 ${
              selectedPackingOption === option.packingOptionId
                ? 'border-hopstack-blue-700 shadow-lg'
                : 'border-gray-300'
            }`}
          >
            <div
              style={{
                borderTopRightRadius: '1rem',
                borderBottomRightRadius: '1rem',
              }}
              className='absolute left-0 top-3 inline-block rounded-r-lg bg-blue-100 px-2 py-1 text-xs font-semibold text-blue-600'
            >
              {option.discounts?.[0]?.description || 'No Discount'}
            </div>
            {/* Radio Button */}
            <div className='mt-2'>
              <input
                type='radio'
                name='packingOption'
                checked={selectedPackingOption === option.packingOptionId}
                onChange={() => setSelectedPackingOption(option.packingOptionId)}
                className='h-5 w-5 cursor-pointer text-hopstack-blue-600 focus:ring-hopstack-blue-500'
              />
            </div>

            {/* Option Details */}
            <div className='ml-4 w-60'>
              {/* Option Name and Fee */}
              <div className='mt-2'>
                <div className='text-lg font-medium'>Option {String.fromCharCode(65 + index)}</div>
                <div className='text-sm text-gray-500'>
                  {option.fees?.[0]?.description
                    ? `$${option.fees[0].value?.amount || 0} ${option.fees[0].description}`
                    : ''}
                </div>
                <div className='text-sm text-gray-500'>
                  {getApplicableText(option.supportedShippingConfigurations)}
                </div>
              </div>
            </div>

            {/* Groups */}
            <div className='ml-8 flex w-full flex-wrap items-center gap-4 overflow-auto'>
              {option.packingGroupsWithGroupItems?.map((group, groupIndex) => (
                <div
                  key={groupIndex}
                  className='flex h-28 w-20 flex-col items-center justify-center gap-1 bg-white p-2'
                >
                  <span className='text-sm font-medium'>Group {groupIndex + 1}</span>
                  <img
                    key={groupIndex}
                    src={groupIcon}
                    alt={'Group Image'}
                    className='mb-2 h-12 w-12 rounded-md object-cover'
                  />
                  <span className='text-xs text-gray-500'>
                    {group.items?.reduce((acc, curr) => acc + curr.quantity, 0) || 0} Items
                  </span>
                </div>
              ))}
            </div>

            {/* Expiry Tag */}
            {option.expiration && (
              <div
                style={{
                  top: '-1.5rem',
                  borderTopRightRadius: '1rem',
                  borderBottomLeftRadius: '2rem',
                  right: '1rem',
                }}
                className='absolute right-4 top-2 flex -translate-y-6 items-center rounded-t-lg bg-yellow-100 px-2 py-1 text-xs text-yellow-700'
              >
                <ClockIcon className='mr-1 inline-block h-4 w-4' />
                {option.expiration || 'No Expiry'}
              </div>
            )}

            {/* Expand Icon */}
            <div className='absolute right-4 top-4 cursor-pointer text-gray-400 hover:text-gray-600'>
              <ArrowsPointingOutIcon
                onClick={() => {
                  setPackingOptionForOverview(option);
                  setOpenGroupOverview(true);
                }}
                className='h-5 w-5'
              />
            </div>
          </div>
        ))}
      </div>
      <SlideOver
        open={openGroupOverview}
        XLarge={true}
        isCrossIconVisible={true}
        setOpen={setOpenGroupOverview}
        title={
          <HeaderWithArrow
            headerTitle={'Packing Option - Groups'}
            description={
              'Review the details of the items included in this packing group, including quantities and SKUs.'
            }
            isLearnMore={false}
            isArrow
            arrowAction={() => setOpenGroupOverview(false)}
            mainClasses='mb-0'
          />
        }
      >
        <PackingGroupReview groups={packingOptionForOverview?.packingGroupsWithGroupItems} />
      </SlideOver>
    </div>
  );
};

export default SelectPackingOptions;
