import React from 'react'

interface ShimmerProps {
  key?: string
  width?: string
  height?: string
  backgroundColor?: string
  shimmerColor?: string
  duration?: string
  className?: string
}

const Shimmer: React.FC<ShimmerProps> = ({
  key,
  width = 'w-full',
  height = 'h-full',
  backgroundColor = 'bg-gray-300',
  shimmerColor = 'rgba(255, 255, 255, 0.4)',
  duration = '1.5s',
  className,
}) => {
  const shimmerStyle = {
    animationDuration: duration,
    background: `linear-gradient(to right, transparent 0%, ${shimmerColor} 50%, transparent 100%)`,
  }

  return (
    <div
      key={key}
      className={`relative overflow-hidden ${width} ${height} ${backgroundColor} ${className}`}
    >
      <div className='absolute inset-0 animate-shimmer' style={shimmerStyle} />
    </div>
  )
}

export default Shimmer
