// UploadDetails.tsx

import React, { useContext } from 'react'
import { ArrowLeftIcon } from '@heroicons/react/20/solid'
import { Upload } from '../common/types'
import BaseContext from '../common/BaseContext'
import useDownload from '../../hooks/useDownload'

interface UploadDetailsProps {
  upload: Upload
  onClose: () => void
}
/**
 * Renders a component that displays the details of an uploaded file, including the file upload ID, file name, file size, file type, upload type, upload timestamp, processing status, retry count, and any processing errors.
 *
 * @param upload - An object containing the details of the uploaded file.
 * @param onClose - A function to be called when the user wants to close the upload details view.
 * @returns A React component that displays the upload details.
 */
const UploadDetails: React.FC<UploadDetailsProps> = ({ upload, onClose }) => {
  const { setLoading, setShowNotification } = useContext(BaseContext)
  const authenticatedDownload = useDownload()

  const groupErrors = (errors: Upload['processingResult']['data']['errors']) => {
    return (errors || []).reduce((groupedErrors: Record<string, number[]>, error) => {
      const errorText = error.error
      const row = error.row
      groupedErrors[errorText] = [...(groupedErrors[errorText] || []), row]
      return groupedErrors
    }, {})
  }

  const handleDownloadResult = async ({ upload }: { upload: Upload }) => {
    setLoading(true)
    try {
      await authenticatedDownload(
        `${upload.fileName.replace('.csv', '')}-result`,
        `/api/v1/inventory/bulk-update/result/${upload.fileUploadId}`,
        {
          method: 'GET',
        },
      )
      setShowNotification({
        show: true,
        type: 'success',
        content: 'Download started!',
      })
    } catch (error) {
      setShowNotification({
        show: true,
        type: 'failure',
        content: error?.message || 'Failed to download the file',
      })
    }
    setLoading(false)
  }

  const statusClassMap = {
    completed: 'bg-green-200',
    failed: 'bg-red-200',
    default: 'bg-yellow-100',
  }
  return (
    <div
      className=' mx-auto p-6 bg-white rounded-xl '
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <div className='flex flex-col justify-between items-start gap-3 '>
        <button onClick={onClose}>
          <ArrowLeftIcon width='20' height='20' color='black' />
        </button>
        <h2 className='text-3xl  font-semibold text-gray-800'>Upload Details</h2>
        <p className='text-gray-400 mb-10'>Here are the uploads Details:</p>{' '}
      </div>
      <div className='grid grid-cols-2 gap-x-8 gap-y-4  shadow p-3 rounded-md bg-gray-50'>
        <div>
          <p className='font-medium text-gray-500'>File Upload ID:</p>
          <p className='text-gray-900 '>{upload.fileUploadId}</p>
        </div>
        <div>
          <p className='font-medium text-gray-500'>File Name:</p>
          <p className='text-gray-900 font-semibold'>{upload.fileName}</p>
        </div>
        <div>
          <p className='font-medium text-gray-500'>File Size:</p>
          <p className='text-gray-900 '>{upload.fileSize} bytes</p>
        </div>
        <div>
          <p className='font-medium text-gray-500'>File Type:</p>
          <p className='text-gray-900 '>{upload.fileType}</p>
        </div>
        <div>
          <p className='font-medium text-gray-500'>Upload Type:</p>
          <p className='text-gray-900 '>{upload.uploadType}</p>
        </div>
        <div>
          <p className='font-medium text-gray-500'>Upload Timestamp:</p>
          <p className='text-gray-900 '>{upload.uploadTimestamp}</p>
        </div>
        <div>
          <p className='font-medium text-gray-500'>Processing Status:</p>
          <span
            className={`absolute font-semibold text-gray-900 capitalize px-2 py-1 rounded-md ${
              statusClassMap[upload.processingStatus] || statusClassMap['default']
            }`}
          >
            {upload.processingStatus}
          </span>
        </div>
        <div>
          <p className='font-medium text-gray-500'>Retry Count:</p>
          <p className='text-gray-900'>{upload.retryCount}</p>
        </div>

        <div>
          <p className='font-medium text-gray-500'>Reason:</p>
          <p className='text-gray-900'>{upload.metadata.reason}</p>
        </div>
      </div>
      {upload.processingResult?.data.errors && (
        <div className='mt-6 '>
          <h3 className='text-lg font-semibold text-gray-800 mb-2'>Processing Result</h3>
          <div className='p-4 bg-red-50 rounded-lg'>
            <div className=''>
              <p className='font-semibold text-gray-600 mb-1'>Errors:</p>
              {Object.entries(groupErrors(upload.processingResult.data.errors)).map(
                ([errorText, rows], index) => (
                  <div key={index} className='mt-2'>
                    <p className='text-red-800'>{errorText}</p>
                    <p className='text-red-800'>Number of rows affected: {rows.length}</p>
                  </div>
                ),
              )}
            </div>
          </div>
          <div className='m-4 flex justify-center items-center'>
            <button
              className='bg-hopstack-blue-600 text-white p-1.5 font-semibold rounded'
              onClick={() => {
                handleDownloadResult({ upload })
              }}
            >
              Download result
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default UploadDetails
