import React, { useState, useEffect } from 'react';
import { ChevronDownIcon, ChevronRightIcon, TrashIcon } from '@heroicons/react/24/outline';
import Button from '../components/common/Button';

interface Location {
  shipmentId: string;
  cityName: string;
}

interface PalletDimensions {
  height: number;
  length: number;
  width: number;
  unitOfMeasurement: 'IN' | 'CM';
}

interface PalletWeight {
  unit: 'LB' | 'KG';
  value: number;
}

interface Pallet {
  dimensions: PalletDimensions;
  quantity: number;
  weight: PalletWeight;
}

interface PalletInfo {
  [shipmentId: string]: Pallet[];
}

interface PayloadDimensions {
  length: number;
  width: number;
  height: number;
  unitOfMeasurement: string;
}

interface PayloadWeight {
  unit: string;
  value: number;
}

interface PayloadPallet {
  quantity: number;
  weight: PayloadWeight;
  dimensions: PayloadDimensions;
}

interface PayloadShipment {
  shipmentId: string;
  pallets: PayloadPallet[];
}

interface FbaPalletManagementProps {
  locations?: Location[];
  onSave: (payload: PayloadShipment[]) => void;
  setShowNotification: (notification: { show: boolean; type: string; content: string }) => void;
}

const FbaPalletManagement: React.FC<FbaPalletManagementProps> = ({
  locations = [],
  onSave,
  setShowNotification,
}) => {
  const [expandedLocation, setExpandedLocation] = useState<string | null>(null);
  const [hasValidPallets, setHasValidPallets] = useState<boolean>(false);

  const [palletInfo, setPalletInfo] = useState<PalletInfo>(() => {
    const initialData: PalletInfo = {};
    locations.forEach((location) => {
      initialData[location.shipmentId] = [];
    });
    return initialData;
  });

  useEffect(() => {
    // Check if at least one location has at least one pallet
    const isValid = Object.values(palletInfo).some((pallets) => pallets.length > 0);
    setHasValidPallets(isValid);
  }, [palletInfo]);

  const handleReset = () => {
    const initialData = {};
    locations.forEach((location) => {
      initialData[location.shipmentId] = [];
    });
    setPalletInfo(initialData);
  };

  const handleAddPallet = (shipmentId: string): void => {
    setPalletInfo((prev) => ({
      ...prev,
      [shipmentId]: [
        ...prev[shipmentId],
        {
          dimensions: {
            length: 48,
            width: 40,
            height: 70,
            unitOfMeasurement: 'IN',
          },
          quantity: 1,
          weight: {
            unit: 'LB',
            value: 250,
          },
        },
      ],
    }));
    setShowNotification({
      show: true,
      type: 'success',
      content: `New pallet added to ${locations.find((loc) => loc.shipmentId === shipmentId)
        ?.cityName}`,
    });
  };

  const handleRemovePallet = (shipmentId: string, index: number): void => {
    setPalletInfo((prev) => ({
      ...prev,
      [shipmentId]: prev[shipmentId].filter((_, i) => i !== index),
    }));
  };

  const handleDimensionChange = (
    shipmentId: string,
    palletIndex: number,
    dimension: keyof PalletDimensions,
    value: string,
  ): void => {
    setPalletInfo((prev) => ({
      ...prev,
      [shipmentId]: prev[shipmentId].map((pallet, i) =>
        i === palletIndex
          ? {
              ...pallet,
              dimensions: {
                ...pallet.dimensions,
                [dimension]: parseFloat(value) || 0,
              },
            }
          : pallet,
      ),
    }));
  };

  const handleWeightChange = (shipmentId: string, palletIndex: number, value: string): void => {
    setPalletInfo((prev) => ({
      ...prev,
      [shipmentId]: prev[shipmentId].map((pallet, i) =>
        i === palletIndex
          ? {
              ...pallet,
              weight: {
                ...pallet.weight,
                value: parseFloat(value) || 0,
              },
            }
          : pallet,
      ),
    }));
  };

  const handleSave = (): void => {
    const payload: PayloadShipment[] = Object.entries(palletInfo).map(([shipmentId, pallets]) => ({
      shipmentId,
      pallets,
    }));
    onSave(payload);
  };

  return (
    <div className='rounded-lg border border-gray-200 bg-white'>
      <div className='border-b border-gray-200 p-4'>
        <h2 className='text-lg font-medium text-gray-900'>LTL Pallet Management</h2>
      </div>

      <div className='divide-y divide-gray-200'>
        {locations.map((location) => (
          <div key={location.shipmentId}>
            <div
              className='flex cursor-pointer items-center justify-between bg-gray-50 p-4'
              onClick={() =>
                setExpandedLocation(
                  expandedLocation === location.shipmentId ? null : location.shipmentId,
                )
              }
            >
              <div className='flex items-center gap-2'>
                {expandedLocation === location.shipmentId ? (
                  <ChevronDownIcon className='h-4 w-4 text-gray-600' />
                ) : (
                  <ChevronRightIcon className='h-4 w-4 text-gray-600' />
                )}
                <span className='text-sm font-medium text-gray-900'>{location.cityName}</span>
              </div>
              <button
                className='text-sm font-medium text-primaryAccent hover:text-primaryAccent/80'
                onClick={(e) => {
                  e.stopPropagation();
                  handleAddPallet(location.shipmentId);
                }}
              >
                + Add Pallet
              </button>
            </div>

            {expandedLocation === location.shipmentId && (
              <div className='px-8 py-2'>
                {palletInfo[location.shipmentId]?.map((pallet, index) => (
                  <div key={index} className='flex items-center gap-6 py-3'>
                    <div className='flex w-96 items-center gap-2'>
                      <span className='text-base text-gray-600'>
                        {`Pallet ${index + 1}: (l" x w" x h") & (Weight lbs)`}
                      </span>
                    </div>

                    <div className='flex items-center gap-2'>
                      <input
                        type='number'
                        className='w-16 rounded border border-gray-300 px-2 py-1 text-sm'
                        value={pallet.dimensions.length}
                        onChange={(e) =>
                          handleDimensionChange(
                            location.shipmentId,
                            index,
                            'length',
                            e.target.value,
                          )
                        }
                        placeholder='L'
                      />
                      <span className='text-gray-400'>×</span>
                      <input
                        type='number'
                        className='w-16 rounded border border-gray-300 px-2 py-1 text-sm'
                        value={pallet.dimensions.width}
                        onChange={(e) =>
                          handleDimensionChange(location.shipmentId, index, 'width', e.target.value)
                        }
                        placeholder='B'
                      />
                      <span className='text-gray-400'>×</span>
                      <input
                        type='number'
                        className='w-16 rounded border border-gray-300 px-2 py-1 text-sm'
                        value={pallet.dimensions.height}
                        onChange={(e) =>
                          handleDimensionChange(
                            location.shipmentId,
                            index,
                            'height',
                            e.target.value,
                          )
                        }
                        placeholder='H'
                      />
                    </div>

                    <input
                      type='number'
                      className='w-32 rounded border border-gray-300 px-2 py-1 text-sm'
                      value={pallet.weight.value}
                      onChange={(e) =>
                        handleWeightChange(location.shipmentId, index, e.target.value)
                      }
                      placeholder='Enter Weight'
                    />

                    <button
                      onClick={() => handleRemovePallet(location.shipmentId, index)}
                      className='text-gray-400 hover:text-red-600'
                    >
                      <TrashIcon className='h-4 w-4' />
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>

      <div className='flex justify-end gap-4 border-t border-gray-200 p-4'>
        <Button variant='secondary' className='text-sm font-medium' onClick={handleReset}>
          Reset
        </Button>
        <Button
          variant='primary'
          className='text-sm font-medium'
          onClick={handleSave}
          disabled={!hasValidPallets}
        >
          Save and Regenerate Rates
        </Button>
      </div>
    </div>
  );
};

export default FbaPalletManagement;
