import React from 'react';
import { twMerge } from 'tailwind-merge';

interface CustomBadgeProps {
  color?: string;
  label: string;
  bgColor?: string;
  textColor?: string;
  textSize?: string;
  className?: string;
}

const CustomBadge: React.FC<CustomBadgeProps> = ({
  color,
  label,
  bgColor,
  textColor,
  textSize,
  className,
}) => {
  const BG_COLOR = color ? `bg-${color}-100` : '';
  const TEXT_COLOR = color ? `text-${color}-800` : '';

  return (
    <span
      style={{ backgroundColor: bgColor, color: textColor }}
      className={twMerge(
        `${className} rounded-full px-3 py-2 ${BG_COLOR} ${TEXT_COLOR} ${textSize || ''}`,
      )}
    >
      {label}
    </span>
  );
};

export default CustomBadge;
