import React, { useContext, useEffect, useState } from 'react';
import useFetch from '../hooks/useFetch';
import { Bars3Icon } from '@heroicons/react/24/outline';
import BaseContext from '../components/common/BaseContext';
import { capitalize } from '../components/common/utils';
import { useParams } from 'react-router-dom';

const Notifications = () => {
  const authenticatedFetch = useFetch();
  const [notifications, setNotifications] = useState([]);
  const { setLoading, setOrganization, organization, setShowNotification } =
    useContext(BaseContext);
  const params = useParams();
  useEffect(() => {
    if (params.orgId && organization === null) {
      setLoading(true);
      authenticatedFetch('/api/v1/organizations?include=subscription').then((res) => {
        if (res.success && res.data) {
          setOrganization(res.data.find((org) => org.organizationId === params.orgId));
        } else {
          location.replace('/');
        }
        setLoading(false);
      });
    }
  }, []);

  const fetchNotifications = () => {
    setLoading(true);
    authenticatedFetch(`/api/v1/user/notifications`).then((res) => {
      setLoading(false);
      if (res.success) {
        setNotifications(res.data);
      }
    });
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const markAsRead = (notificationId: string) => {
    authenticatedFetch(`/api/v1/user/notifications/read/${notificationId}`, {
      method: 'POST',
    }).then((res) => {
      if (res.success) {
        fetchNotifications();
        setShowNotification({
          show: true,
          type: 'success',
          content: 'Notification marked as read successfully!',
        });
      } else {
        setShowNotification({
          show: true,
          type: 'failure',
          content: res.message || res.errors[0]?.message,
        });
      }
    });
  };
  return (
    <div className='p-8'>
      <h1 className='text-[#030229] text-[24px] font-bold'>Notifications</h1>
      <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg mt-8'>
        <table className='min-w-full divide-y divide-gray-300'>
          <thead className='bg-gray-50'>
            <tr>
              <th
                scope='col'
                className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
              >
                <Bars3Icon className='text-black w-4 h-4' />
              </th>
              <th
                scope='col'
                className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
              >
                Timestamp
              </th>
              <th
                scope='col'
                className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody className='divide-y divide-gray-200 bg-white'>
            {notifications.map((notification) => (
              <tr key={notification?.email}>
                <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                  {capitalize(notification?.notificationEvent)
                    ?.split('.')
                    .join(' ')}
                </td>
                <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                  {capitalize(new Date(notification?.createdAt).toLocaleString('en-US'))}
                </td>

                <td className='relative whitespace-nowrap py-4 text-sm font-medium sm:pr-6'>
                  {notification?.status === 'unread' && (
                    <button
                      onClick={() => markAsRead(notification?.notificationId)}
                      //   onClick={() => setSelectedWarehouse(warehouse)}
                      className='text-hopstack-blue-600 hover:text-hopstack-blue-900'
                    >
                      Mark as read
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Notifications;
