import React from 'react';
import { Disclosure, DisclosureButton, DisclosurePanel, Transition } from '@headlessui/react';
import { XMarkIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
interface Options {
  label: string;
  value: string;
}
const CheckboxFilter: React.FC<{
  options: Options[];
  selectedOptions: Options[];
  onChange?: (selectedOptions: Options[]) => void;
  label: string;
}> = ({ options, selectedOptions, onChange, label }) => {
  const selectedOptionValues = selectedOptions.map((op) => op.value);

  const handleOptionChange = (option: Options) => {
    const newSelectedOptions = selectedOptionValues.includes(option.value)
      ? selectedOptions.filter((selectedOption) => selectedOption.value !== option.value)
      : [...selectedOptions, option];
    onChange(newSelectedOptions);
  };

  return (
    <div>
      <div className='flex items-center mb-2 flex-wrap'>
        {/* <span className='mr-2'>Selected Options:</span> */}
        {selectedOptions.map((option) => (
          <span
            key={option.value}
            className='m-2 p-1 px-3 bg-white border text-sm border-hopstack-blue-700 rounded-full inline-flex gap-2 capitalize'
          >
            {option.label}{' '}
            <XMarkIcon
              height={15}
              className={'my-auto cursor-pointer'}
              onClick={() => handleOptionChange(option)}
            />
          </span>
        ))}
      </div>
      <Disclosure>
        {({ open }) => (
          <>
            <DisclosureButton className='flex justify-between text-gray-700  w-full px-4 py-2  font-medium text-left   focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75'>
              <span>{label}</span>
              <ChevronDownIcon
                className={`${open ? 'transform rotate-180' : ''} w-5 h-5 text-hopstack-blue-700`}
              />
            </DisclosureButton>
            <Transition
              enter='transition duration-300 ease-out'
              enterFrom='transform scale-95 opacity-0'
              enterTo='transform scale-100 opacity-100'
              leave='transition duration-75 ease-out'
              leaveFrom='transform scale-100 opacity-100'
              leaveTo='transform scale-95 opacity-0'
            >
              <DisclosurePanel className='px-4 pt-4 pb-2 text-sm text-gray-500 max-h-40 overflow-scroll scrollbar-hide '>
                {options.map((option) => (
                  <label key={option.value} className='flex items-center mb-2'>
                    <input
                      type='checkbox'
                      className='form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out'
                      checked={selectedOptionValues.includes(option.value)}
                      onChange={() => handleOptionChange(option)}
                    />
                    <span className='ml-2 capitalize'>{option.label}</span>
                  </label>
                ))}
              </DisclosurePanel>
            </Transition>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default CheckboxFilter;
