import React, { useState, useMemo } from 'react';
import CustomTable from '../components/common/CustomTable';
import { FALLBACK_IMAGE_URL } from '../utils/FallbackImageUrls';
import { Tooltip } from 'react-tooltip';

interface ViewPackedItemsProps {
  items: any[];
  isGroup: boolean;
}

const ViewPackedItems: React.FC<ViewPackedItemsProps> = ({ items, isGroup }) => {
  const formatPrepType = (prepType: string): string => {
    return prepType
      .replace('ITEM_', '')
      .split('_')
      .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
      .join(' ');
  };

  const [searchTerm, setSearchTerm] = useState('');

  // Check if any items have prep instructions
  const hasPrepInstructions = useMemo(() => {
    return items.some((item) => item.prepInstructions?.length > 0);
  }, [items]);

  // Filter items based on SKU search
  const filteredItems = useMemo(() => {
    return items.filter((item) => item.msku.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [items, searchTerm]);

  const columns = [
    {
      key: 'productInfo',
      title: 'Product Name',
      render: (_, row) => (
        <div className='flex items-center'>
          <img
            src={row.image || FALLBACK_IMAGE_URL}
            alt={row.msku || row.sku}
            className='mr-4 h-16 w-16 rounded-md object-cover'
          />
          <div>
            <div
              className='w-56 truncate text-base font-medium text-gray-800'
              data-tooltip-id='product-name-tooltip'
              data-tooltip-content={row.productName}
            >
              {row.productName}
            </div>
            <Tooltip id='product-name-tooltip' />
            <div className='text-sm text-gray-500'>FNSKU: {row.fnSku || row.fnsku}</div>
            <div className='text-sm text-gray-500'>SKU: {row.msku || row.sku}</div>
            <div className='text-sm text-gray-500'>ASIN: {row.asin}</div>
          </div>
        </div>
      ),
    },
    {
      key: 'availableQuantity',
      title: isGroup ? 'Available Quantity' : 'Quantity',
      render: (_, row) => (
        <span className='inline-flex items-center justify-center rounded-full bg-blue-50 px-3 py-1 text-sm font-medium text-blue-700'>
          {row.quantity || 0}
        </span>
      ),
    },
    isGroup
      ? {
          key: 'assignedQuantity',
          title: 'Assigned Quantity',
          render: (_, row) => (
            <span className='inline-flex items-center justify-center rounded-full bg-green-50 px-3 py-1 text-sm font-medium text-green-700'>
              {row.assignedQuantity || 0}
            </span>
          ),
        }
      : null,
    // Only include prep instructions column if at least one item has them
    ...(hasPrepInstructions
      ? [
          {
            key: 'prepInstructions',
            title: 'Prep Instructions',
            render: (_, row) => (
              <div className='flex flex-wrap gap-2'>
                {row.prepInstructions?.map((instruction, index) => (
                  <div
                    key={index}
                    style={{
                      backgroundColor: '#FFF8E7',
                      color: '#B8860B',
                      borderRadius: '9999px',
                      padding: '4px 12px',
                      fontWeight: 500,
                      fontSize: '14px',
                    }}
                  >
                    {formatPrepType(instruction.prepType)}
                  </div>
                ))}
              </div>
            ),
          },
        ]
      : []),
  ].filter(Boolean);

  return (
    <div className='w-full font-inter'>
      <div className='mb-4 flex items-center gap-5'>
        <span className='text-sm text-gray-400'>Total No. of Products: {filteredItems.length}</span>
        <span className='text-sm text-gray-400'>
          Total Units: {filteredItems.reduce((acc, item) => acc + (Number(item.quantity) || 0), 0)}
        </span>
      </div>

      <CustomTable
        columns={columns}
        data={filteredItems}
        isSearchable
        searchTerm={searchTerm}
        searchPlaceholder='Search with SKU...'
        onChangeSearchTerm={setSearchTerm}
      />
    </div>
  );
};

export default ViewPackedItems;
