import React from 'react';

interface InventoryCardProps {
  index: number;
  item: {
    colorClass: string;
    tag: string;
    label: string;
    keyName: string;
  };
  inventoryUnits: { [key: string]: number };
}

const InventoryCard: React.FC<InventoryCardProps> = ({ index, item, inventoryUnits }) => {
  return (
    <div key={index} className='bg-white py-4 pl-4 rounded-lg shadow-lg'>
      <div className='flex item-end justify-end right-0 font-medium mb-2'>
        <div className={`text-right px-2 py-1 rounded-l-xl ${item.colorClass} max-w-fit`}>
          {item.tag}
        </div>
      </div>
      <div className='text-gray-400 font-semibold'>{item.label}</div>
      <div className='text-3xl font-bold -mb-3 inline-flex gap-2 items-end'>
        {Object.keys(inventoryUnits).length === 0 ? (
          <div className='text-3xl font-bold text-black -mb-3'>Loading...</div>
        ) : (
          <div className='text-3xl inline-flex items-end gap-2 font-bold -mb-3'>
            {inventoryUnits[item.keyName] ? (
              <span className='-mb-[2px]'>{inventoryUnits[item.keyName]}</span>
            ) : (
              <span className='font-semibold'>0</span>
            )}
            <span className='text-sm text-gray-400 font-medium'>Units</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default InventoryCard;
