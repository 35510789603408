import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
  ExclamationCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { Tooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';

interface Option {
  [key: string]: any;
  label: string | React.ReactNode;
  frequency?: number;
}

interface AutoCompleteSingleSelectProps {
  options: Option[];
  value?: any;
  onChange: any;
  labelKey: string;
  valueKey: string;
  placeholder?: string;
  onKeyDown?: (text: string) => void;
  secondaryLabelKey?: string;
  disabled?: boolean;
  searchable?: boolean;
  restrictOptionsWidth?: boolean;
  labelText?: string;
  required?: boolean;
  error?: string;
  parentClasses?: string;
  labelClasses?: string;
  shouldBeHighLighted?: boolean;
  shouldDeselect?: boolean;
  infoText?: string;
  isSort?: boolean;
  defaultValue?: string;
  inputClasses?: string;
}

export default function AutoCompleteSingleSelect({
  options,
  value,
  onChange,
  labelKey,
  valueKey,
  placeholder = '',
  onKeyDown,
  secondaryLabelKey = '',
  disabled = false,
  searchable = true,
  restrictOptionsWidth = true,
  labelText = '',
  required = false,
  error = '',
  parentClasses = '',
  labelClasses = 'text-gray-700',
  shouldBeHighLighted = false,
  shouldDeselect = true,
  infoText = '',
  isSort = true,
  defaultValue = '',
  inputClasses = '',
}: AutoCompleteSingleSelectProps) {
  const [text, setText] = useState(defaultValue);
  const [showOptions, setShowOptions] = useState(false);
  const [cursor, setCursor] = useState(-1);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const select = (option: string) => {
    if (onChange) {
      onChange({ label: option, value: option });
    }
    setShowOptions(false);
  };

  const handleChange = (text: string) => {
    setText(text);
    if (onKeyDown) {
      onKeyDown(text);
    }
    setCursor(-1);
    if (!showOptions) {
      setShowOptions(true);
    }
  };

  const clearSelection = () => {
    if (onChange) {
      onChange(null);
    }
    setText('');
  };

  let filteredOptions = options
    ? options.filter((option) => option[labelKey]?.toLowerCase().includes(text?.toLowerCase()))
    : [];
  if (isSort) {
    filteredOptions = filteredOptions.sort(
      (a, b) =>
        (b.frequency || 0) - (a.frequency || 0) ||
        String(a[labelKey]).localeCompare(String(b[labelKey])),
    );
  }

  const moveCursorDown = () => {
    if (cursor < filteredOptions.length - 1) {
      setCursor((c) => c + 1);
    }
  };

  const moveCursorUp = () => {
    if (cursor > 0) {
      setCursor((c) => c - 1);
    }
  };

  const handleNav = (e: React.KeyboardEvent) => {
    switch (e.key) {
      case 'ArrowUp':
        moveCursorUp();
        break;
      case 'ArrowDown':
        moveCursorDown();
        break;
      case 'Enter':
        if (cursor >= 0 && cursor < filteredOptions.length) {
          select(filteredOptions[cursor][valueKey]);
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (showOptions) {
      inputRef.current?.focus();
    }
  }, [showOptions]);

  useEffect(() => {
    function handleOutsideClick(event: MouseEvent) {
      if (!dropdownRef.current?.contains(event.target as Node)) {
        setShowOptions(false);
        setCursor(-1);
        setText('');
      }
    }

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const selectedOption = _.find(options, (e) => e[valueKey] === value);

  return (
    <div className={`relative ${parentClasses}`} ref={dropdownRef}>
      {labelText && (
        <div className='mb-2 flex items-center'>
          <div className={labelClasses}>
            {labelText}
            {required && <span className='text-red-500'>*</span>}
            {infoText && (
              <>
                <ExclamationCircleIcon
                  data-tooltip-id='info-tooltip'
                  data-tooltip-content={infoText}
                  className='ml-2 inline-block h-4 w-4 text-gray-400 cursor-help'
                />
                <Tooltip id='info-tooltip' />
              </>
            )}
          </div>
        </div>
      )}
      <div className='relative'>
        <input
          type='text'
          placeholder={!selectedOption ? placeholder : ''}
          readOnly={true}
          disabled={disabled}
          onClick={() => !disabled && setShowOptions((prev) => !prev)}
          onChange={(e) => handleChange(e.target.value)}
          className={twMerge(
            `block w-full rounded border bg-white p-2 text-gray-700 transition-colors duration-300 focus:border-primaryAccent focus:ring-primaryAccent ${
              shouldBeHighLighted && error ? 'border-red-500' : 'border-gray-400'
            } ${disabled ? 'pointer-events-none bg-gray-200 opacity-50' : ''} ${inputClasses}`,
          )}
        />
        {selectedOption && (
          <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2'>
            {selectedOption['label']}
          </div>
        )}
        <div className='absolute inset-y-0 right-0 flex items-center pr-3'>
          {selectedOption && shouldDeselect && !disabled ? (
            <XMarkIcon
              className='h-5 w-5 cursor-pointer text-gray-500'
              onClick={disabled ? () => {} : clearSelection}
            />
          ) : (
            <ChevronDownIcon className='h-5 w-5 text-gray-500' />
          )}
        </div>
      </div>

      {showOptions && (
        <div className='z-8 relative mt-2 w-full transition duration-300 ease-in-out'>
          <ul
            className={`absolute ${
              restrictOptionsWidth ? 'w-full' : 'w-max'
            } z-50 max-h-96 overflow-auto rounded-lg rounded-t-none border border-gray-300 bg-white shadow-lg`}
          >
            {searchable && (
              <li className='sticky top-0 z-10 bg-white'>
                <input
                  type='search'
                  ref={inputRef}
                  value={text}
                  className='block w-full border-none p-2 pl-8 text-gray-700 transition-colors duration-300 hover:bg-white focus:border-gray-300 focus:ring-gray-300'
                  placeholder='Search'
                  onChange={(e) => handleChange(e.target.value)}
                  onKeyDown={handleNav}
                />
                <span className='absolute left-3 top-3'>
                  <MagnifyingGlassIcon className='h-4 w-4' />
                </span>
              </li>
            )}
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option, i, arr) => {
                let className = `text-gray-900 text-md cursor-pointer select-none relative py-4 pl-4 pr-4 border-b hover:bg-gray-100 `;

                if (i === arr.length - 1) className += 'rounded-b-lg';
                if (cursor === i) {
                  className += ' bg-gray-200';
                }

                return (
                  <li className={className} key={i} onClick={() => select(option[valueKey])}>
                    {option['label']}
                    {secondaryLabelKey && option[secondaryLabelKey]}
                  </li>
                );
              })
            ) : (
              <li className='text-md relative cursor-default select-none border-b py-4 pl-4 pr-4 text-gray-900'>
                No results
              </li>
            )}
          </ul>
        </div>
      )}
      {error && (
        <div className='mt-1 text-sm text-red-600'>{error || 'This field is required.'}</div>
      )}
    </div>
  );
}
