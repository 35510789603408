import React from 'react';

interface PositiveNumberInputProps {
  value: number | null;
  onChange: (value: number | null) => void;
  max?: number;
  className?: string;
}

const PositiveNumberInput: React.FC<PositiveNumberInputProps> = ({
  value,
  onChange,
  max,
  className,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value ? parseInt(e.target.value) : null;

    if (newValue === null || (newValue >= 0 && (max === undefined || newValue <= max))) {
      onChange(newValue);
    }
  };

  return (
    <input
      type='number'
      min='0'
      max={max}
      value={value ?? ''}
      onChange={handleChange}
      className={
        `block w-[100px] rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-hopstack-blue-600 sm:text-sm sm:leading-6 ` +
        className
      }
    />
  );
};

export default PositiveNumberInput;
