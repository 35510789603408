import React, { useState } from 'react';
import { PlusIcon, MinusIcon, TrashIcon } from '@heroicons/react/24/solid';
import CustomPopconfirm from '../components/common/CustomPopconfirm';
import Button from '../components/common/Button';
import CustomTable from '../components/common/CustomTable';

interface PrepInstruction {
  prepType: string;
  prepOwner?: string;
}

interface BoxItem {
  asin: string;
  fnsku: string;
  msku: string;
  quantity: number;
  image?: string;
  prepInstructions?: PrepInstruction[];
}

interface BoxItemWithMax extends BoxItem {
  maxQuantity: number;
}

interface EditBoxItemsProps {
  box: any;
  group: any;
  onSave: any;
  onCancel: () => void;
  setShowNotification: (notification: { show: boolean; type: string; content: string }) => void;
}

const EditBoxItems: React.FC<EditBoxItemsProps> = ({
  box,
  group,
  onSave,
  onCancel,
  setShowNotification,
}) => {
  const [data, setData] = useState<BoxItemWithMax[]>(
    box.items.map((item) => {
      const groupItem = group.items.find((gi) => gi.msku === item.msku);
      const maxQuantity = (groupItem?.quantity || 0) + item.quantity;

      return {
        ...item,
        maxQuantity,
      };
    }),
  );
  const [searchTerm, setSearchTerm] = useState('');

  const handleQuantityChange = (
    msku: string,
    operation: 'increment' | 'decrement',
    directValue = null,
  ): void => {
    setData((prevData) =>
      prevData.map((item) => {
        if (item.msku === msku) {
          let newQuantity;

          if (directValue !== null) {
            // Handle direct input value
            newQuantity = parseInt(directValue, 10);

            // Handle invalid inputs
            if (isNaN(newQuantity) || newQuantity < 1) {
              return item;
            }
          } else {
            // Handle increment/decrement
            newQuantity =
              operation === 'increment'
                ? item.quantity + 1
                : item.quantity > 1
                  ? item.quantity - 1
                  : 1;
          }

          // Check against maxQuantity
          if (newQuantity > item.maxQuantity) {
            setShowNotification({
              show: true,
              type: 'warning',
              content: 'Quantity cannot exceed available quantity',
            });
            return item;
          }

          return {
            ...item,
            quantity: newQuantity,
          };
        }
        return item;
      }),
    );
  };

  const handleDeleteItem = (msku: string): void => {
    setData((prevData) => prevData.filter((item) => item.msku !== msku));
  };

  const filteredData = data.filter((item) =>
    item.msku.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const columns = [
    {
      key: 'productInfo',
      title: 'Product Name',
      render: (_, row) => (
        <div className='flex items-center'>
          <img src={row.image} alt={row.msku} className='mr-4 h-16 w-16 rounded-md object-cover' />
          <div>
            <div className='text-base font-medium text-gray-800'>{row.fnsku}</div>
            <div className='text-sm text-gray-500'>SKU: {row.msku}</div>
            <div className='text-sm text-gray-500'>ASIN: {row.asin}</div>
          </div>
        </div>
      ),
    },
    {
      key: 'prepInstructions',
      title: 'Prep Instructions',
      render: (_, row) => (
        <div className='flex flex-wrap gap-2'>
          {row.prepInstructions?.map((instruction, idx) => (
            <div
              key={idx}
              style={{
                backgroundColor: '#FFF8E7',
                color: '#B8860B',
                borderRadius: '9999px',
                padding: '4px 12px',
                fontWeight: 500,
                fontSize: '14px',
              }}
            >
              {instruction.prepType
                .replace('ITEM_', '')
                .split('_')
                .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
                .join(' ')}
            </div>
          ))}
        </div>
      ),
    },
    {
      key: 'maxQuantity',
      title: 'Available Quantity',
      render: (_, row) => <span>{row.maxQuantity}</span>,
    },
    {
      key: 'quantity',
      title: 'Quantity',
      render: (_, row) => (
        <div className='flex items-center gap-2'>
          <button
            onClick={() => handleQuantityChange(row.msku, 'decrement')}
            className='rounded border border-gray-300 p-3'
            disabled={row.quantity <= 1}
          >
            <MinusIcon
              className={`h-4 w-4 ${row.quantity <= 1 ? 'text-gray-300' : 'text-primaryAccent'}`}
            />
          </button>

          <input
            type='number'
            value={row.quantity}
            min='1'
            max={row.maxQuantity}
            className='w-20 rounded-md border border-gray-300 px-2 py-1 text-center'
            onChange={(e) => {
              // Remove any negative signs from the input
              const value = e.target.value.replace(/-/g, '');
              handleQuantityChange(row.msku, null, value);
            }}
            onBlur={(e) => {
              // If empty or invalid on blur, reset to 1
              if (!e.target.value || parseInt(e.target.value) < 1) {
                handleQuantityChange(row.msku, null, '1');
              }
            }}
          />

          <button
            onClick={() => handleQuantityChange(row.msku, 'increment')}
            className='rounded border border-gray-300 p-3'
            disabled={row.quantity >= row.maxQuantity}
          >
            <PlusIcon
              className={`h-4 w-4 ${
                row.quantity >= row.maxQuantity ? 'text-gray-300' : 'text-primaryAccent'
              }`}
            />
          </button>
        </div>
      ),
    },
    {
      key: 'actions',
      title: 'Actions',
      render: (_, row) => (
        <CustomPopconfirm
          title='Are you sure to remove this item?'
          onConfirm={() => handleDeleteItem(row.msku)}
          okText='Yes'
          cancelText='No'
          placement='left'
          okButtonProps={{
            className: 'bg-primaryAccent text-white',
          }}
          cancelButtonProps={{
            className: 'bg-white border rounded border-primaryAccent text-primaryAccent',
          }}
        >
          <TrashIcon className='h-5 w-5 cursor-pointer text-red-500 hover:text-red-600' />
        </CustomPopconfirm>
      ),
    },
  ];

  const handleConfirm = (): void => {
    if (data.length === 0) {
      const returnQuantities: Record<string, number> = {};
      box.items.forEach((originalItem) => {
        returnQuantities[originalItem.msku] = -originalItem.quantity;
      });

      onSave({
        updatedItems: [],
        quantityChanges: returnQuantities,
      });
      return;
    }

    const quantityChanges: Record<string, number> = {};

    data.forEach((currentItem) => {
      const originalItem = box.items.find((bi) => bi.msku === currentItem.msku);
      if (originalItem) {
        const diff = currentItem.quantity - originalItem.quantity;
        if (diff !== 0) {
          quantityChanges[currentItem.msku] = diff;
        }
      }
    });

    box.items.forEach((originalItem) => {
      const stillExists = data.find((item) => item.msku === originalItem.msku);
      if (!stillExists) {
        quantityChanges[originalItem.msku] = -originalItem.quantity;
      }
    });

    onSave({
      updatedItems: data,
      quantityChanges,
    });
  };

  return (
    <div className='p-6 font-inter'>
      <div className='mb-4 flex items-center gap-5'>
        <span className='text-sm text-gray-400'>Total No. of Products: {filteredData?.length}</span>
        <span className='text-sm text-gray-400'>
          Total Quantity: {filteredData?.reduce((total, item) => total + item.quantity, 0)}
        </span>
      </div>

      <div className='flex-grow' style={{ height: 'calc(100vh - 18rem)' }}>
        <CustomTable
          columns={columns}
          data={filteredData}
          isSearchable
          searchTerm={searchTerm}
          searchPlaceholder='Search with SKU...'
          onChangeSearchTerm={handleSearch}
        />
      </div>

      <div className='sticky bottom-0 z-30 flex w-full items-center justify-between bg-white p-4'>
        <div className='flex w-full justify-end gap-6'>
          <Button variant='secondary' onClick={onCancel}>
            Cancel
          </Button>
          <Button variant='primary' onClick={handleConfirm}>
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EditBoxItems;
