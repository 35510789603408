import React from 'react';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { twMerge } from 'tailwind-merge';

interface HeaderWithArrowProps {
  headerTitle: string | React.ReactNode;
  description: any;
  isLearnMore?: boolean;
  learnMoreAction?: () => void;
  href?: string;
  arrowAction?: () => void;
  isArrow?: boolean;
  descriptionClasses?: string;
  titleClasses?: string;
  mainClasses?: string;
}

function HeaderWithArrow({
  headerTitle,
  description,
  isLearnMore,
  learnMoreAction,
  href,
  arrowAction,
  isArrow = true,
  descriptionClasses = '',
  titleClasses = '',
  mainClasses = 'mb-4',
}: HeaderWithArrowProps) {
  return (
    <div className={mainClasses}>
      <div className='flex items-center justify-start gap-3'>
        {isArrow && (
          <ArrowLeftIcon onClick={arrowAction ?? (() => {})} className='h-6 w-6 cursor-pointer' />
        )}
        <h2 className={twMerge('text-2xl font-semibold ' + titleClasses)}>{headerTitle}</h2>
      </div>

      <p className={twMerge('text-base font-light text-gray-400 ' + descriptionClasses)}>
        {description}{' '}
        {isLearnMore && (
          <a
            href={href ?? '#'}
            onClick={learnMoreAction ?? (() => {})}
            className='text-base font-medium text-hopstack-blue-700 underline'
          >
            Learn More
          </a>
        )}
      </p>
    </div>
  );
}

export default HeaderWithArrow;
