import { RequestParams } from '../../hooks/useRequest';

export const createAuthenticatedFetch = (getToken: () => Promise<string>) => {
  const baseUrl = process.env.REACT_APP_BACKEND_API_ENDPOINT;

  return async (url: string, options: RequestInit = {}) => {
    try {
      const token = await getToken();

      const headers = {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...options.headers,
      };

      const response = await fetch(baseUrl + url, {
        ...options,
        headers,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return response.json();
    } catch (error) {
      console.error('Authentication fetch error:', error);
      throw error;
    }
  };
};

export const makeRequest = async (
  baseUrl: string,
  params: RequestParams,
  options: object = {},
  getToken: () => Promise<string>,
) => {
  const authenticatedFetch = createAuthenticatedFetch(getToken);

  const newQueryParams = new URLSearchParams(params.queryParams);
  let constructedUrl = `${baseUrl}?${newQueryParams.toString()}`;

  for (const key in params.urlParams) {
    constructedUrl = constructedUrl.replace(`{${key}}`, params.urlParams[key]);
  }

  const response = await authenticatedFetch(constructedUrl, options);
  return { data: response, status: 200 };
};
