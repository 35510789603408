import { useState } from 'react'
import { Address } from '../components/common/types'
import { countries } from '../components/common/utils'

export enum SuggestedAction {
  ACCEPT = 'ACCEPT',
  CONFIRM = 'CONFIRM',
  FIX = 'FIX',
  ADD_SUBPREMISES = 'ADD_SUBPREMISES',
}

interface ValidationSuggestion {
  suggestedAction: SuggestedAction
}

interface AddressComponentName {
  text: string
  languageCode: string
}
enum AddressComponentConfirmationLevel {
  CONFIRMATION_LEVEL_UNSPECIFIED = 'CONFIRMATION_LEVEL_UNSPECIFIED',
  CONFIRMED = 'CONFIRMED',
  UNCONFIRMED_BUT_PLAUSIBLE = 'UNCONFIRMED_BUT_PLAUSIBLE',
  UNCONFIRMED_AND_SUSPICIOUS = 'UNCONFIRMED_AND_SUSPICIOUS',
}
interface AddressComponent {
  confirmationLevel: AddressComponentConfirmationLevel
  componentName?: AddressComponentName
  componentType: string
  inferred: boolean
  spellCorrected: boolean
  replaced: boolean
  unexpected: boolean
}

interface GMapsAddressResponse {
  missingComponentTypes: any
  postalAddress: any
  addressComponents: AddressComponent[]
  unresolvedTokens: any
}

enum Granularity {
  GRANULARITY_UNSPECIFIED = 'GRANULARITY_UNSPECIFIED',
  SUB_PREMISE = 'SUB_PREMISE',
  PREMISE = 'PREMISE',
  PREMISE_PROXIMITY = 'PREMISE_PROXIMITY',
  BLOCK = 'BLOCK',
  ROUTE = 'ROUTE',
  OTHER = 'OTHER',
}

interface ValidationVerdict {
  validationGranularity?: Granularity
  hasReplacedComponents?: boolean
  addressComplete?: boolean
  hasInferredComponents?: boolean
  hasUnconfirmedComponents?: boolean
}

interface ValidationResult {
  address: GMapsAddressResponse
  verdict: ValidationVerdict
}

interface ValidateResponse {
  suggestedAction: SuggestedAction
  suggestedAddress?: Address
}

const SUBPREMISE = 'subpremise'

function isUSA(address: GMapsAddressResponse): boolean {
  return address.postalAddress?.regionCode === 'US'
}

function isMissingNonSubpremiseComponent(result: ValidationResult): boolean {
  const missingComponents = result.address.missingComponentTypes || []
  return (
    missingComponents.length > 1 ||
    (missingComponents.length === 1 && missingComponents[0] !== SUBPREMISE)
  )
}

function hasValidationGranularityRouteOrBetter(result: ValidationResult): boolean {
  return (
    result.verdict?.validationGranularity &&
    [
      Granularity.ROUTE,
      Granularity.BLOCK,
      Granularity.PREMISE,
      Granularity.PREMISE_PROXIMITY,
      Granularity.SUB_PREMISE,
    ].includes(result.verdict.validationGranularity)
  )
}

function hasValidationGranularityOther(result: ValidationResult): boolean {
  return (
    !result.verdict?.validationGranularity ||
    result.verdict.validationGranularity === Granularity.OTHER
  )
}

function hasSuspiciousComponent(result: ValidationResult): boolean {
  return result.address.addressComponents.some(
    (c) => c.confirmationLevel === 'UNCONFIRMED_AND_SUSPICIOUS',
  )
}

function hasSpellingCorrected(result: ValidationResult): boolean {
  return result.address.addressComponents.some((c) => c.spellCorrected === true)
}

function hasUnresolvedToken(result: ValidationResult): boolean {
  return (result.address.unresolvedTokens || []).length > 0
}

function hasInferredComponents(result: ValidationResult): boolean {
  return !!result.verdict?.hasInferredComponents
}

function hasReplacement(result: ValidationResult): boolean {
  return !!result.verdict?.hasReplacedComponents
}

function isAddressComplete(result: ValidationResult): boolean {
  return !!result.verdict?.addressComplete
}

function isMissingExactlyUSASubpremise(result: ValidationResult): boolean {
  return (
    isUSA(result.address) &&
    result.address.missingComponentTypes?.length === 1 &&
    result.address.missingComponentTypes[0] === SUBPREMISE
  )
}

export function suggestValidationAction(result: ValidationResult): ValidationSuggestion {
  if (
    !isAddressComplete(result) ||
    isMissingNonSubpremiseComponent(result) ||
    hasValidationGranularityOther(result) ||
    hasSuspiciousComponent(result) ||
    hasUnresolvedToken(result)
  ) {
    return { suggestedAction: SuggestedAction.FIX }
  } else if (
    hasValidationGranularityRouteOrBetter(result) &&
    isAddressComplete(result) &&
    (hasInferredComponents(result) || hasReplacement(result) || hasSpellingCorrected(result))
  ) {
    return { suggestedAction: SuggestedAction.CONFIRM }
  } else if (isMissingExactlyUSASubpremise(result)) {
    return { suggestedAction: SuggestedAction.ADD_SUBPREMISES }
  } else {
    return { suggestedAction: SuggestedAction.ACCEPT }
  }
}

function getAddressComponentByType(
  addressComponents: AddressComponent[],
  componentType: string,
): AddressComponent | null {
  return addressComponents.find(
    (addressComponent) => addressComponent.componentType === componentType,
  )
}

export default function useAddressValidator() {
  const [previousResponseId, setPreviousResponseId] = useState(null)

  return async function validateAddress(address: Address): Promise<ValidateResponse> {
    const response = await fetch(
      `https://addressvalidation.googleapis.com/v1:validateAddress?key=${process.env.REACT_APP_GCP_MAPS_KEY}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          address: {
            revision: 0,
            regionCode: address?.country?.code,
            postalCode: address.zipCode,
            administrativeArea: address.state,
            locality: address.city,
            addressLines: [address.line1, address.line2, address.line3],
          },
          previousResponseId,
          // enableUspsCass: true,
        }),
      },
    )
    const jsonResponse = await response.json()

    if (jsonResponse?.error?.status === 'INVALID_ARGUMENT') {
      return {
        suggestedAction: SuggestedAction.ACCEPT,
      }
    }
    if (!previousResponseId) {
      setPreviousResponseId(jsonResponse.responseId)
    }

    const validatorResult: ValidationResult = jsonResponse.result
    const validationResponse = suggestValidationAction(validatorResult)
    const streetNumber = getAddressComponentByType(
      validatorResult.address.addressComponents,
      'street_number',
    )
    const route = getAddressComponentByType(validatorResult.address.addressComponents, 'route')
    const subPremise = getAddressComponentByType(
      validatorResult.address.addressComponents,
      'subpremise',
    )
    const city = getAddressComponentByType(validatorResult.address.addressComponents, 'locality')
    const state = getAddressComponentByType(
      validatorResult.address.addressComponents,
      'administrative_area_level_1',
    )
    const zipCode = getAddressComponentByType(
      validatorResult.address.addressComponents,
      'postal_code',
    )
    const zipCodeSuffix = getAddressComponentByType(
      validatorResult.address.addressComponents,
      'postal_code_suffix',
    )
    const country = getAddressComponentByType(validatorResult.address.addressComponents, 'country')
    const mappedCountry = countries.find(
      (c) =>
        c.alpha3Code === country?.componentName?.text || c.code === country?.componentName?.text,
    )
    return {
      suggestedAction: validationResponse.suggestedAction,
      suggestedAddress: {
        ...address,
        line1: `${streetNumber?.componentName?.text} ${route?.componentName?.text} ${subPremise?.componentName?.text}`,
        line2: null,
        city: city?.componentName?.text,
        state: state?.componentName?.text,
        country: {
          name: mappedCountry?.name,
          code: mappedCountry?.code,
        },
        zipCode: `${zipCode?.componentName?.text}${
          zipCodeSuffix && `-${zipCodeSuffix?.componentName?.text}`
        }`,
      },
    }
  }
}
