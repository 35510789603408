import React from 'react'
import Shimmer from '../common/Shimmer'

export interface Metric {
  value: number
  label: string
  units: string
}

interface MetricDisplayProps {
  metrics: Metric[]
  isLoading?: boolean
}

const MetricDisplay: React.FC<MetricDisplayProps> = ({ metrics, isLoading = true }) => {
  return (
    <div className='flex space-x-8 text-center justify-evenly bg-white p-4 rounded-md shadow-lg'>
      {metrics.map((metric, index) => (
        <>
          {index > 0 && (
            <div className='inline-flex items-center text-gray-400' key={`divider-${index}`}>
              -------------------
            </div>
          )}
          <div key={index + 'metric-display'}>
            <p className='text-gray-500'>{metric.label}</p>
            <p className='text-2xl text-blue-950 font-bold mt-2'>
              {!isLoading ? (
                metric.value
              ) : (
                <Shimmer
                  className='rounded-md shadow-lg h-[2rem] w-[3rem] mb-2 mx-auto '
                  backgroundColor='bg-hopstack-blue-100 opacity-50'
                />
              )}
            </p>
            <p className='text-gray-500 text-sm'>{metric.units}</p>
          </div>
        </>
      ))}
    </div>
  )
}

export default MetricDisplay
