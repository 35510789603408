import React, { useCallback, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { CrossSVG } from '../../pages/Inventory';
import { Warehouse, Upload } from '../common/types';
import { classNames } from '../common/utils';
import UploadDetails from './UploadDetails';
import { debounce } from 'lodash';
import { Spinner } from '../common/Spinner';

interface PastUploadsProps {
  warehouse: Warehouse;
  authenticatedFetch: (input: RequestInfo, init?: RequestInit) => Promise<any>;
  setOpenPastUploadsSlideOver: (open: boolean) => void;
}
/**
 * Renders a component that displays a list of past inventory uploads for a specific warehouse.
 * The component uses InfiniteScroll to load more data as the user scrolls down the page.
 * Each upload is displayed in a table row, with information such as the file name, upload date, reason, and status.
 * Clicking on a row will open a details modal for the selected upload.
 */
const PastUploads: React.FC<PastUploadsProps> = ({
  warehouse,
  authenticatedFetch,
  setOpenPastUploadsSlideOver,
}) => {
  const [uploads, setUploads] = useState<Upload[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const currentPageRef = useRef(0);
  const fetchedPagesRef = useRef(new Set<number>());
  const [selectedUpload, setSelectedUpload] = useState<Upload | null>(null);

  const handleRowClick = (upload: Upload) => {
    setSelectedUpload(upload);
  };

  const fetchData = useCallback(
    debounce(async (page: number) => {
      if (fetchedPagesRef.current.has(page)) {
        return;
      }

      try {
        setLoading(true);
        const response = await authenticatedFetch(
          `/api/v1/inventory/bulk-update?filter[warehouseId]=${warehouse.warehouseId}&page=${page}&limit=20`,
        );

        setUploads((prevUploads) => {
          const newUploads = response.data.filter(
            (upload: Upload) =>
              !prevUploads.some((prevUpload) => prevUpload.fileUploadId === upload.fileUploadId),
          );
          return [...prevUploads, ...newUploads];
        });

        setHasMore(response.data.length > 0);
        fetchedPagesRef.current.add(page);
        currentPageRef.current = page + 1;
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    }, 300),
    [],
  );

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    };
    return new Date(dateString).toLocaleString('en-US', options);
  };

  const statusClassMap = {
    completed: 'bg-green-100 text-green-800',
    'in-process': 'bg-hopstack-blue-100 text-blue-800',
    'partial-complete': 'bg-yellow-100 text-yellow-800',
    failed: 'bg-red-100 text-red-800',
  };
  return (
    <>
      <div className='bg-white p-4'>
        <span
          className='flex flex-col -mt-10 w-full items-end font-semibold'
          onClick={() => setOpenPastUploadsSlideOver(false)}
        >
          {/* cross svg */}
          <CrossSVG width='20' height='20' color='black' />
        </span>
        <div className='text-3xl  font-semibold'>Past Uploads</div>
        <p className='text-gray-400 mb-10'>
          Here are the past inventory uploads for this warehouse:
        </p>

        <InfiniteScroll
          className='relative'
          pageStart={0}
          loadMore={() => fetchData(currentPageRef.current)}
          hasMore={hasMore}
          loader={
            loading &&
            uploads.length === 0 && (
              <div className='flex justify-center py-10' key={'page' + currentPageRef.current}>
                <Spinner />
              </div>
            )
          }
        >
          <table className='w-full text-center text-sm rounded-lg shadow-md'>
            <thead className='bg-gray-50 rounded-lg p-2 h-[45px]'>
              <tr className='text-gray-900 uppercase'>
                <th className='px-4 py-2'>File Name</th>
                <th className='px-4 py-2'>Date of Upload</th>
                <th className='px-4 py-2'>Reason</th>
                <th className='px-4 py-2'>Status</th>
              </tr>
            </thead>
            <tbody>
              {uploads.map((upload: Upload) => (
                <tr
                  key={upload.fileUploadId}
                  className={classNames(
                    'border-b border-gray-200 h-14 hover:bg-gray-100 transition duration-200 ease-in-out text-center',
                    'cursor-pointer',
                  )}
                  onClick={() => handleRowClick(upload)}
                >
                  <td className='relative w-[100px] group'>
                    <span className='capitalize block text-ellipsis whitespace-nowrap overflow-hidden max-w-[220px] mx-10'>
                      {upload.fileName}
                    </span>
                    <span className='absolute left-0 z-10 hidden group-hover:block p-2 bg-gray-800 text-white text-xs rounded  whitespace-nowrap'>
                      {upload.fileName}
                    </span>
                  </td>
                  <td className=''>{formatDate(upload.uploadTimestamp)}</td>
                  <td className='relative group inline-flex justify-center my-4'>
                    <span className='capitalize block text-ellipsis whitespace-nowrap overflow-hidden max-w-[220px] mx-10   text-gray-500'>
                      {upload?.metadata?.reason || 'NA'}
                    </span>

                    {(upload?.metadata?.reason || 'NA').length > 10 && (
                      <span className='absolute left-0 z-10 hidden capitalize group-hover:block bg-gray-800 text-white max-w-[300px] text-xs rounded whitespace-nowrap overflow-scroll'>
                        {upload?.metadata?.reason || 'NA'}
                      </span>
                    )}
                  </td>
                  <td className=''>
                    <span
                      className={`rounded-full px-3 py-1 text-xs font-medium capitalize ${
                        statusClassMap[upload.processingStatus] || 'bg-gray-100 text-gray-800'
                      }`}
                    >
                      {upload.processingStatus}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </InfiniteScroll>
        {selectedUpload && (
          <div
            className='fixed  inset-0 flex items-center justify-center bg-gray-700 -my-14  bg-opacity-50'
            onClick={() => {
              setSelectedUpload(null);
            }}
          >
            <UploadDetails upload={selectedUpload} onClose={() => setSelectedUpload(null)} />
          </div>
        )}
      </div>
    </>
  );
};

export default PastUploads;
