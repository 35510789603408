import React from 'react';
import { Organization } from './types';
import { roles } from './utils';

interface Context {
  organization: Organization;
  setOrganization: any;
  showNotification: {
    show: boolean;
    type: string;
    content: string;
  };
  setShowNotification: any;
  loading: boolean;
  setLoading: any;
  orgRole: roles;
  setOrgRole: any;
  loadingOverlayProps: {
    isVisible: boolean;
    loaderTitle: string;
    description: string;
    loaderClasses: string;
    spinnerSize: 'small' | 'default' | 'large';
    colorClasses?: {
      titleColor?: string;
      descriptionColor?: string;
      loaderColor?: string;
    };
    htmlContent?: React.ReactNode;
  };
  setLoadingOverlay: (
    loaderTitle?: string,
    description?: string,
    htmlContent?: React.ReactNode,
    loaderClasses?: string,
    spinnerSize?: 'small' | 'default' | 'large',
    colorClasses?: {
      titleColor?: string;
      descriptionColor?: string;
      loaderColor?: string;
    },
  ) => void;
  removeLoadingOverlay: () => void;
}
const BaseContext = React.createContext<Context>({
  organization: null,
  setOrganization: null,
  showNotification: { show: false, type: '', content: '' },
  setShowNotification: null,
  loading: false,
  setLoading: null,
  orgRole: null,
  setOrgRole: null,
  loadingOverlayProps: {
    isVisible: false,
    loaderTitle: 'Please wait',
    description: 'This might take a few minutes. Please hold on.',
    loaderClasses: '',
    spinnerSize: 'default',
  },
  setLoadingOverlay: () => {},
  removeLoadingOverlay: () => {},
});

export default BaseContext;
