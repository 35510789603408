import React, { useEffect, useRef, useState } from 'react';
import {
  ExclamationCircleIcon,
  MagnifyingGlassIcon,
  XCircleIcon,
  FunnelIcon,
} from '@heroicons/react/24/outline';
import { Tooltip } from 'react-tooltip';
import noResultsImage from '../../assets/images/nofilter.png';
import { Spinner } from './Spinner';

interface Column {
  key: string;
  title: string;
  isSort?: boolean;
  infoText?: string;
  stickPosition?: 'left' | 'right';
  render?: (value: any, row: any) => React.ReactNode;
}

interface CustomTableProps {
  columns: Column[];
  data?: any[];
  onSort?: (key: string, direction: string) => void;
  isSearchable?: boolean;
  isFilters?: boolean;
  isPagination?: boolean;
  isInfiniteScroll?: boolean;
  loadingData?: boolean;
  currentPage?: number;
  setCurrentPage?: (page: number) => void;
  pageSize?: number;
  searchTerm?: string;
  setPageSize?: (size: number) => void;
  onChangeSearchTerm?: (term: string) => void;
  noResultsText?: string;
  isLoadMore?: boolean;
  totalDataCount?: number;
  pageSizeOptions?: string[];
  totalDataCountLable?: string;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  renderRow?: (row: any, index: number) => React.ReactNode;
  searchPlaceholder?: string;
}

const CustomTable: React.FC<CustomTableProps> = ({
  columns,
  data,
  onSort = () => {},
  isSearchable = false,
  isFilters = false,
  isInfiniteScroll = false,
  loadingData = false,
  currentPage,
  setCurrentPage = () => {},
  pageSize,
  searchTerm = '',
  searchPlaceholder,
  onChangeSearchTerm = () => {},
  noResultsText = 'No results found',
  totalDataCount,
  totalDataCountLable = '',
  onKeyDown = () => {},
  renderRow = null,
}) => {
  const [sortConfig, setSortConfig] = useState<{ key: string; direction: string } | null>(null);
  const infiniteLoader = useRef<HTMLDivElement | null>(null);

  const handleSort = (key: string) => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
    onSort(key, direction);
  };

  const handleClearSearch = () => {
    onChangeSearchTerm('');
  };

  const attachObserver = () => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          totalDataCount &&
          currentPage &&
          pageSize &&
          totalDataCount > currentPage * pageSize
        ) {
          setCurrentPage(currentPage + 1);
        }
      },
      {
        threshold: 0.5,
      },
    );

    if (infiniteLoader.current) {
      observer.observe(infiniteLoader.current);
    }

    return observer;
  };

  useEffect(() => {
    const observer = attachObserver();

    return () => {
      if (infiniteLoader.current) {
        observer.unobserve(infiniteLoader.current);
      }
    };
  }, [data, totalDataCount]);

  return (
    <div className='h-full w-full font-inter'>
      {isSearchable || isFilters ? (
        <div style={{ padding: '1px' }} className='mb-4 flex h-8 w-full items-center'>
          {isSearchable && (
            <div
              style={{ width: isFilters ? '95%' : '100%', height: '100%' }}
              className='relative mr-2 flex items-center border-none'
            >
              <input
                type='text'
                placeholder={searchPlaceholder || 'Search...'}
                value={searchTerm}
                onKeyDown={onKeyDown}
                onChange={(e) => onChangeSearchTerm(e.target.value)}
                className='h-full w-full rounded border p-2 text-sm text-gray-500 placeholder-transparent focus:border-primaryAccent focus:ring-primaryAccent'
              />
              {searchTerm ? (
                <XCircleIcon
                  className='absolute right-2 h-5 w-5 cursor-pointer text-gray-400'
                  onClick={handleClearSearch}
                />
              ) : (
                <MagnifyingGlassIcon className='absolute right-2 h-5 w-5 text-gray-400' />
              )}
            </div>
          )}
          {isFilters && (
            <div className='relative mr-2 flex items-center'>
              <FunnelIcon className='h-5 w-5' />
            </div>
          )}
        </div>
      ) : null}
      {totalDataCountLable && totalDataCountLable !== '' && (
        <div style={{ padding: '1px' }} className='mb-2 flex w-full items-center'>
          <span className='text-base font-light text-gray-400'>
            {totalDataCountLable}
            {totalDataCount}
          </span>
        </div>
      )}
      <div className='relative h-full w-full overflow-auto'>
        {loadingData && !data ? (
          <table className='min-w-full divide-y divide-gray-200'>
            <thead className='sticky top-0 z-5 bg-gray-100 shadow-md'>
              <tr>
                {columns.map((column) => (
                  <th
                    key={column.key}
                    className={`px-6 py-6 text-left text-base font-normal tracking-wider text-[#717679] ${
                      column.stickPosition === 'left'
                        ? 'sticky left-0 z-5 bg-gray-100 shadow-md'
                        : ''
                    } ${
                      column.stickPosition === 'right'
                        ? 'sticky right-0 z-5 bg-gray-100 shadow-md'
                        : ''
                    }`}
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    <div className='flex items-center'>
                      <span>{column.title}</span>
                      {column.infoText && (
                        <>
                          <ExclamationCircleIcon
                            data-tooltip-id={`tooltip-${column.key}`}
                            className='ml-1 h-4 w-4 text-gray-500 cursor-help'
                          />
                          <Tooltip
                            id={`tooltip-${column.key}`}
                            content={column.infoText}
                            place='top'
                          />
                        </>
                      )}
                      {column.isSort && (
                        <svg
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          className='ml-1 h-6 w-6 cursor-pointer'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M8 9L12 5L16 9M16 15L12 19L8 15'
                            stroke='#717679'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className='divide-y divide-gray-200 bg-white'>
              {Array.from({ length: pageSize || 0 }).map((_, index) => (
                <tr key={index} className='hover:bg-gray-50'>
                  {columns.map((column) => (
                    <td
                      key={column.key}
                      className={`whitespace-nowrap px-6 py-6 text-base text-gray-900 ${
                        column.stickPosition === 'left'
                          ? 'sticky left-0 z-auto bg-white shadow-md'
                          : ''
                      } ${
                        column.stickPosition === 'right'
                          ? 'sticky right-0 z-auto bg-white shadow-md'
                          : ''
                      }`}
                    >
                      <div className='flex space-x-4'>
                        <div className='h-6 w-full animate-pulse rounded-md bg-gray-200'></div>
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        ) : data && data?.length === 0 ? (
          <div className='flex w-full flex-col items-center justify-center py-20'>
            <img src={noResultsImage} alt='No results' className='mb-4' style={{ width: '40%' }} />
            <p className='text-xl font-semibold text-gray-600'>{noResultsText}</p>
          </div>
        ) : (
          <table className='min-w-full divide-y divide-gray-200'>
            <thead className='sticky top-0 z-5 bg-gray-50 shadow-sm'>
              <tr>
                {columns.map((column) => (
                  <th
                    key={column.key}
                    className={`px-6 py-6 text-left text-base font-normal tracking-wider text-[#717679] ${
                      column.stickPosition === 'left'
                        ? 'sticky left-0 z-5 bg-gray-100 shadow-md'
                        : ''
                    } ${
                      column.stickPosition === 'right'
                        ? 'sticky right-0 z-5 bg-gray-100 shadow-md'
                        : ''
                    }`}
                    onClick={() => column.isSort && handleSort(column.key)}
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    <div className='flex items-center'>
                      <span>{column.title}</span>
                      {column.infoText && (
                        <>
                          <ExclamationCircleIcon
                            data-tooltip-id={`tooltip-${column.key}`}
                            className='ml-1 h-4 w-4 text-gray-500 cursor-help'
                          />
                          <Tooltip
                            id={`tooltip-${column.key}`}
                            content={column.infoText}
                            place='top'
                          />
                        </>
                      )}
                      {column.isSort && (
                        <svg
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          className='ml-1 h-6 w-6 cursor-pointer'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M8 9L12 5L16 9M16 15L12 19L8 15'
                            stroke='#717679'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className='w-full divide-y divide-gray-200 bg-white'>
              {data?.map((row, rowIndex) =>
                renderRow ? (
                  renderRow(row, rowIndex)
                ) : (
                  <tr key={rowIndex} className='relative hover:bg-gray-50'>
                    {columns.map((column) => (
                      <td
                        key={column.key}
                        className={`whitespace-nowrap px-6 py-6 text-base text-gray-600 ${
                          column.stickPosition === 'left'
                            ? 'sticky left-0 z-auto bg-white shadow-md'
                            : ''
                        } ${
                          column.stickPosition === 'right'
                            ? 'sticky right-0 z-auto bg-white shadow-md'
                            : ''
                        }`}
                      >
                        {column.render ? column.render(row[column.key], row) : row[column.key]}
                      </td>
                    ))}
                  </tr>
                ),
              )}
              {isInfiniteScroll &&
                data &&
                totalDataCount &&
                currentPage &&
                pageSize &&
                totalDataCount > currentPage * pageSize && (
                  <div
                    ref={infiniteLoader}
                    className='sticky left-1/2 flex h-14 w-full -translate-x-1/2 transform items-center justify-center border-none'
                  >
                    <Spinner />
                  </div>
                )}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default CustomTable;
