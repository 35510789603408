import React from 'react';

interface Tab {
  label: string;
  value: string;
  disabled?: boolean;
}

interface CustomTabSelectionProps {
  tabs: Tab[];
  activeTab: string;
  onTabChange: (value: string) => void;
  tabContainerClasses?: string;
  tabClasses?: string;
  activeTabClasses?: string;
  inactiveTabClasses?: string;
  disabledTabClasses?: string;
  barClasses?: string;
}

const CustomTabSelection: React.FC<CustomTabSelectionProps> = ({
  tabs,
  activeTab,
  onTabChange,
  tabContainerClasses = 'flex justify-start text-sm space-x-6',
  tabClasses = 'flex flex-col items-center',
  activeTabClasses = 'text-hopstack-blue-700 font-medium border-blue-900',
  inactiveTabClasses = 'text-gray-400 font-medium border-transparent hover:text-gray-600',
  disabledTabClasses = 'text-gray-400 font-medium border-transparent cursor-not-allowed opacity-50',
  barClasses = 'w-full h-2',
}) => {
  return (
    <div className={`w-full font-inter ${tabContainerClasses}`}>
      {tabs.map((tab) => (
        <div
          key={tab.value}
          className={`${tabClasses} ${tab.disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
          onClick={() => {
            if (!tab.disabled) {
              onTabChange(tab.value);
            }
          }}
        >
          <div
            className={`${barClasses} ${
              activeTab === tab.value
                ? 'bg-hopstack-blue-700'
                : tab.disabled
                  ? 'bg-gray-100'
                  : 'bg-gray-200'
            } rounded-md`}
          />
          <span
            className={`mt-2 ${
              tab.disabled
                ? disabledTabClasses
                : activeTab === tab.value
                  ? activeTabClasses
                  : inactiveTabClasses
            }`}
          >
            {tab.label}
          </span>
        </div>
      ))}
    </div>
  );
};

export default CustomTabSelection;
