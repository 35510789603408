import React from 'react';
import { twMerge } from 'tailwind-merge';

interface ButtonProps {
  onClick?: any;
  children: any;
  disabled?: boolean;
  className?: string;
  variant?: 'primary' | 'secondary';
}

const Button = ({ onClick, children, className, disabled, variant = 'primary' }: ButtonProps) => {
  const filled = 'bg-hopstack-blue-700 text-white hover:opacity-80';
  const outlined =
    'border border-hopstack-blue-700 text-hopstack-blue-700 hover:bg-hopstack-blue-50';

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={twMerge(
        `flex gap-2 h-fit rounded-lg py-2 px-4`,
        variant === 'primary' ? filled : outlined,
        className,
      )}
    >
      {children}
    </button>
  );
};

export default Button;
