import { createSlice } from '@reduxjs/toolkit';
import {
  startAmazonInboundPlanCreation,
  getPackingOptions,
  setPackingInformation,
  getErrorsInInboundPlan,
  cancelAmazonInboundPlan,
  confirmPackingOptions,
  getPlacementsOptions,
  getTransportationOptions,
  getDeliveryWindowOptions,
  listShipments,
  confirmTransportationOptions,
  confirmPlacementOption,
  confirmDeliveryWindowOptions,
  confirmPackingCompleted,
  regenerateTransportationOptions,
} from './fbaPackingActions';
import { addPromiseHandlers } from '../ReduxUtils/fetchApi';

enum StateKeys {
  AMAZON_INBOUND_PLAN_CREATION_RESULT = 'amazonInboundPlanCreationResult',
  PACKING_OPTIONS_RESULT = 'packingOptionsResult',
  CONFIRM_PACKING_RESULT = 'confirmPackingResult',
  SET_PACKING_INFO_RESULT = 'setPackingInfoResult',
  ERRORS_RESULT = 'errorsResult',
  CANCEL_INBOUND_PLAN_RESULT = 'cancelInboundPlanResult',
  PLACEMENTS_RESULT = 'placementsResult',
  TRANSPORTATION_RESULT = 'transportationResult',
  SHIPMENTS_RESULT = 'shipmentsResult',
  CONFIRM_TRANSPORTATION_OPTIONS_RESULT = 'confirmTransportationsOptionsResult',
  CONFIRM_PLACEMENT_OPTION_RESULT = 'confirmPlacementOptionResult',
  GET_DELIVERY_WINDOW_OPTIONS_RESULT = 'getDeliverWindowOptionsResult',
  CONFIRM_DELIVERY_WINDOW_OPTIONS_RESULT = 'confirmDeliveryWindowOptionsResult',
  CONFIRM_PACKING_COMPLETED_RESULT = 'confirmPackingCompletedResult',
  REGENERATE_TRANSPORTATION_OPTIONS_RESULT = 'regenerateTransportationOptionsResult',
}

export interface PromiseState<T> {
  loading: boolean;
  success: boolean;
  error: boolean;
  data: T;
}

const createInitialPromiseState = <T>(initialData: T): PromiseState<T> => ({
  loading: false,
  success: false,
  error: false,
  data: initialData,
});

export interface FbaPackingState {
  amazonInboundPlanCreationResult: PromiseState<Record<string, any>>;
  packingOptionsResult: PromiseState<any[]>;
  confirmPackingResult: PromiseState<Record<string, any>>;
  setPackingInfoResult: PromiseState<Record<string, any>>;
  errorsResult: PromiseState<any[]>;
  cancelInboundPlanResult: PromiseState<Record<string, any>>;
  placementsResult: PromiseState<any[]>;
  transportationResult: PromiseState<any[]>;
  shipmentsResult: PromiseState<any[]>;
  confirmPlacementOptionResult: PromiseState<Record<string, any>>;
  getDeliverWindowOptionsResult: PromiseState<any[]>;
  confirmTransportationsOptionsResult: PromiseState<Record<string, any>>;
  confirmDeliveryWindowOptionsResult: PromiseState<Record<string, any>>;
  confirmPackingCompletedResult: PromiseState<Record<string, any>>;
  regenerateTransportationOptionsResult: PromiseState<Record<string, any>>;
}

const initialState: FbaPackingState = {
  [StateKeys.AMAZON_INBOUND_PLAN_CREATION_RESULT]: createInitialPromiseState({}),
  [StateKeys.PACKING_OPTIONS_RESULT]: createInitialPromiseState([]),

  [StateKeys.CONFIRM_PACKING_RESULT]: createInitialPromiseState({}),
  [StateKeys.SET_PACKING_INFO_RESULT]: createInitialPromiseState({}),
  [StateKeys.ERRORS_RESULT]: createInitialPromiseState([]),
  [StateKeys.CANCEL_INBOUND_PLAN_RESULT]: createInitialPromiseState({}),
  [StateKeys.PLACEMENTS_RESULT]: createInitialPromiseState([]),
  [StateKeys.TRANSPORTATION_RESULT]: createInitialPromiseState([]),
  [StateKeys.SHIPMENTS_RESULT]: createInitialPromiseState([]),
  [StateKeys.CONFIRM_TRANSPORTATION_OPTIONS_RESULT]: createInitialPromiseState({}),
  [StateKeys.CONFIRM_PLACEMENT_OPTION_RESULT]: createInitialPromiseState({}),
  [StateKeys.GET_DELIVERY_WINDOW_OPTIONS_RESULT]: createInitialPromiseState([]),
  [StateKeys.CONFIRM_DELIVERY_WINDOW_OPTIONS_RESULT]: createInitialPromiseState({}),
  [StateKeys.CONFIRM_PACKING_COMPLETED_RESULT]: createInitialPromiseState({}),
  [StateKeys.REGENERATE_TRANSPORTATION_OPTIONS_RESULT]: createInitialPromiseState({}),
};

const fbaPackingSlice = createSlice({
  name: 'fbaPacking',
  initialState,
  reducers: {
    resetFbaPackingState: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = { ...initialState[key] };
      });
    },
    resetFbaPackingAmazonInboundPlanCreationResult: (state) => {
      state[StateKeys.AMAZON_INBOUND_PLAN_CREATION_RESULT] = createInitialPromiseState({});
    },
    resetPackingOptionsResult: (state) => {
      state[StateKeys.PACKING_OPTIONS_RESULT] = createInitialPromiseState([]);
    },
    resetConfirmPackingResult: (state) => {
      state[StateKeys.CONFIRM_PACKING_RESULT] = createInitialPromiseState({});
    },
    resetSetPackingInfoResult: (state) => {
      state[StateKeys.SET_PACKING_INFO_RESULT] = createInitialPromiseState({});
    },
    resetErrorInInboundPlanResult: (state) => {
      state[StateKeys.ERRORS_RESULT] = createInitialPromiseState([]);
    },
    resetCancelInboundPlanResult: (state) => {
      state[StateKeys.CANCEL_INBOUND_PLAN_RESULT] = createInitialPromiseState({});
    },
    resetPlacementsResult: (state) => {
      state[StateKeys.PLACEMENTS_RESULT] = createInitialPromiseState([]);
    },
    resetTransportationResult: (state) => {
      state[StateKeys.TRANSPORTATION_RESULT] = createInitialPromiseState([]);
    },
    resetShipmentsResult: (state) => {
      state[StateKeys.SHIPMENTS_RESULT] = createInitialPromiseState([]);
    },
    resetConfirmTransportationOptionResult: (state) => {
      state[StateKeys.CONFIRM_TRANSPORTATION_OPTIONS_RESULT] = createInitialPromiseState({});
    },
    resetConfirmPlacementOptionResult: (state) => {
      state[StateKeys.CONFIRM_PLACEMENT_OPTION_RESULT] = createInitialPromiseState({});
    },
    resetGetDeliveryWindowOptionsResult: (state) => {
      state[StateKeys.GET_DELIVERY_WINDOW_OPTIONS_RESULT] = createInitialPromiseState([]);
    },
    resetConfirmDeliveryWindowOptionsResult: (state) => {
      state[StateKeys.CONFIRM_DELIVERY_WINDOW_OPTIONS_RESULT] = createInitialPromiseState({});
    },
    resetConfirmPackingCompletedResult: (state) => {
      state[StateKeys.CONFIRM_PACKING_COMPLETED_RESULT] = createInitialPromiseState({});
    },
    resetRegenerateTransportationOptionsResult: (state) => {
      state[StateKeys.REGENERATE_TRANSPORTATION_OPTIONS_RESULT] = createInitialPromiseState({});
    },
  },
  extraReducers: (builder) => {
    // Active handlers
    addPromiseHandlers(
      builder,
      startAmazonInboundPlanCreation,
      StateKeys.AMAZON_INBOUND_PLAN_CREATION_RESULT,
    );
    addPromiseHandlers(builder, getPackingOptions, StateKeys.PACKING_OPTIONS_RESULT);

    addPromiseHandlers(builder, confirmPackingOptions, StateKeys.CONFIRM_PACKING_RESULT);
    addPromiseHandlers(builder, setPackingInformation, StateKeys.SET_PACKING_INFO_RESULT);
    addPromiseHandlers(builder, getErrorsInInboundPlan, StateKeys.ERRORS_RESULT);
    addPromiseHandlers(builder, cancelAmazonInboundPlan, StateKeys.CANCEL_INBOUND_PLAN_RESULT);
    addPromiseHandlers(builder, getPlacementsOptions, StateKeys.PLACEMENTS_RESULT);
    addPromiseHandlers(builder, getTransportationOptions, StateKeys.TRANSPORTATION_RESULT);
    addPromiseHandlers(builder, listShipments, StateKeys.SHIPMENTS_RESULT);
    addPromiseHandlers(
      builder,
      confirmTransportationOptions,
      StateKeys.CONFIRM_TRANSPORTATION_OPTIONS_RESULT,
    );
    addPromiseHandlers(builder, confirmPlacementOption, StateKeys.CONFIRM_PLACEMENT_OPTION_RESULT);
    addPromiseHandlers(
      builder,
      getDeliveryWindowOptions,
      StateKeys.GET_DELIVERY_WINDOW_OPTIONS_RESULT,
    );
    addPromiseHandlers(
      builder,
      confirmDeliveryWindowOptions,
      StateKeys.CONFIRM_DELIVERY_WINDOW_OPTIONS_RESULT,
    );
    addPromiseHandlers(
      builder,
      confirmPackingCompleted,
      StateKeys.CONFIRM_PACKING_COMPLETED_RESULT,
    );
    addPromiseHandlers(
      builder,
      regenerateTransportationOptions,
      StateKeys.REGENERATE_TRANSPORTATION_OPTIONS_RESULT,
    );
  },
});

export const {
  resetConfirmPackingResult,
  resetErrorInInboundPlanResult,
  resetFbaPackingAmazonInboundPlanCreationResult,
  resetFbaPackingState,
  resetSetPackingInfoResult,
  resetPackingOptionsResult,
} = fbaPackingSlice.actions;

export default fbaPackingSlice.reducer;
