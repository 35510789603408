import React, { useContext, useEffect, useState } from 'react';
import useFetch from '../hooks/useFetch';
import SlideOver from '../components/common/SlideOver';
import CreateOrganization from '../components/Onboarding/CreateOrganization';
import { Dialog, Listbox, Menu } from '@headlessui/react';
import { EllipsisVerticalIcon, XMarkIcon } from '@heroicons/react/24/outline';
import BaseContext from '../components/common/BaseContext';
import { useUser } from '@clerk/clerk-react';
import Warehouses from '../components/common/Warehouses';
import { USStates, capitalize, roleBasedAccess } from '../components/common/utils';
import { useParams } from 'react-router-dom';
import WarningModalV2 from '../components/common/WarningModalV2';
import Button from '../components/common/Button';

const Organizations = () => {
  const authenticatedFetch = useFetch();
  // const [organization, setOrganization] = useState<Organization>()
  const [invitations, setInvitations] = useState<any>([]);
  const [members, setMembers] = useState<any>([]);
  const [open, setOpen] = useState(false);

  const [roles, setRoles] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [selectedWarehouses, setSelectedWarehouses] = useState([]);
  const [merchants, setMerchants] = useState([]);
  const [selectedMerchants, setSelectedMerchants] = useState([]);
  const [role, setRole] = useState<any>();
  const [email, setEmail] = useState<string>('');
  const { setLoading, setShowNotification, organization, setOrganization, orgRole } =
    useContext(BaseContext);
  const [isShowDeleteUserConfirmation, setIsShowDeleteUserConfirmation] = useState(false);
  const [currentUserId, setCurrentUserId] = useState<string>('');
  const { user } = useUser();
  const params = useParams();

  useEffect(() => {
    if (params.orgId && organization === null) {
      setLoading(true);
      authenticatedFetch('/api/v1/organizations?include=subscription').then((res) => {
        if (res.success && res.data) {
          setOrganization(res.data.find((org) => org.organizationId === params.orgId));
        } else {
          location.replace('/');
        }
        setLoading(false);
      });
    }
  }, []);

  const fetchMembers = () => {
    authenticatedFetch(`/api/v1/organizations/${organization.organizationId}/users`).then((res) => {
      if (res.success) {
        setMembers(res.data);
      }
    });
  };
  const inviteMember = () => {
    authenticatedFetch('/api/v1/user-invitations', {
      method: 'POST',
      body: JSON.stringify({
        email: email,
        organizationId: organization.organizationId,
        role: role.roleId,
        warehouseIds:
          selectedWarehouses.length > 0 ? selectedWarehouses.map((w) => w.warehouseId) : ['*'],
        merchantIds:
          selectedMerchants.length > 0 ? selectedMerchants.map((w) => w.merchantId) : ['*'],
      }),
    }).then((res) => {
      setOpen(false);
      if (res.success) {
        setEmail('');
        setShowNotification({ show: true, type: 'success', content: 'User invited successfully' });
        fetchUserInvitations();
      } else {
        setShowNotification({ show: true, type: 'failure', content: res.errors[0]?.message });
      }
    });
  };

  const removeUser = (userId: string) => {
    setLoading(true);
    authenticatedFetch(
      `/api/v1/organizations/${organization.organizationId}/users/role/${userId}`,
      {
        method: 'DELETE',
      },
    ).then((res) => {
      setOpen(false);
      fetchMembers();
      setLoading(false);
      if (res.success) {
        setShowNotification({ show: true, type: 'success', content: 'User removed successfully' });
        setIsShowDeleteUserConfirmation(false);
      } else {
        setShowNotification({ show: true, type: 'failure', content: res?.message });
        setIsShowDeleteUserConfirmation(false);
      }
    });
  };

  const fetchUserInvitations = () => {
    authenticatedFetch(
      `/api/v1/user-invitations?filter[organizationId]=${organization.organizationId}`,
    ).then((res) => {
      if (res.success) {
        setInvitations(res.data);
      }
    });
  };

  useEffect(() => {
    if (organization) {
      fetchUserInvitations();
      fetchMembers();

      authenticatedFetch(
        `/auth/api/v1/roles?filter[organizationId]=${organization.organizationId}`,
      ).then((res) => {
        if (res.success) {
          setRoles(res.data.filter((role) => role.description !== 'Client'));
          setRole(res.data[0]);
        }
      });

      authenticatedFetch(
        `/api/v1/merchants?filter[organizationId]=${organization.organizationId}`,
      ).then((res) => {
        if (res.success) {
          setMerchants(res.data);
        }
      });

      authenticatedFetch(
        '/api/v1/warehouses?' +
          new URLSearchParams({
            'filter[organizationId]': organization.organizationId,
          }),
      ).then((res) => setWarehouses(res.data));
    }
  }, [organization]);

  const navigation = [
    { name: 'Profile', visible: true },
    { name: 'Users', visible: roleBasedAccess['action.editUsers'].includes(orgRole as any) },
    // { name: 'Warehouses' },
    { name: 'Billing', visible: roleBasedAccess['action.billing'].includes(orgRole as any) },
  ];
  const [selectedNav, setSelectedNav] = useState(navigation[0].name);
  const [editMode, setEditMode] = useState(false);

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const deleteInvitation = (userInvitationId: string) => {
    setLoading(true);
    authenticatedFetch(`/api/v1/user-invitations/${userInvitationId}`, {
      method: 'DELETE',
    }).then((res) => {
      setLoading(false);
      fetch;
      if (res.success) {
        setShowNotification({
          show: true,
          type: 'success',
          content: 'User Invitation deleted successfully',
        });
        fetchUserInvitations();
      } else
        setShowNotification({
          show: true,
          type: 'failure',
          content: res.message || res.errors[0]?.message,
        });
    });
  };
  const resendInvitation = (userInvitationId: string) => {
    setLoading(true);
    authenticatedFetch(`/api/v1/user-invitations/${userInvitationId}/resend`, {
      method: 'POST',
    }).then((res) => {
      setLoading(false);
      if (res.success) {
        setShowNotification({
          show: true,
          type: 'success',
          content: res.message,
        });
        fetchUserInvitations();
      } else {
        setShowNotification({
          show: true,
          type: 'failure',
          content: res.errors[0]?.message,
        });
      }
    });
  };

  return editMode ? (
    <div className='p-8'>
      <CreateOrganization orgData={organization} setEditMode={setEditMode} />
    </div>
  ) : (
    <div className='p-8'>
      <div className='px-4 sm:px-0'>
        <h1 className='text-[#030229] text-[24px] font-bold'>Organization Information</h1>
        <p className='text-[14px]'>
          Edit and manage your organisation details, users and billing for the Ignite Platform
        </p>
      </div>
      <nav className='flex gap-4'>
        {navigation.map(
          (item, index) =>
            item.visible && (
              <div key={index}>
                <button
                  onClick={() => setSelectedNav(item.name)}
                  className={classNames(
                    item.name === selectedNav
                      ? 'text-hopstack-blue-700'
                      : 'text-gray-400 hover:text-gray-800',
                    'group flex gap-x-3 rounded-md py-2 text-sm leading-6 font-semibold',
                  )}
                >
                  {item.name}
                </button>
              </div>
            ),
        )}
      </nav>
      {selectedNav === 'Profile' && organization ? (
        <>
          <div className='mt-6 border-t border-gray-100'>
            <dl className='divide-y divide-gray-100'>
              <div className='px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0'>
                <dt className='text-sm font-medium leading-6 text-gray-900'>Name</dt>
                <dd className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>
                  {organization?.name}
                </dd>
              </div>
              <div className='px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0'>
                <dt className='text-sm font-medium leading-6 text-gray-900'>Email</dt>
                <dd className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>
                  {organization?.email}
                </dd>
              </div>
              <div className='px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0'>
                <dt className='text-sm font-medium leading-6 text-gray-900'>Billing Address</dt>
                <dd className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>
                  {organization?.billingAddress ? (
                    <>
                      {[
                        organization.billingAddress.line1,
                        organization.billingAddress.line2,
                        organization.billingAddress.country?.code === 'US'
                          ? USStates.find(
                              (state) => state.abbreviation === organization.billingAddress.state,
                            )?.name || organization.billingAddress.state
                          : organization.billingAddress.state,
                        organization.billingAddress.country?.name,
                      ]
                        .filter(Boolean)
                        .join(', ')}
                    </>
                  ) : (
                    'No address available'
                  )}
                </dd>
              </div>
              <div className='px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0'>
                <dt className='text-sm font-medium leading-6 text-gray-900'>Mobile Number</dt>
                <dd className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>
                  +
                  {organization?.mobileNumber.countryCode + ' ' + organization?.mobileNumber.number}
                </dd>
              </div>
              <div className='px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0'>
                <dt className='text-sm font-medium leading-6 text-gray-900'>Organization ID</dt>
                <dd className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>
                  {organization?.organizationId}
                </dd>
              </div>
              <div className='py-3 flex gap-2'>
                <Button
                  onClick={() => setEditMode(true)}
                  className=' w-fit flex gap-2 px-8 py-2 text-sm font-semibold text-white shadow-sm '
                >
                  Edit
                </Button>
              </div>
            </dl>
            <div className='w-full h-[1px] bg-gray-200 my-4' />
            <Warehouses warehouses={warehouses} />
          </div>
        </>
      ) : selectedNav === 'Users' ? (
        <div>
          <div className='overflow-visible shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg'>
            <table className='min-w-full divide-y divide-gray-300'>
              <thead className='bg-gray-50'>
                <tr>
                  <th
                    scope='col'
                    className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                  >
                    Name
                  </th>
                  <th
                    scope='col'
                    className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                  >
                    Role
                  </th>
                  <th
                    scope='col'
                    className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                  >
                    Status
                  </th>
                  <th
                    scope='col'
                    className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200 bg-white '>
                <WarningModalV2
                  isOpen={isShowDeleteUserConfirmation}
                  onClose={() => setIsShowDeleteUserConfirmation(!isShowDeleteUserConfirmation)}
                  onDelete={() => removeUser(currentUserId)}
                />
                {members.map((member, index) => (
                  <tr className={`${index % 2 === 0 ? '' : 'bg-[#F9FAFB]'} `} key={index}>
                    <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 '>
                      {member?.email}
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      {member?.roleName}
                    </td>

                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      {'Accepted'}
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      <Menu as={'div'} className='relative overflow-visible'>
                        <div>
                          <Menu.Button className=''>
                            <EllipsisVerticalIcon className='w-8 h-8 px-1' />
                          </Menu.Button>
                        </div>
                        <Menu.Items className='absolute z-20 overflow-visible top-10 p-1 -ml-24 w-40 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none'>
                          <div>
                            <Menu.Item
                              as='div'
                              className={`p-2 hover:bg-gray-100 ${
                                member.authServiceUserId === user.id
                                  ? 'opacity-50 hover:opacity-50 cursor-not-allowed'
                                  : ''
                              }`}
                              disabled={member.authServiceUserId === user.id}
                            >
                              <button
                                onClick={() => {
                                  setIsShowDeleteUserConfirmation(true),
                                    setCurrentUserId(String(member.userId));
                                }}
                                className={`text-red-500 font-bold hover:opacity-80 ${
                                  member.authServiceUserId === user.id
                                    ? 'opacity-50 hover:opacity-50 cursor-not-allowed'
                                    : ''
                                }`}
                                disabled={member.authServiceUserId === user.id}
                              >
                                Remove
                              </button>
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Menu>
                    </td>
                  </tr>
                ))}
                {invitations
                  .filter((i) => i.status !== 'accepted')
                  .map((invitation) => (
                    <tr className='bg-gray-100 w-full' key={invitation?.email}>
                      <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 opacity-60'>
                        {invitation?.email}
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        {capitalize(invitation?.role)}
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        {capitalize(invitation?.status)}
                      </td>

                      <td className='px-3 text-md'>
                        <Menu as={'div'} className='relative overflow-visible'>
                          <div>
                            <Menu.Button className=''>
                              <EllipsisVerticalIcon className='w-8 h-8 px-1' />
                            </Menu.Button>
                          </div>
                          <Menu.Items className='absolute z-20 overflow-visible top-10 p-1 -ml-24 w-40 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none'>
                            <Menu.Item
                              as='div'
                              className='p-2 hover:bg-gray-100'
                              onClick={() => deleteInvitation(invitation?.userInvitationId)}
                            >
                              <button className='text-red-500 font-bold text-sm hover:opacity-80'>
                                Delete Invitation
                              </button>
                            </Menu.Item>
                            <Menu.Item
                              as='div'
                              className='p-2 hover:bg-gray-100'
                              onClick={() => resendInvitation(invitation?.userInvitationId)}
                            >
                              <button className='text-blue-500 font-bold text-sm hover:opacity-80'>
                                Resend Invitation
                              </button>
                            </Menu.Item>
                          </Menu.Items>
                        </Menu>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <Button className='mt-4' onClick={() => setOpen(true)}>
            Invite other members
          </Button>
        </div>
      ) : selectedNav === 'Warehouses' ? (
        <Warehouses warehouses={warehouses} />
      ) : selectedNav === 'Billing' ? (
        <div className='text-sm'>
          <a
            target='_blank'
            rel='noreferrer'
            href={process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK}
            className='text-hopstack-blue-700'
          >
            Manage my subscription
          </a>{' '}
          (this will redirect you to an external page managed by Stripe, our payments processor)
        </div>
      ) : (
        <div></div>
      )}

      <SlideOver open={open} setOpen={setOpen}>
        <div>
          <div className='flex items-start justify-between'>
            <Dialog.Title className='text-base font-semibold leading-6 text-gray-900'>
              {'Invite Member'}
            </Dialog.Title>
            <div className='ml-3 flex h-7 items-center'>
              <button
                type='button'
                className='relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-hopstack-blue-500 focus:ring-offset-2'
                onClick={() => setOpen(false)}
              >
                <span className='absolute -inset-2.5' />
                <span className='sr-only'>Close panel</span>
                <XMarkIcon className='h-6 w-6' aria-hidden='true' />
              </button>
            </div>
          </div>
        </div>
        <div className='relative mt-6 flex-1'>
          <div className='sm:col-span-4'>
            <label
              htmlFor='first-name'
              className='block text-sm font-medium leading-6 text-gray-900'
            >
              Email Address*
            </label>
            <div className='mt-2'>
              <input
                value={email}
                autoComplete='email'
                onChange={(e) => setEmail(e.target.value)}
                className='block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-hopstack-blue-600 sm:text-sm sm:leading-6'
              />
            </div>
          </div>
          <div className='w-full mt-2'>
            <label htmlFor='country' className='block text-sm font-medium leading-6 text-gray-900'>
              Role
            </label>
            <div className='mt-2'>
              <select
                onChange={(e) => setRole(roles[e.target.value])}
                className='block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-hopstack-blue-600  sm:text-sm sm:leading-6'
              >
                {roles?.map((role, index) => {
                  return (
                    <option value={index} key={index}>
                      {role?.description}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          {role?.includeWarehouseIds && (
            <div className='sm:col-span-4 mt-2'>
              <Listbox
                as={'div'}
                value={selectedWarehouses}
                onChange={setSelectedWarehouses}
                multiple
                className={'relative'}
              >
                <Listbox.Label className='block text-sm font-medium leading-6 text-gray-900'>
                  Warehouses
                </Listbox.Label>
                <Listbox.Button
                  className={
                    'block w-full px-2 text-left rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-hopstack-blue-600  sm:text-sm sm:leading-6'
                  }
                >
                  {selectedWarehouses.map((person) => person.name).join(', ') || 'All warehouses'}
                </Listbox.Button>
                <Listbox.Options className='absolute mt-2 z-10 w-full bg-white  border rounded-md'>
                  {warehouses.map((warehouse) => (
                    <Listbox.Option
                      className={`${
                        selectedWarehouses.find((w) => w.warehouseId === warehouse.warehouseId) !==
                        undefined
                          ? 'bg-gray-100'
                          : 'bg-white hover:bg-gray-50'
                      } p-2`}
                      key={warehouse.warehouseId}
                      value={warehouse}
                    >
                      {warehouse.name}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Listbox>
            </div>
          )}

          {role?.includeMerchantIds && (
            <div className='sm:col-span-4 mt-2'>
              <Listbox
                as={'div'}
                value={selectedMerchants}
                onChange={setSelectedMerchants}
                multiple
                className={'relative'}
              >
                <Listbox.Label className='block text-sm font-medium leading-6 text-gray-900'>
                  Clients
                </Listbox.Label>
                <Listbox.Button
                  className={
                    'block w-full px-2 text-left rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-hopstack-blue-600  sm:text-sm sm:leading-6'
                  }
                >
                  {selectedMerchants.map((person) => person.name).join(', ') || 'Select'}
                </Listbox.Button>
                <Listbox.Options className='absolute mt-2 w-full bg-white  border rounded'>
                  {merchants.map((merchant) => (
                    <Listbox.Option
                      className={`${
                        selectedMerchants.find((m) => m.id === merchant.id) !== undefined
                          ? 'bg-gray-100'
                          : 'bg-white'
                      } p-2`}
                      key={merchant.id}
                      value={merchant}
                    >
                      {merchant.name}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Listbox>
            </div>
          )}

          <button
            onClick={() => inviteMember()}
            className='rounded-md my-4 bg-hopstack-blue-700 flex gap-2 px-8 py-2 text-sm font-semibold text-white shadow-sm hover:bg-hopstack-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hopstack-blue-600'
          >
            Send Invitation
          </button>
        </div>
      </SlideOver>
    </div>
  );
};

export default Organizations;
