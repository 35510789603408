export interface IntegrationConnection {
  region: string;
  regionCode: string;
  country: string;
  marketplaceId: string;
  countryCode: string;
  marketplaceUrl: string;
}

export const MARKETPLACE_DATA: IntegrationConnection[] = [
  {
    region: 'North America',
    regionCode: 'NA',
    country: 'Canada',
    marketplaceId: 'A2EUQ1WTGCTBG2',
    countryCode: 'CA',
    marketplaceUrl: 'https://www.amazon.ca',
  },
  {
    region: 'North America',
    regionCode: 'NA',
    country: 'United States of America',
    marketplaceId: 'ATVPDKIKX0DER',
    countryCode: 'US',
    marketplaceUrl: 'https://www.amazon.com',
  },
  {
    region: 'North America',
    regionCode: 'NA',
    country: 'Mexico',
    marketplaceId: 'A1AM78C64UM0Y8',
    countryCode: 'MX',
    marketplaceUrl: 'https://www.amazon.com.mx',
  },
  {
    region: 'North America',
    regionCode: 'NA',
    country: 'Brazil',
    marketplaceId: 'A2Q3Y263D00KWC',
    countryCode: 'BR',
    marketplaceUrl: 'https://www.amazon.com.br',
  },
  {
    region: 'Europe',
    regionCode: 'EU',
    country: 'Spain',
    marketplaceId: 'A1RKKUPIHCS9HS',
    countryCode: 'ES',
    marketplaceUrl: 'https://www.amazon.es',
  },
  {
    region: 'Europe',
    regionCode: 'EU',
    country: 'United Kingdom',
    marketplaceId: 'A1F83G8C2ARO7P',
    countryCode: 'UK',
    marketplaceUrl: 'https://www.amazon.co.uk',
  },
  {
    region: 'Europe',
    regionCode: 'EU',
    country: 'France',
    marketplaceId: 'A13V1IB3VIYZZH',
    countryCode: 'FR',
    marketplaceUrl: 'https://www.amazon.fr',
  },
  {
    region: 'Europe',
    regionCode: 'EU',
    country: 'Belgium',
    marketplaceId: 'AMEN7PMS3EDWL',
    countryCode: 'BE',
    marketplaceUrl: 'https://www.amazon.com.be',
  },
  {
    region: 'Europe',
    regionCode: 'EU',
    country: 'Netherlands',
    marketplaceId: 'A1805IZSGTT6HS',
    countryCode: 'NL',
    marketplaceUrl: 'https://www.amazon.nl',
  },
  {
    region: 'Europe',
    regionCode: 'EU',
    country: 'Germany',
    marketplaceId: 'A1PA6795UKMFR9',
    countryCode: 'DE',
    marketplaceUrl: 'https://www.amazon.de',
  },
  {
    region: 'Europe',
    regionCode: 'EU',
    country: 'Italy',
    marketplaceId: 'APJ6JRA9NG5V4',
    countryCode: 'IT',
    marketplaceUrl: 'https://www.amazon.it',
  },
  {
    region: 'Europe',
    regionCode: 'EU',
    country: 'Sweden',
    marketplaceId: 'A2NODRKZP88ZB9',
    countryCode: 'SE',
    marketplaceUrl: 'https://www.amazon.se',
  },
  {
    region: 'Europe',
    regionCode: 'EU',
    country: 'South Africa',
    marketplaceId: 'AE08WJ6YKNBMC',
    countryCode: 'ZA',
    marketplaceUrl: 'https://www.amazon.com.za',
  },
  {
    region: 'Europe',
    regionCode: 'EU',
    country: 'Poland',
    marketplaceId: 'A1C3SOZRARQ6R3',
    countryCode: 'PL',
    marketplaceUrl: 'https://www.amazon.pl',
  },
  {
    region: 'Europe',
    regionCode: 'EU',
    country: 'Egypt',
    marketplaceId: 'ARBP9OOSHTCHU',
    countryCode: 'EG',
    marketplaceUrl: 'https://www.amazon.eg',
  },
  {
    region: 'Europe',
    regionCode: 'EU',
    country: 'Turkey',
    marketplaceId: 'A33AVAJ2PDY3EV',
    countryCode: 'TR',
    marketplaceUrl: 'https://www.amazon.com.tr',
  },
  {
    region: 'Europe',
    regionCode: 'EU',
    country: 'Saudi Arabia',
    marketplaceId: 'A17E79C6D8DWNP',
    countryCode: 'SA',
    marketplaceUrl: 'https://www.amazon.sa',
  },
  {
    region: 'Europe',
    regionCode: 'EU',
    country: 'United Arab Emirates',
    marketplaceId: 'A2VIGQ35RCS4UG',
    countryCode: 'AE',
    marketplaceUrl: 'https://www.amazon.ae',
  },
  {
    region: 'Europe',
    regionCode: 'EU',
    country: 'India',
    marketplaceId: 'A21TJRUUN4KGV',
    countryCode: 'IN',
    marketplaceUrl: 'https://www.amazon.in',
  },
  {
    region: 'Far East',
    regionCode: 'FE',
    country: 'Singapore',
    marketplaceId: 'A19VAU5U5O7RUS',
    countryCode: 'SG',
    marketplaceUrl: 'https://www.amazon.sg',
  },
  {
    region: 'Far East',
    regionCode: 'FE',
    country: 'Australia',
    marketplaceId: 'A39IBJ37TRP1C6',
    countryCode: 'AU',
    marketplaceUrl: 'https://www.amazon.com.au',
  },
  {
    region: 'Far East',
    regionCode: 'FE',
    country: 'Japan',
    marketplaceId: 'A1VC38T7YXB528',
    countryCode: 'JP',
    marketplaceUrl: 'https://www.amazon.co.jp',
  },
];
export const getMarketplace = (marketplaceId: string) => {
  const marketplace = MARKETPLACE_DATA.find(
    (marketplace) => marketplace.marketplaceId === marketplaceId,
  );
  return marketplace || MARKETPLACE_DATA.find((marketplace) => marketplace.countryCode === 'US');
};

export const amazonCarrierMapping = [
  {
    name: 'FedEx',
    value: 'FEDERAL_EXPRESS_CORP',
  },
  {
    name: 'UPS',
    value: 'UNITED_PARCEL_SERVICE_INC',
  },
  {
    name: 'USPS',
    value: 'UNITED_STATES_POSTAL_SERVICE',
  },
  {
    name: 'DHL',
    value: 'DHL_EXPRESS_USA_INC',
  },
  {
    name: 'Other',
    value: 'OTHER',
  },
];

export const shopifySupportedCarriers = [
  {
    name: '4PX',
    value: '4PX',
  },
  {
    name: 'AGS',
    value: 'AGS',
  },
  {
    name: 'Amazon Logistics UK',
    value: 'Amazon Logistics UK',
  },
  {
    name: 'Amazon Logistics US',
    value: 'Amazon Logistics US',
  },
  {
    name: 'An Post',
    value: 'An Post',
  },
  {
    name: 'Anjun Logistics',
    value: 'Anjun Logistics',
  },
  {
    name: 'APC',
    value: 'APC',
  },
  {
    name: 'Asendia USA',
    value: 'Asendia USA',
  },
  {
    name: 'Australia Post',
    value: 'Australia Post',
  },
  {
    name: 'Bonshaw',
    value: 'Bonshaw',
  },
  {
    name: 'BPost',
    value: 'BPost',
  },
  {
    name: 'BPost International',
    value: 'BPost International',
  },
  {
    name: 'Canada Post',
    value: 'Canada Post',
  },
  {
    name: 'Canpar',
    value: 'Canpar',
  },
  {
    name: 'CDL Last Mile',
    value: 'CDL Last Mile',
  },
  {
    name: 'China Post',
    value: 'China Post',
  },
  {
    name: 'Chronopost',
    value: 'Chronopost',
  },
  {
    name: 'Chukou1',
    value: 'Chukou1',
  },
  {
    name: 'Colissimo',
    value: 'Colissimo',
  },
  {
    name: 'Comingle',
    value: 'Comingle',
  },
  {
    name: 'Coordinadora',
    value: 'Coordinadora',
  },
  {
    name: 'Correios',
    value: 'Correios',
  },
  {
    name: 'Correos',
    value: 'Correos',
  },
  {
    name: 'CTT',
    value: 'CTT',
  },
  {
    name: 'CTT Express',
    value: 'CTT Express',
  },
  {
    name: 'Cyprus Post',
    value: 'Cyprus Post',
  },
  {
    name: 'Delnext',
    value: 'Delnext',
  },
  {
    name: 'Deutsche Post',
    value: 'Deutsche Post',
  },
  {
    name: 'DHL eCommerce',
    value: 'DHL eCommerce',
  },
  {
    name: 'DHL eCommerce Asia',
    value: 'DHL eCommerce Asia',
  },
  {
    name: 'DHL Express',
    value: 'DHL Express',
  },
  {
    name: 'DPD',
    value: 'DPD',
  },
  {
    name: 'DPD Local',
    value: 'DPD Local',
  },
  {
    name: 'DPD UK',
    value: 'DPD UK',
  },
  {
    name: 'DTD Express',
    value: 'DTD Express',
  },
  {
    name: 'DX',
    value: 'DX',
  },
  {
    name: 'Eagle',
    value: 'Eagle',
  },
  {
    name: 'Estes',
    value: 'Estes',
  },
  {
    name: 'Evri',
    value: 'Evri',
  },
  {
    name: 'FedEx',
    value: 'FedEx',
  },
  {
    name: 'First Global Logistics',
    value: 'First Global Logistics',
  },
  {
    name: 'First Line',
    value: 'First Line',
  },
  {
    name: 'FSC',
    value: 'FSC',
  },
  {
    name: 'Fulfilla',
    value: 'Fulfilla',
  },
  {
    name: 'GLS',
    value: 'GLS',
  },
  {
    name: 'Guangdong Weisuyi Information Technology (WSE)',
    value: 'Guangdong Weisuyi Information Technology (WSE)',
  },
  {
    name: 'Heppner Internationale Spedition GmbH & Co.',
    value: 'Heppner Internationale Spedition GmbH & Co.',
  },
  {
    name: 'Iceland Post',
    value: 'Iceland Post',
  },
  {
    name: 'IDEX',
    value: 'IDEX',
  },
  {
    name: 'Israel Post',
    value: 'Israel Post',
  },
  {
    name: 'Japan Post (EN)',
    value: 'Japan Post (EN)',
  },
  {
    name: 'Japan Post (JA)',
    value: 'Japan Post (JA)',
  },
  {
    name: 'La Poste',
    value: 'La Poste',
  },
  {
    name: 'Lasership',
    value: 'Lasership',
  },
  {
    name: 'Latvia Post',
    value: 'Latvia Post',
  },
  {
    name: 'Lietuvos Paštas',
    value: 'Lietuvos Paštas',
  },
  {
    name: 'Logisters',
    value: 'Logisters',
  },
  {
    name: 'Lone Star Overnight',
    value: 'Lone Star Overnight',
  },
  {
    name: 'M3 Logistics',
    value: 'M3 Logistics',
  },
  {
    name: 'Meteor Space',
    value: 'Meteor Space',
  },
  {
    name: 'Mondial Relay',
    value: 'Mondial Relay',
  },
  {
    name: 'New Zealand Post',
    value: 'New Zealand Post',
  },
  {
    name: 'NinjaVan',
    value: 'NinjaVan',
  },
  {
    name: 'North Russia Supply Chain (Shenzhen) Co.',
    value: 'North Russia Supply Chain (Shenzhen) Co.',
  },
  {
    name: 'OnTrac',
    value: 'OnTrac',
  },
  {
    name: 'Packeta',
    value: 'Packeta',
  },
  {
    name: 'Pago Logistics',
    value: 'Pago Logistics',
  },
  {
    name: 'Ping An Da Tengfei Express',
    value: 'Ping An Da Tengfei Express',
  },
  {
    name: 'Pitney Bowes',
    value: 'Pitney Bowes',
  },
  {
    name: 'Portal PostNord',
    value: 'Portal PostNord',
  },
  {
    name: 'Poste Italiane',
    value: 'Poste Italiane',
  },
  {
    name: 'PostNL',
    value: 'PostNL',
  },
  {
    name: 'PostNord DK',
    value: 'PostNord DK',
  },
  {
    name: 'PostNord NO',
    value: 'PostNord NO',
  },
  {
    name: 'PostNord SE',
    value: 'PostNord SE',
  },
  {
    name: 'Purolator',
    value: 'Purolator',
  },
  {
    name: 'Qxpress',
    value: 'Qxpress',
  },
  {
    name: 'Qyun Express',
    value: 'Qyun Express',
  },
  {
    name: 'Royal Mail',
    value: 'Royal Mail',
  },
  {
    name: 'Royal Shipments',
    value: 'Royal Shipments',
  },
  {
    name: 'Sagawa (EN)',
    value: 'Sagawa (EN)',
  },
  {
    name: 'Sagawa (JA)',
    value: 'Sagawa (JA)',
  },
  {
    name: 'Sendle',
    value: 'Sendle',
  },
  {
    name: 'SF Express',
    value: 'SF Express',
  },
  {
    name: 'SFC Fulfillment',
    value: 'SFC Fulfillment',
  },
  {
    name: 'SHREE NANDAN COURIER',
    value: 'SHREE NANDAN COURIER',
  },
  {
    name: 'Singapore Post',
    value: 'Singapore Post',
  },
  {
    name: 'Southwest Air Cargo',
    value: 'Southwest Air Cargo',
  },
  {
    name: 'StarTrack',
    value: 'StarTrack',
  },
  {
    name: 'Step Forward Freight',
    value: 'Step Forward Freight',
  },
  {
    name: 'Swiss Post',
    value: 'Swiss Post',
  },
  {
    name: 'TForce Final Mile',
    value: 'TForce Final Mile',
  },
  {
    name: 'Tinghao',
    value: 'Tinghao',
  },
  {
    name: 'TNT',
    value: 'TNT',
  },
  {
    name: 'Toll IPEC',
    value: 'Toll IPEC',
  },
  {
    name: 'United Delivery Service',
    value: 'United Delivery Service',
  },
  {
    name: 'UPS',
    value: 'UPS',
  },
  {
    name: 'USPS',
    value: 'USPS',
  },
  {
    name: 'Venipak',
    value: 'Venipak',
  },
  {
    name: 'We Post',
    value: 'We Post',
  },
  {
    name: 'Whistl',
    value: 'Whistl',
  },
  {
    name: 'Wizmo',
    value: 'Wizmo',
  },
  {
    name: 'WMYC',
    value: 'WMYC',
  },
  {
    name: 'Xpedigo',
    value: 'Xpedigo',
  },
  {
    name: 'XPO Logistics',
    value: 'XPO Logistics',
  },
  {
    name: 'Yamato (EN)',
    value: 'Yamato (EN)',
  },
  {
    name: 'Yamato (JA)',
    value: 'Yamato (JA)',
  },
  {
    name: 'YiFan Express',
    value: 'YiFan Express',
  },
  {
    name: 'YunExpress',
    value: 'YunExpress',
  },
];
