import { ArrowRightIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { Link } from 'react-router-dom';
import Shimmer from '../common/Shimmer';

interface CountItem {
  label: string;
  description: JSX.Element;
  value: number;
  period: string;
}
const CountOverview = ({
  item,
  isLoading,
  path,
}: {
  item: CountItem;
  isLoading: boolean;
  path: string;
}) => {
  return (
    <div className='bg-hopstack-blue-50 p-4 rounded-lg '>
      <div className='text-gray-700 font-medium text-lg mb-4'>{item.label}</div>
      {isLoading ? (
        <Shimmer
          key={'count.' + item.label}
          height='h-[11.3rem]'
          className='rounded-md shadow-lg'
          backgroundColor='bg-slate-300 opacity-50'
        />
      ) : (
        <div className='bg-white pl-4 pr-3 py-4 rounded-2xl shadow-lg'>
          {/* More Button */}
          <Link
            to={path}
            className='text-gray-600 text-sm inline-flex justify-end w-full font-semibold items-center gap-1'
          >
            More <ArrowRightIcon className='h-[12px] stroke-[3px] text-gray-400 mt-[2px]' />
          </Link>

          <div className='pb-5 -mt-2'>
            <div className='text-gray-700 font-medium text-lg'>Total Count</div>
            <div className='text-gray-800 text-sm h-8'>{item.description}</div>
          </div>
          {isLoading ? (
            'Loading...'
          ) : (
            <div className='flex items-center gap-5 mt-4 -mb-5'>
              <div className='text-2xl font-bold'>{item.value}</div>
            </div>
          )}

          <div className='text-gray-700 text-sm inline-flex justify-end -mr-10 w-full'>
            {item.period}
          </div>
        </div>
      )}
    </div>
  );
};

export default CountOverview;
