import React, { useState, useRef, useEffect, ReactNode } from 'react';
import { ChevronUpDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid';

interface SearchableDropdownProps<T> {
  options: T[];
  onChange: (selectedOptions: T | T[]) => void;
  getOptionLabel: (option: T) => string;
  getOptionValue: (option: T) => string | number;
  renderOption: (option: T, isSelected: boolean) => ReactNode;
  placeholder?: string;
  selectedValueRenderer?: (selectedOptions: T | T[]) => ReactNode;
  isMulti?: boolean;
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  selectedOptions: T | T[];
  setSelectedOptions: React.Dispatch<React.SetStateAction<T | T[]>>;
}

function SearchableDropdown<T>({
  options,
  onChange,
  getOptionLabel,
  getOptionValue,
  renderOption,
  placeholder = 'Select options...',
  selectedValueRenderer,
  isMulti = false,
  query,
  setQuery,
  selectedOptions,
  setSelectedOptions,
}: SearchableDropdownProps<T>) {
  const [isOpen, setIsOpen] = useState(false);
  // const [selectedOptions, setSelectedOptions] = useState<T | T[]>(isMulti ? [] : null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const toggleOption = (option: T) => {
    if (isMulti) {
      setSelectedOptions((prev: T[]) => {
        const isSelected = prev.some((item) => getOptionValue(item) === getOptionValue(option));
        if (isSelected) {
          return prev.filter((item) => getOptionValue(item) !== getOptionValue(option));
        } else {
          return [...prev, option];
        }
      });
    } else {
      setSelectedOptions(option);
      setIsOpen(false);
    }
    setIsOpen(false);
    setQuery('');
  };

  useEffect(() => {
    onChange(selectedOptions);
  }, [selectedOptions]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const renderSelectedValue = () => {
    if (selectedValueRenderer) {
      return selectedValueRenderer(selectedOptions);
    }
    if (isMulti) {
      return (selectedOptions as T[]).length === 0
        ? placeholder
        : `${(selectedOptions as T[]).length} selected`;
    }
    return selectedOptions ? getOptionLabel(selectedOptions as T) : placeholder;
  };

  return (
    <div className='relative w-full' ref={dropdownRef}>
      <button
        type='button'
        className='relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left border border-gray-300 focus:outline-none sm:text-sm'
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className='block truncate'>{renderSelectedValue()}</span>
        <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
          <ChevronUpDownIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
        </span>
      </button>

      {isOpen && (
        <div className='absolute mt-1 w-full rounded-md bg-white shadow-lg z-10'>
          {/* <div className='px-3 py-2'>
            <input
              ref={searchInputRef}
              className='w-full border-gray-300 rounded-md shadow-sm focus:outline-none p-1 '
              placeholder='Search'
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Escape') {
                  setQuery('');
                  e.preventDefault();
                }
              }}
            />
          </div> */}
          <div className='w-full'>
            <label htmlFor='search' className='sr-only'>
              Search
            </label>
            <div className='relative text-gray-400 focus-within:text-gray-600 border border-gray-300 shadow-sm w-full rounded-md'>
              <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
                <MagnifyingGlassIcon className='h-5 w-5' aria-hidden='true' />
              </div>
              <input
                value={query}
                ref={searchInputRef}
                onChange={(e) => setQuery(e.target.value)}
                id='search'
                className='block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 focus:outline-none sm:text-sm sm:leading-6'
                placeholder='Search'
                type='search'
                name='search'
              />
            </div>
          </div>
          <ul className='max-h-60 overflow-auto rounded-md py-1 text-base focus:outline-none sm:text-sm'>
            {options.map((option) => {
              const isSelected = isMulti
                ? (selectedOptions as T[]).some(
                    (item) => getOptionValue(item) === getOptionValue(option),
                  )
                : selectedOptions &&
                  getOptionValue(selectedOptions as T) === getOptionValue(option);
              return (
                <li
                  key={getOptionValue(option)}
                  className='relative cursor-default select-none hover:bg-hopstack-blue-50'
                  onClick={() => toggleOption(option)}
                >
                  {renderOption(option, isSelected)}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}

export default SearchableDropdown;
