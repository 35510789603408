import React from 'react';
import CustomTable from '../components/common/CustomTable';
import AutoCompleteSingleSelect from '../components/common/AutoCompleteSingleSelect';
import dayjs from 'dayjs';

interface DeliveryWindowOption {
  startDate: string;
  endDate: string;
  deliveryWindowOptionId: string;
}

interface TransportOption {
  shipmentId: string;
  shippingSolution: string;
  carrierAppointment?: {
    endTime: string;
  };
}

interface ShipmentData {
  shipmentId: string;
  deliveryWindowOptions?: DeliveryWindowOption[];
  destination?: string;
  fc?: string;
  carrierType?: string;
  units?: number;
}

interface SelectedDeliveryWindow {
  [key: string]: {
    label: string;
    value: string;
  };
}

interface SelectDeliveryOptionsProps {
  data: ShipmentData[];
  selectedDeliveryWindow: SelectedDeliveryWindow;
  setSelectedDeliveryWindow: React.Dispatch<React.SetStateAction<SelectedDeliveryWindow>>;
}

const SelectDeliveryOptions: React.FC<SelectDeliveryOptionsProps> = ({
  data = [],
  selectedDeliveryWindow,
  setSelectedDeliveryWindow,
}) => {
  const selectedPlacementOptionData = JSON.parse(
    localStorage.getItem('selectedPlacementOptionData') || '{}',
  );

  const transportOptions: TransportOption[] = JSON.parse(
    localStorage.getItem('transportationOptions') || '[]',
  );

  const getFilteredDeliveryOptions = (
    deliveryOptions: DeliveryWindowOption[],
    shipmentId: string,
  ) => {
    const transportOption = transportOptions.find((option) => option.shipmentId === shipmentId);

    const carrierAppointmentEndTime = transportOption?.carrierAppointment?.endTime;

    if (carrierAppointmentEndTime) {
      const endTime = dayjs(carrierAppointmentEndTime);
      return deliveryOptions.filter((option) => dayjs(option.endDate).isAfter(endTime));
    }

    return deliveryOptions;
  };

  const formatDeliveryWindow = (start: string, end: string): string => {
    if (!start || !end) {
      return '';
    }
    const formattedStart = dayjs(start).format('MMM D, YYYY');
    const formattedEnd = dayjs(end).format('MMM D, YYYY');
    return `${formattedStart} - ${formattedEnd}`;
  };

  const columns = [
    {
      title: 'Destination',
      key: 'destination',
    },
    {
      title: 'Shipment ID',
      key: 'shipmentId',
    },
    {
      title: 'Carrier Type',
      key: 'carrierType',
    },
    {
      title: 'Delivery Window Selection',
      key: 'deliveryWindowSelection',
      render: (_: any, row: ShipmentData) => {
        const filteredOptions = getFilteredDeliveryOptions(
          row.deliveryWindowOptions || [],
          row.shipmentId,
        );

        const options = filteredOptions.map((option) => ({
          label: formatDeliveryWindow(option.startDate, option.endDate),
          value: option.deliveryWindowOptionId,
        }));

        return (
          <AutoCompleteSingleSelect
            options={options}
            onChange={(selectedOption) => {
              setSelectedDeliveryWindow((prev) => ({
                ...prev,
                [row.shipmentId]: selectedOption,
              }));
            }}
            value={selectedDeliveryWindow[row.shipmentId]?.value || ''}
            labelKey='label'
            valueKey='value'
            placeholder='Select Delivery Window'
          />
        );
      },
    },
  ];

  const tableData = data.map((item) => ({
    ...item,
    destination:
      selectedPlacementOptionData?.shipmentsWithBoxesAndItems?.find(
        (shipment: { shipmentId: string }) => shipment.shipmentId === item.shipmentId,
      )?.destination?.address?.city || '',
    fc:
      selectedPlacementOptionData?.shipmentsWithBoxesAndItems?.find(
        (shipment: { shipmentId: string }) => shipment.shipmentId === item.shipmentId,
      )?.destination?.warehouseId || '',
    carrierType:
      transportOptions.find((option) => option.shipmentId === item.shipmentId)?.shippingSolution ===
      'AMAZON_PARTNERED_CARRIER'
        ? 'Partnered'
        : 'Non-Partnered',
    // units: 20,
  }));

  return (
    <div className='flex h-full flex-col'>
      <div className='flex-1'>
        <CustomTable data={tableData} columns={columns} />
      </div>
    </div>
  );
};

export default SelectDeliveryOptions;
