import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import SlideOver from '../components/common/SlideOver';
import BaseContext from '../components/common/BaseContext';
import {
  ArrowLeftIcon,
  MagnifyingGlassIcon,
  MinusIcon,
  PlusIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { Dialog } from '@headlessui/react';
import { Client, Product, Warehouse } from '../components/common/types';
import { useNavigate, useParams } from 'react-router-dom';

import Button from '../components/common/Button';

import useRequest from '../hooks/useRequest';
import { apiPaths } from '../utils/ApiPaths';
import { RequestParams } from '../hooks/useRequest';
import InboundOrderTable from '../components/Inbound/InbountOrderTable';
import { debounce } from 'lodash';
import { InboundOrder } from '../components/Inbound/types';
import Dropdown from '../components/common/Dropdown';

import SearchableDropdown from '../components/common/SearchableDropdown';
import InboundFilter from '../components/Inbound/InboundFilter';
import { MARKETPLACE_DATA } from '../utils/ConnectionConfig';

const Inbound = () => {
  const [open, setOpen] = useState(false);
  const { setLoading, organization, setShowNotification, setOrganization, loading } =
    useContext(BaseContext);
  const urlParams = useParams();
  const navigate = useNavigate();
  const statuses = ['completed', 'processing', 'unprocessed'];

  const { executeRequest: fetchOrganizationsData } = useRequest(
    `${apiPaths.GET_ORGANIZATIONS}`,
    [],
  );
  const { executeRequest: fetchOrdersData, pending: isOrdersFetching } = useRequest(
    `${apiPaths.INBOUND_ORDERS}`,
    [],
  );

  const { data: warehouses, executeRequest: fetchWarehousesData } = useRequest<Warehouse[]>(
    `${apiPaths.GET_WAREHOUSES}`,
    [],
  );

  const { data: merchants, executeRequest: fetchMerchantsData } = useRequest(
    `${apiPaths.GET_MERCHANTS}`,
    [],
  );
  const { data: products, executeRequest: fetchProductsData } = useRequest(
    `${apiPaths.LISTINGS}`,
    [],
  );
  const { executeRequest: addOrderRequest } = useRequest(`${apiPaths.INBOUND_ORDERS}`, {});

  const [selectedWarehouse, setSelectedWarehouse] = useState<Warehouse>();
  const [selectedMerchant, setSelectedMerchant] = useState<Client>();
  const [orders, setOrders] = useState([]);
  const [productPage, setProductPage] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
  const [orderId, setOrderId] = useState('');
  const [trackingNumber, setTrackingNumber] = useState(null);
  const [clientNotes, setClientNote] = useState(null);
  const [inboundDate, setInboundDate] = useState<string>(new Date().toISOString().substring(0, 10));
  const [selectedMerchants, setSelectedMerchants] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [orderSearchText, setOrderSearchText] = useState('');
  const [hasMore, setHasMore] = useState(false);
  const pageRef = useRef(0);
  const fetchedPagesRef = useRef(new Set<number>());
  const [displayProducts, setDisplayProducts] = useState([]);
  const pendingRequestsRef = useRef<AbortController[]>([]);

  const getParams = useCallback((): RequestParams => {
    const params: RequestParams = {
      queryParams: { include: 'merchant' },
      urlParams: {},
    };

    if (selectedMerchants.length > 0) {
      params.queryParams['filter[merchantId]'] = selectedMerchants
        .map((merchant) => merchant.merchantId)
        .join(',');
    } else {
      params.queryParams['filter[merchantId]'] = merchants
        .map((merchant) => merchant.merchantId)
        .join(',');
    }

    params.queryParams['filter[warehouseId]'] = warehouses
      .map((warehouse) => warehouse.warehouseId)
      .join(',');

    if (orderSearchText.length > 0) {
      params.queryParams.search = orderSearchText;
    }

    if (selectedStatuses.length > 0) {
      params.queryParams['filter[status]'] = selectedStatuses.map((i) => i.value).join(',');
    }

    return params;
  }, [selectedMerchants, selectedStatuses, orderSearchText, merchants, warehouses]);
  const fetchOrders = useCallback(
    debounce(async (page: number) => {
      if (loading || fetchedPagesRef.current.has(page)) return;
      if (!(merchants.length && warehouses.length)) return;

      // Create a new AbortController for this fetch
      const controller = new AbortController();
      const { signal } = controller;

      try {
        // If it's the initial fetch (page 0), cancel all pending requests
        if (page === 0) {
          setOrders([]);
          // Cancel all pending requests
          pendingRequestsRef.current.forEach((controller) => controller.abort());
          pendingRequestsRef.current = [];
        }

        // Add this controller to the list of pending requests
        pendingRequestsRef.current.push(controller);

        const params = getParams();
        params.queryParams = {
          ...params.queryParams,
          limit: '50',
          page: (page + 1).toString(),
        };

        const ordersDataResponse = await fetchOrdersData(params, { signal });

        if (ordersDataResponse.success) {
          setOrders((prevOrders) => {
            if (page === 0) {
              prevOrders = [];
            }
            const newOrders = ordersDataResponse.data.filter(
              (order: InboundOrder) =>
                !prevOrders.some((p) => p.inboundOrderId === order.inboundOrderId),
            );
            return [...prevOrders, ...newOrders];
          });

          setHasMore(ordersDataResponse.data.length > 0);
          fetchedPagesRef.current.add(page);
          pageRef.current = page + 1;
        }

        return ordersDataResponse;
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Fetch aborted');
        } else {
          console.error('Error fetching orders:', error);
        }
      } finally {
        // Remove this controller from the list of pending requests
        pendingRequestsRef.current = pendingRequestsRef.current.filter((c) => c !== controller);
      }
    }, 0),
    [getParams, loading, merchants, warehouses],
  );

  // Reset the fetch state when filter parameters change
  useEffect(() => {
    pageRef.current = 0;
    fetchedPagesRef.current.clear();
    fetchOrders(0);
  }, [selectedMerchants, selectedStatuses, orderSearchText, warehouses, merchants]);

  const addOrder = async () => {
    try {
      const addOrderParams: RequestParams = { queryParams: {}, urlParams: {} };
      setLoading(true);
      const addOrderResponse = await addOrderRequest(addOrderParams, {
        method: 'POST',
        body: JSON.stringify({
          inboundOrderDisplayId: orderId.length > 0 ? orderId : undefined,
          merchantId: selectedMerchant.merchantId,
          warehouseId: selectedWarehouse.warehouseId,
          orderDate: new Date(inboundDate).toISOString(),
          clientNotes: clientNotes,
          trackingNumber,
          expectedDeliveryDate: new Date(inboundDate).toISOString(),
          products: displayProducts.map((product) => {
            return {
              listingId: product.product.listingId,
              expectedQuantity: product.quantity,
            };
          }),
        }),
      });
      setOpen(false);
      if (addOrderResponse.success) {
        setProductPage(false);
        setOrderId('');
        setTrackingNumber(null);
        setClientNote(null);
        setSelectedProducts([]);
        setInboundDate(new Date().toISOString().substring(0, 10));
        setShowNotification({ show: true, type: 'success', content: 'Order created successfully' });
        debounce(async () => {
          setOrders([]);
          await handleFetchOrderAsync();
        }, 300)();
      } else
        setShowNotification({
          show: true,
          type: 'failure',
          content: addOrderResponse.errors[0]?.message,
        });
    } catch (error) {
      console.log('Error fetching orders: ', error);
    } finally {
      setLoading(false);
    }
  };

  const handleOrderClick = (order) => {
    //redirect to order details page
    navigate(`${order.inboundOrderId}`);
  };

  const handleFetchOrderAsync = async () => {
    try {
      fetchedPagesRef.current = new Set<number>();
      await fetchOrders(0);
    } catch (error) {
      console.log('Error fetching orders: ', error);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        if (urlParams.orgId) {
          const organizationParams: RequestParams = {
            queryParams: { include: 'subscription' },
            urlParams: {},
          };
          const organizationsResponse = await fetchOrganizationsData(organizationParams);
          if (
            organizationsResponse.success &&
            organizationsResponse.data &&
            organizationsResponse.data.length > 0
          ) {
            const org = organizationsResponse.data.find(
              (org) => org.organizationId === urlParams.orgId,
            );
            setOrganization(org);
            // if (!org) setMessage(ORDERS_EMPTY_MESSAGE);

            const wareHouseMerchantsParams: RequestParams = {
              queryParams: { 'filter[organizationId]': org.organizationId },
              urlParams: {},
            };
            const [warehousesResponse, merchantsResponse] = await Promise.all([
              fetchWarehousesData(wareHouseMerchantsParams),
              fetchMerchantsData(wareHouseMerchantsParams),
            ]);

            if (warehousesResponse.data.length === 0 || merchantsResponse.data.length === 0) {
              // setMessage(ORDERS_EMPTY_MESSAGE);
            }
            await fetchOrders(0);
            setSelectedWarehouse(warehousesResponse.data[0]);
            setSelectedMerchant(merchantsResponse.data[0]);
          } else {
            location.replace('/');
          }
        }
      } catch (error) {
        console.log('Error fetching organizations', error);
        location.replace('/');
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (warehouses.length && merchants.length) {
      handleFetchOrderAsync();
    }
  }, [selectedMerchants, selectedStatuses, orderSearchText]);

  useEffect(() => {
    const params: RequestParams = { queryParams: {}, urlParams: {} };
    if (selectedMerchant)
      params.queryParams = {
        ...params.queryParams,
        'filter[merchantId]': selectedMerchant?.merchantId,
      };
    if (searchText.length > 0) params.queryParams = { ...params.queryParams, search: searchText };
    if (selectedMerchant) fetchProductsData(params);
  }, [searchText, selectedMerchant]);

  //Helper
  const renderProductOption = (product, isSelected) => {
    return (
      <>
        <div
          className={`${isSelected ? 'bg-green-50' : 'bg-white hover:bg-gray-50'} p-3 truncate`}
          key={product.listingId}
        >
          <div className='flex gap-2'>
            <img
              className='rounded object-scale-down h-10'
              src={product?.images[0]}
              height={40}
              width={40}
            />
            <div>
              <div className='truncate'>{product.name}</div>
              <div className='flex mt-2 gap-2'>
                {product?.marketplaceData?.sellerSku && (
                  <div>
                    <span className='font-bold'>SKU:</span> {product.marketplaceData.sellerSku}
                  </div>
                )}
                {product?.marketplaceData?.asin && (
                  <div>
                    <span className='font-bold'>ASIN:</span> {product.marketplaceData.asin}
                  </div>
                )}
                {product?.marketplaceData?.fnSku && (
                  <div>
                    <span className='font-bold'>FNSKU:</span> {product.marketplaceData.fnSku}
                  </div>
                )}
                {product?.marketplaceData?.marketplaceId && (
                  <div>
                    <span className='font-bold'>Marketplace:</span>{' '}
                    {MARKETPLACE_DATA.find(
                      (marketplace) =>
                        marketplace.marketplaceId === product.marketplaceData.marketplaceId,
                    )?.countryCode || 'Unknown'}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const loadMoreOrders = () => {
    if (!isOrdersFetching && hasMore) {
      fetchOrders(pageRef.current);
    }
  };

  return (
    <div className='p-8 h-screen'>
      <div className='flex justify-between my-2'>
        <div>
          <h1 className='text-[#030229] text-[24px] font-bold'>Inbound Orders</h1>
          <p className='text-[14px]'>Manage all orders and inventory coming into your warehouse</p>
        </div>
        <Button
          onClick={() => {
            setOpen(true);
          }}
        >
          <div>Add Inbound Order</div>
        </Button>
      </div>
      <div className='flex gap-4 my-4'>
        {merchants.length > 0 && statuses.length > 0 && (
          <InboundFilter
            merchants={merchants.map((e) => ({ ...e, label: e.name, value: e.merchantId }))}
            statuses={statuses.map((e) => ({ label: e, value: e }))}
            onApply={(checkedMerchants: any, checkedStatus) => {
              setSelectedMerchants(checkedMerchants);
              setSelectedStatuses(checkedStatus);
            }}
            onReset={() => {}}
          />
        )}
        <div className='w-full'>
          <label htmlFor='search' className='sr-only'>
            Search
          </label>
          <div className='relative text-gray-400 focus-within:text-gray-600 border border-gray-300 shadow-sm w-full rounded-md'>
            <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
              <MagnifyingGlassIcon className='h-5 w-5' aria-hidden='true' />
            </div>
            <input
              value={orderSearchText}
              onChange={(e) => setOrderSearchText(e.target.value)}
              id='search'
              className='block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 focus:outline-none sm:text-sm sm:leading-6'
              placeholder='Search'
              type='search'
              name='search'
            />
          </div>
        </div>
      </div>
      {/* {orders.length > 0 && ( */}
      <div className=' shadow ring-1  ring-black ring-opacity-5 sm:rounded-lg'>
        <InboundOrderTable
          orders={orders}
          fetchMoreData={loadMoreOrders}
          hasMore={hasMore}
          handleOrderClick={handleOrderClick}
          organization={organization}
          isFetching={isOrdersFetching}
        />
      </div>
      {/* {message && <div>{message}</div>} */}
      <SlideOver open={open} setOpen={setOpen} large={true}>
        <div className='flex items-start justify-between'>
          <Dialog.Title className='text-base font-semibold leading-6 text-gray-900'>
            {'Add Inbound Order'}
          </Dialog.Title>
          <div className='flex h-7'>
            <button
              type='button'
              className='relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-hopstack-blue-500 focus:ring-offset-2'
              onClick={() => setOpen(false)}
            >
              <span className='absolute -inset-2.5' />
              <span className='sr-only'>Close panel</span>
              <XMarkIcon className='h-6 w-6' aria-hidden='true' />
            </button>
          </div>
        </div>
        {productPage ? (
          <div>
            <div className='sm:col-span-4 mt-2'>
              <SearchableDropdown<Product>
                options={products}
                onChange={() => {
                  setDisplayProducts(
                    selectedProducts.map((newProd) => {
                      const existingProduct = displayProducts.find(
                        (displayProd) => displayProd.product.listingId === newProd.listingId,
                      );
                      return {
                        product: newProd,
                        quantity: existingProduct ? existingProduct.quantity : 1,
                      };
                    }),
                  );
                }}
                getOptionLabel={(product) => product.name}
                getOptionValue={(product) => product.listingId}
                placeholder='Select products...'
                renderOption={renderProductOption}
                isMulti={true}
                query={searchText}
                setQuery={setSearchText}
                selectedOptions={selectedProducts}
                setSelectedOptions={setSelectedProducts}
              />
            </div>
            <div className='overflow-hidden mt-4 shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg'>
              <table className='min-w-full divide-y divide-gray-300'>
                <thead className='bg-gray-50'>
                  <tr>
                    <th className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'>
                      Image
                    </th>
                    <th className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 '>
                      Product
                    </th>
                    <th className='py-3.5 px-1 text-center  text-sm font-semibold text-gray-900 '>
                      Quantity
                    </th>
                    <th className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {displayProducts.map((p, index) => {
                    return (
                      <tr className=' py-4 mt-2' key={index}>
                        <td className=' pl-5 my-3 inline-flex justify-center text-sm font-medium text-gray-900 '>
                          <img
                            className='rounded object-scale-down h-20'
                            src={p.product?.images[0]}
                            height={50}
                            width={50}
                          />
                        </td>
                        <td className='py-4 pl-4 pr-3 text-sm font-medium  max-w-[20rem] text-gray-900 sm:pl-6'>
                          <div>
                            <div className='text-hopstack-blue-700 truncate'>{p.product.name}</div>
                            <div className='flex mt-2 gap-2'>
                              {p.product.marketplaceData && (
                                <>
                                  {p.product.marketplaceData.sellerSku && (
                                    <div>
                                      {' '}
                                      <span className='font-bold'> SKU :</span>{' '}
                                      {p.product.marketplaceData.sellerSku}
                                    </div>
                                  )}
                                  {p.product.marketplaceData.asin && (
                                    <div>
                                      {' '}
                                      <span className='font-bold'> ASIN :</span>{' '}
                                      {p.product.marketplaceData.asin}
                                    </div>
                                  )}
                                  {p.product.marketplaceData.fnSku && (
                                    <div>
                                      {' '}
                                      <span className='font-bold'> FNSKU :</span>{' '}
                                      {p.product.marketplaceData.fnSku}
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </td>
                        <td className='py-4 px-2 text-sm font-medium text-gray-900 '>
                          <div className='flex gap-2 justify-center '>
                            <button
                              onClick={() => {
                                setDisplayProducts(
                                  displayProducts.map((p, pIndex) => {
                                    if (pIndex === index)
                                      return {
                                        ...p,
                                        quantity: Math.max(1, parseInt(p.quantity) - 1),
                                      };
                                    return p;
                                  }),
                                );
                              }}
                            >
                              <MinusIcon className='w-4 h-4 text-hopstack-blue-700' />
                            </button>
                            <input
                              step={1}
                              min={0}
                              onKeyDown={(evt) =>
                                (evt.keyCode === 190 || evt.keyCode === 189) && evt.preventDefault()
                              }
                              onChange={(e) => {
                                setDisplayProducts(
                                  displayProducts.map((p, pIndex) => {
                                    if (pIndex === index) return { ...p, quantity: e.target.value };
                                    return p;
                                  }),
                                );
                              }}
                              type='number'
                              className='w-[100px] text-center py-2 border rounded-xl text-black [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none'
                              value={p.quantity}
                            />
                            <button
                              onClick={() => {
                                setDisplayProducts(
                                  displayProducts.map((p, pIndex) => {
                                    if (pIndex === index)
                                      return { ...p, quantity: parseInt(p.quantity) + 1 };
                                    return p;
                                  }),
                                );
                              }}
                            >
                              <PlusIcon className='w-4 h-4 text-hopstack-blue-700' />
                            </button>
                          </div>
                        </td>
                        <td className='px-6 m-auto'>
                          <button
                            onClick={() => {
                              setDisplayProducts(
                                displayProducts.filter(
                                  (s) =>
                                    displayProducts[index].product.listingId !==
                                    s.product.listingId,
                                ),
                              );

                              setSelectedProducts(
                                selectedProducts.filter(
                                  (s) => displayProducts[index].product.listingId !== s.listingId,
                                ),
                              );
                            }}
                          >
                            <TrashIcon className='w-8 h-8 hover:text-red-600 text-red-400' />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className='flex gap-2 my-4'>
              <button
                onClick={() => 
                  {
                    setProductPage(false),
                    setSelectedProducts([]);
                  }
                }
                className='rounded-md bg-hopstack-blue-600 flex gap-2 px-4 py-2 h-fit text-sm font-semibold text-white shadow-sm hover:bg-hopstack-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hopstack-blue-600'
              >
                <ArrowLeftIcon className='w-4 h-4 my-auto' />
                Go Back
              </button>
              <button
                onClick={() => addOrder()}
                className='rounded-md bg-hopstack-blue-700 flex gap-2 px-8 py-2 text-sm font-semibold text-white shadow-sm hover:bg-hopstack-blue-700/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hopstack-blue-600'
              >
                Confirm
              </button>
            </div>
          </div>
        ) : (
          <div>
            <div className='relative mt-6 grid grid-cols-2 gap-3'>
              {warehouses.length !== 1 && (
                <div className='w-full'>
                  <label
                    htmlFor='country'
                    className='block text-sm font-medium leading-6 text-gray-900'
                  >
                    Warehouse Name
                  </label>
                  <Dropdown<Warehouse>
                    items={warehouses}
                    selectedItem={selectedWarehouse}
                    setSelectedItem={setSelectedWarehouse}
                    getItemDisplayValue={(item) => item.name}
                    getItemId={(item) => item.warehouseId}
                  />
                </div>
              )}
              {merchants.length !== 1 && (
                <div className='w-full'>
                  <label
                    htmlFor='country'
                    className='block text-sm font-medium leading-6 text-gray-900'
                  >
                    Client Name
                  </label>
                  <Dropdown<Client>
                    items={merchants}
                    selectedItem={selectedMerchant}
                    setSelectedItem={setSelectedMerchant}
                    getItemDisplayValue={(item) => item.name}
                    getItemId={(item) => item.merchantId}
                  />
                </div>
              )}

              <div className='sm:col-span-1 w-full'>
                <label className='block text-sm font-medium leading-6 text-gray-900'>
                  Expected Inbound Date
                </label>
                <div className='mt-1'>
                  <input
                    onChange={(e) => {
                      setInboundDate(e.target.value);
                    }}
                    value={inboundDate}
                    type='date'
                    className='block w-full rounded-md border-0 p-[4.5px] text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none'
                  />
                </div>
              </div>

              <div className='sm:col-span-1 w-full'>
                <label className='block text-sm font-medium leading-6 text-gray-900'>
                  Order ID
                </label>
                <div className='mt-1'>
                  <input
                    placeholder={`We'll generate one if empty`}
                    onChange={(e) => setOrderId(e.target.value)}
                    onKeyDown={(event) => {
                      if (event.code === 'Space') event.preventDefault();
                    }}
                    value={orderId}
                    className='block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none'
                  />
                </div>
              </div>

              <div className='sm:col-span-1 w-full'>
                <label className='block text-sm font-medium leading-6 text-gray-900'>
                  Tracking Number
                </label>
                <div className='mt-1'>
                  <input
                    placeholder='Tracking number'
                    onChange={(e) => setTrackingNumber(e.target.value)}
                    onKeyDown={(event) => {
                      if (event.code === 'Space') event.preventDefault();
                    }}
                    value={trackingNumber}
                    className='block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none'
                  />
                </div>
              </div>
            </div>
            <div className='sm:col-span-1 w-full mt-10'>
              <label className='block text-sm font-medium leading-6 text-gray-900'>
                Client Note
              </label>
              <div className='mt-1'>
                <input
                  placeholder='Client note'
                  onChange={(e) => setClientNote(e.target.value)}
                  onKeyDown={(event) => {
                       if (event.code === 'Space' && clientNotes === '') event.preventDefault(); // Prevent leading spaces
                  }}
                  value={clientNotes}
                  className='block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none'
                />
              </div>
            </div>

            <button
              onClick={() => setProductPage(true)}
              className='rounded-md my-4 bg-hopstack-blue-700 flex gap-2 px-8 py-2 text-sm font-semibold text-white shadow-sm hover:bg-hopstack-blue-700/70 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hopstack-blue-600'
            >
              Continue
            </button>
          </div>
        )}
      </SlideOver>
    </div>
  );
};

export default Inbound;
