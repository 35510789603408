// <reference no-default-lib="true"/>
import { useAuth } from '@clerk/clerk-react'

export default function useFetch() {
  const { getToken } = useAuth()
  const baseUrl = process.env.REACT_APP_BACKEND_API_ENDPOINT

  const authenticatedFetch = async (...args: any) => {
    return fetch(baseUrl + args[0], {
      ...args[1],
      headers: {
        Authorization: `Bearer ${await getToken()}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then((res) => res.json())
  }

  return authenticatedFetch
}
