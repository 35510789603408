import React from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Assuming you're using react-router for navigation
import InfiniteScrollingTable, { Column } from '../common/InfiniteScrollingTable';
import { Organization } from '../common/types';

interface FulfillmentOrder {
  fulfillmentId: string;
  marketplaceData: {
    shipmentId: string;
  };
  merchant?: {
    name: string;
  };
  lineItems?: Array<{ quantity: number }>;
  status: 'completed' | 'pending' | string; // Add other possible statuses if needed
}

interface FulfillmentTableProps {
  orders: FulfillmentOrder[];
  organization: Organization;
  handleOrderClick: (order: FulfillmentOrder) => void;
  fetchMoreData: () => void;
  hasMore: boolean;
  isFetching: boolean;
}

export enum FulfillmentStatus {
  UNPROCESSED = 'unprocessed',
  EXCEPTION = 'exception',
  PROCESSING = 'processing',
  PAUSED = 'paused',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
}

const getFulfillmentStatusColor = (status: FulfillmentStatus) => {
  switch (status) {
    case FulfillmentStatus.UNPROCESSED:
      return 'text-gray-700 bg-gray-100';
    case FulfillmentStatus.EXCEPTION:
      return 'text-red-700 bg-red-100';
    case FulfillmentStatus.PROCESSING:
      return 'text-yellow-700 bg-yellow-100';
    case FulfillmentStatus.PAUSED:
      return 'text-blue-700 bg-blue-100';
    case FulfillmentStatus.COMPLETED:
      return 'text-green-700 bg-green-100';
    case FulfillmentStatus.CANCELLED:
      return 'text-red-700 bg-red-200';
    default:
      return 'text-gray-700 bg-gray-200';
  }
};

const FulfillmentTable: React.FC<FulfillmentTableProps> = ({
  orders,
  organization,
  fetchMoreData,
  hasMore,
  isFetching,
}) => {
  const navigate = useNavigate();
  const columns: Column<FulfillmentOrder>[] = [
    {
      key: 'shipmentId',
      title: 'Shipment ID',
      accessor: (order) => (
        <Link
          className='hover:underline'
          to={`/${organization.organizationId}/fulfillment/${order?.fulfillmentId}`}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          {order?.marketplaceData.shipmentId || order.fulfillmentId}
        </Link>
      ),
    },
    {
      key: 'clientName',
      title: 'Client Name',
      accessor: (order) => order?.merchant?.name || '',
    },
    {
      key: 'numberOfProducts',
      title: 'No. of Products',
      accessor: (order) => <div className='pl-10'>{order?.lineItems?.length || 0}</div>,
    },
    {
      key: 'quantity',
      title: 'Quantity',
      accessor: (order) => (
        <div className='pl-10'>
          {order?.lineItems?.reduce((acc, product) => acc + product.quantity, 0) || 0}
        </div>
      ),
    },
    {
      key: 'status',
      title: 'Status',
      accessor: (order) => (
        <div className='relative group w-fit mx-auto'>
          <div
            className={`py-1 px-3 text-sm mt-2 rounded-full capitalize ${getFulfillmentStatusColor(
              order.status as FulfillmentStatus,
            )}`}
          >
            {order?.status}
          </div>
        </div>
      ),
      headerClassnamePerColumn: 'inline-flex justify-center w-full',
    },
  ];

  const handleOrderClick = (order) => {
    navigate(`${order.fulfillmentId}`);
  };

  return (
    <InfiniteScrollingTable
      onSort={() => {}}
      totalDataCount={orders.length}
      columns={columns}
      data={orders}
      fetchMoreData={fetchMoreData}
      hasMore={hasMore}
      loadingData={isFetching && orders.length === 0}
      onRowClick={handleOrderClick}
    />
  );
};

export default FulfillmentTable;
