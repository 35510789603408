import { createAsyncThunk } from '@reduxjs/toolkit';
import { RequestParams } from '../../hooks/useRequest';
import { makeRequest } from './requestService';

interface ApiThunkParams {
  requestParams?: RequestParams;
  options?: object;
  variables?: {
    input: any;
  };
  successCallback?: (data: any) => void;
  errorCallback?: (error: any) => void;
}

export const createApiThunk = (
  typePrefix: string,
  url: string,
  transformData?: (data: any) => any,
) =>
  createAsyncThunk(
    typePrefix,
    async (
      {
        requestParams,
        options = {},
        variables,
        successCallback,
        errorCallback,
        getToken,
        method = 'POST',
      }: ApiThunkParams & { getToken: () => Promise<string>; method?: string },
      { rejectWithValue },
    ) => {
      try {
        const finalRequestParams = variables
          ? {
              urlParams: { outboundOrderId: variables.input.outboundOrderId },
              queryParams: method === 'GET' ? variables.input : {},
            }
          : requestParams;

        const finalOptions = variables
          ? {
              method,
              ...(method !== 'GET' && { body: JSON.stringify(variables.input) }),
            }
          : options;

        const response = await makeRequest(url, finalRequestParams, finalOptions, getToken);
        const result = transformData ? transformData(response.data) : response.data;

        if (successCallback) {
          successCallback(result);
        }

        return result;
      } catch (error) {
        if (errorCallback) {
          errorCallback(error);
        }
        return rejectWithValue(error.message);
      }
    },
  );

export const addPromiseHandlers = (builder: any, action: any, stateProp: string) => {
  builder
    .addCase(action.pending, (state: any) => {
      state[stateProp] = {
        loading: true,
        success: false,
        error: false,
        data: [],
      };
    })
    .addCase(action.fulfilled, (state: any, action: any) => {
      state[stateProp] = {
        loading: false,
        success: true,
        error: false,
        data: action.payload,
      };
    })
    .addCase(action.rejected, (state: any, action: any) => {
      state[stateProp] = {
        loading: false,
        success: false,
        error: true,
        data: action.payload,
      };
    });
};
