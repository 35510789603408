import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { twMerge } from 'tailwind-merge';

interface ColorClasses {
  loaderColor?: string;
  titleColor?: string;
  descriptionColor?: string;
}

interface LoadingOverlayProps {
  isVisible: boolean;
  loaderTitle?: string;
  description?: string;
  htmlContent?: React.ReactNode;
  loaderClasses?: string;
  spinnerSize?: 'small' | 'default' | 'large';
  colorClasses?: ColorClasses;
}

interface CustomLoadingOverlayProps {
  loadingOverlayProps: LoadingOverlayProps;
}

export const Indicator = ({
  color,
  indicatorClasses = 'h-12 w-12 animate-spin',
  ringWidth = '4',
}: {
  color?: string;
  indicatorClasses?: string;
  ringWidth?: string;
}) => (
  <svg
    className={indicatorClasses}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
  >
    <circle
      className='opacity-25'
      cx='12'
      cy='12'
      r='10'
      stroke={color || 'currentColor'}
      strokeWidth={ringWidth}
    ></circle>
    <path
      className='opacity-75'
      fill={color || 'currentColor'}
      d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
    ></path>
  </svg>
);

const CustomLoadingOverlay: React.FC<CustomLoadingOverlayProps> = ({
  loadingOverlayProps: {
    isVisible,
    loaderTitle,
    description,
    htmlContent,
    loaderClasses,
    spinnerSize,
    colorClasses,
  },
}) => {
  useEffect(() => {
    if (!document.getElementById('custom-loader-portal')) {
      const portalContainer = document.createElement('div');
      portalContainer.id = 'custom-loader-portal';
      document.body.appendChild(portalContainer);
    }

    return () => {
      const portalContainer = document.getElementById('custom-loader-portal');
      if (portalContainer) {
        document.body.removeChild(portalContainer);
      }
    };
  }, []);

  if (!isVisible) return null;

  const LoaderContent = (
    <div className='fixed inset-0 overflow-hidden z-[99999]'>
      <div className='fixed inset-0 flex items-center justify-center font-inter'>
        <div
          className='absolute inset-0 bg-gray-800 bg-opacity-50 backdrop-blur-sm'
          onClick={(e) => e.stopPropagation()}
        />

        <div
          className={twMerge(
            'relative rounded-lg bg-white p-8 shadow-lg min-w-[400px] max-w-[600px]',
            loaderClasses,
          )}
        >
          <div className='flex flex-col items-center space-y-6'>
            <div
              className={twMerge(
                'animate-spin',
                spinnerSize === 'small'
                  ? 'h-6 w-6'
                  : spinnerSize === 'large'
                    ? 'h-16 w-16'
                    : 'h-12 w-12',
              )}
            >
              <Indicator color={colorClasses?.loaderColor || '#f5a623'} />
            </div>
            <div>
              <h3
                className={twMerge(
                  'text-lg font-semibold text-orange-400',
                  colorClasses?.titleColor,
                )}
              >
                {loaderTitle}
              </h3>
              <p
                className={twMerge(
                  'mt-2 text-center text-sm text-gray-500',
                  colorClasses?.descriptionColor,
                )}
              >
                {description}
              </p>
            </div>
          </div>
          {htmlContent}
        </div>
      </div>
    </div>
  );

  return createPortal(
    LoaderContent,
    document.getElementById('custom-loader-portal') || document.body,
  );
};

export default CustomLoadingOverlay;
