import React from 'react';
import ModalV2 from './ModalV2';
import Button from './Button';

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  content: React.ReactNode;
  confirmText?: string;
  cancelText?: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  content,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
}) => {
  return (
    <ModalV2 isOpen={isOpen} onClose={onClose} title={title} maxWidth='740px' className='z-[9999]'>
      <div>
        <p>{content}</p>
        <div className='mt-5 flex w-full items-center justify-end gap-5'>
          <Button className='text-base font-medium' variant='secondary' onClick={onClose}>
            {cancelText}
          </Button>
          <Button className='text-base font-medium' onClick={onConfirm}>
            {confirmText}
          </Button>
        </div>
      </div>
    </ModalV2>
  );
};

export default ConfirmationModal;
