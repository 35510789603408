import React, { useState } from 'react';
import { PlusIcon, MinusIcon } from '@heroicons/react/24/solid';
import Button from '../components/common/Button';
import CustomTable from '../components/common/CustomTable';

interface PrepInstruction {
  prepType: string;
  prepOwner?: string;
}

interface ItemData {
  asin: string;
  fnsku: string;
  labelOwner?: string;
  msku: string;
  quantity: number;
  image?: string;
  prepInstructions?: PrepInstruction[];
}

interface TableItem extends ItemData {
  selected: boolean;
  availableQuantity: number;
}

interface AddItemsInBoxProps {
  itemsData: ItemData[];
  onConfirm: (selectedItems: TableItem[]) => void;
  onCancel: () => void;
  setShowNotification: (notification: { show: boolean; type: string; content: string }) => void;
}

const AddItemsInBox: React.FC<AddItemsInBoxProps> = ({
  itemsData,
  onConfirm,
  onCancel,
  setShowNotification,
}) => {
  const [data, setData] = useState<TableItem[]>(
    itemsData.map((item) => ({
      ...item,
      selected: false,
      availableQuantity: item.quantity,
    })),
  );

  const [searchTerm, setSearchTerm] = useState('');

  const handleQuantityChange = (
    msku: string,
    operation: 'increment' | 'decrement',
    directValue = null,
  ): void => {
    setData((prevData) =>
      prevData.map((item) => {
        if (item.msku === msku) {
          let newQuantity;

          if (directValue !== null) {
            // Handle direct input value
            newQuantity = parseInt(directValue, 10);

            // Handle invalid inputs
            if (isNaN(newQuantity) || newQuantity < 1) {
              return item;
            }
          } else {
            // Handle increment/decrement
            newQuantity =
              operation === 'increment'
                ? item.quantity + 1
                : item.quantity > 1
                  ? item.quantity - 1
                  : 1;
          }

          // Check against availableQuantity
          if (newQuantity > item.availableQuantity) {
            setShowNotification({
              show: true,
              type: 'warning',
              content: 'Quantity cannot exceed available quantity',
            });
            return item;
          }

          return {
            ...item,
            quantity: newQuantity,
          };
        }
        return item;
      }),
    );
  };

  const handleCheckboxChange = (msku: string): void => {
    setData((prevData) =>
      prevData.map((item) => (item.msku === msku ? { ...item, selected: !item.selected } : item)),
    );
  };

  const handleConfirm = () => {
    const selectedItems = data.filter((item) => item.selected && item.quantity > 0);

    if (selectedItems.length > 0) {
      const totalQuantity = selectedItems.reduce((sum, item) => sum + item.quantity, 0);
      setShowNotification({
        show: true,
        type: 'success',
        content: `Successfully added ${totalQuantity} items to box`,
      });
    }
    onConfirm(selectedItems);
  };

  // Add filtered data computation
  const filteredData = data.filter((item) =>
    item.msku.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  // Add search handler
  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const columns = [
    {
      key: 'checkbox',
      title: '',
      render: (_, row) => (
        <input
          type='checkbox'
          className='form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out'
          checked={row.selected}
          onChange={() => handleCheckboxChange(row.msku)}
        />
      ),
    },
    {
      key: 'productInfo',
      title: 'Product Name',
      render: (_, row) => (
        <div className='flex items-center'>
          <img src={row.image} alt={row.msku} className='mr-4 h-16 w-16 rounded-md object-cover' />
          <div>
            <div className='text-base font-medium text-gray-800'>{row.fnsku}</div>
            <div className='text-sm text-gray-500'>SKU: {row.msku}</div>
            <div className='text-sm text-gray-500'>ASIN: {row.asin}</div>
          </div>
        </div>
      ),
    },
    {
      key: 'availableQuantity',
      title: 'Available Quantity',
      render: (_, row) => <span>{row.availableQuantity}</span>,
    },
    {
      key: 'quantity',
      title: 'Quantity',
      render: (_, row) => (
        <div className='flex items-center gap-2'>
          <button
            onClick={() => handleQuantityChange(row.msku, 'decrement')}
            className='rounded border border-gray-300 p-3'
            disabled={row.quantity <= 1}
          >
            <MinusIcon
              className={`h-4 w-4 ${row.quantity <= 1 ? 'text-gray-300' : 'text-primaryAccent'}`}
            />
          </button>

          <input
            type='number'
            value={row.quantity}
            min='1'
            max={row.availableQuantity}
            className='w-20 rounded-md border border-gray-300 px-2 py-1 text-center'
            onChange={(e) => {
              // Remove any negative signs from the input
              const value = e.target.value.replace(/-/g, '');
              handleQuantityChange(row.msku, null, value);
            }}
            onBlur={(e) => {
              // If empty or invalid on blur, reset to 1
              if (!e.target.value || parseInt(e.target.value) < 1) {
                handleQuantityChange(row.msku, null, '1');
              }
            }}
          />

          <button
            onClick={() => handleQuantityChange(row.msku, 'increment')}
            className='rounded border border-gray-300 p-3'
            disabled={row.quantity >= row.availableQuantity}
          >
            <PlusIcon
              className={`h-4 w-4 ${
                row.quantity >= row.availableQuantity ? 'text-gray-300' : 'text-primaryAccent'
              }`}
            />
          </button>
        </div>
      ),
    },
  ];

  return (
    <div className='p-6 font-inter'>
      <div className='mb-4 flex items-center gap-5'>
        <span className='text-sm text-gray-400'>Total No. of Products: {filteredData?.length}</span>
        <span className='text-sm text-gray-400'>
          Total Quantity: {filteredData?.reduce((total, item) => total + item.quantity, 0)}
        </span>
      </div>

      <div className='flex-grow' style={{ height: 'calc(100vh - 18rem)' }}>
        <CustomTable
          columns={columns}
          data={filteredData}
          isSearchable
          searchTerm={searchTerm}
          searchPlaceholder='Search with SKU...'
          onChangeSearchTerm={handleSearch}
        />
      </div>

      <div className='sticky bottom-0 z-30 flex w-full items-center justify-between bg-white p-4'>
        <div className='flex w-full justify-end gap-6'>
          <Button className='text-base font-medium' variant='secondary' onClick={onCancel}>
            Cancel
          </Button>
          <Button
            variant='primary'
            className='text-base font-medium'
            disabled={!data?.some((item) => item.selected && item.quantity > 0)}
            onClick={handleConfirm}
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddItemsInBox;
