import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useFetch from '../hooks/useFetch';
import {
  ArrowRightIcon,
  CheckIcon,
  EllipsisVerticalIcon,
  PencilIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import useDownload from '../hooks/useDownload';
import SlideOver from '../components/common/SlideOver';
import BaseContext from '../components/common/BaseContext';
import { capitalize, classNames, truncate } from '../components/common/utils';
import { Menu } from '@headlessui/react';
import Modal from '../components/common/Modal';
import ServicesTable from '../components/Inbound/ServicesTable';
import ReceivedProductsTable from '../components/Inbound/ReceivedProductsTable';
import ProductDetailsTable from '../components/Inbound/ProductDetailsTable';
import StatusChangeConfirmationModal from '../components/Inbound/StatusChangeConfirmationModal';

export enum InboundOrderStatus {
  Unprocessed = 'unprocessed',
  Processing = 'processing',
  OnHold = 'on-hold',
  Completed = 'completed',
}
const InboundDetail = () => {
  const authenticatedFetch = useFetch();
  const authenticatedDownload = useDownload();
  const [order, setOrder] = useState<any>({
    products: [],
  });
  const navigate = useNavigate();

  const navigation = [{ name: 'Line Items' }, { name: 'Services' }];
  const itemNavigation = [{ name: 'Line Items' }, { name: 'Services' }];

  const [selectedNav, setSelectedNav] = useState(navigation[0].name);
  const [selectedItemNav, setSelectedItemNav] = useState(navigation[0].name);
  const [receivingFlow, setReceivingFlow] = useState(false);
  const [location, setLocation] = useState(null);
  const { setLoading, setShowNotification, organization, setOrganization } =
    useContext(BaseContext);
  const [warehouseNoteModalOpen, setWarehouseNoteModalOpen] = useState(false);
  const [warehouseNote, setWarehouseNote] = useState(null);
  const [isEditingWarehouseNote, setIsEditingWarehouseNote] = useState(false);

  const [clientNote, setClientNote] = useState(null);
  const [isEditingClientNote, setIsEditingClientNote] = useState(false);
  const [clientNoteModalOpen, setClientNoteModalOpen] = useState(false);
  const [backdropClickWhenProcessingData, setBackdropClickWhenProcessingData] = useState(true);
  const params = useParams();

  useEffect(() => {
    if (!backdropClickWhenProcessingData) setReceivingFlow(false);
  }, [backdropClickWhenProcessingData]);

  const updateOrderStatus = async (
    status:
      | InboundOrderStatus.Processing
      | InboundOrderStatus.OnHold
      | InboundOrderStatus.Unprocessed,
  ) => {
    if (order?.status !== status) {
      const response = await authenticatedFetch(`/api/v1/inbound-orders/${params.orderId}/status`, {
        method: 'PATCH',
        body: JSON.stringify({
          status,
        }),
      });
      if (response.success) {
        const statusInCaps = response.data.status.toUpperCase();
        setShowNotification({
          show: true,
          type: 'success',
          content: `Order Marked as ${statusInCaps}!`,
        });
      } else if (!response.success) {
        setShowNotification({
          show: true,
          type: 'failure',
          content: response.message || response.errors[0]?.message,
        });
      }
      await fetchOrder();
    }
  };

  const downloadReports = async (reportType: 'itemList' | 'GRN') => {
    setLoading(true);
    try {
      await authenticatedDownload(reportType, `/api/v1/inbound-orders/${params?.orderId}/report`, {
        method: 'POST',
        body: JSON.stringify({
          reportType,
        }),
      });
    } catch (e) {
      setShowNotification({
        show: true,
        type: 'failure',
        content: e?.message || 'Failed to download the file',
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (params.orgId && organization === null) {
      setLoading(true);
      authenticatedFetch('/api/v1/organizations?include=subscription').then((res) => {
        if (res.success && res.data) {
          setOrganization(res.data.find((org) => org.organizationId === params.orgId));
        } else {
          location.replace('/');
        }
        setLoading(false);
      });
    }
  }, []);

  const fetchOrder = () => {
    setLoading(true);
    return authenticatedFetch(
      `/api/v1/inbound-orders/${params.orderId}?` + new URLSearchParams({ include: 'merchant' }),
    ).then((res) => {
      setLoading(false);
      setOrder(res.data);
    });
  };

  useEffect(() => {
    if (params.orderId) {
      fetchOrder();
    }
  }, [params.orderId]);

  useEffect(() => {
    if (params.orderId && order?.warehouseId) {
      setLoading(true);
      authenticatedFetch(`/api/v1/warehouses/${order.warehouseId}/locations`).then((res) => {
        setLoading(false);
        //set default location with locationType as 'storage'
        const locationId = res.data?.find((location) => location.locationType === 'storage')
          ?.locationId;
        setLocation(locationId);
      });
    }

    setWarehouseNote(order.warehouseNotes || 'N/A');
    setClientNote(order.clientNotes || 'N/A');
  }, [order]);

  const [receivedProducts, setReceivedProducts] = useState([]);
  const newService = {
    serviceName: '',
    listingId: order.products[0]?.listingId,
    serviceCost: {
      value: 0,
      currency: 'USD',
    },
  };
  const [services, setServices] = useState([]);

  const receive = (receiveAll: boolean) => {
    if (checkPerishableExpiryDates()) {
      setShowNotification({
        show: true,
        type: 'failure',
        content: 'Please enter the expiry date for all perishable products.',
      });
      return;
    }

    setLoading(true);

    const productsPayload = receiveAll
      ? order?.products?.map((prod) => ({
          listingId: prod.listingId,
          locationId: location,
          receivedEntries: [
            {
              receivedQuantity: prod.expectedQuantity,
              expiry: prod.expiry || undefined,
            },
          ],
        }))
      : receivedProducts.map((rProduct) => ({
          listingId: rProduct.listingId,
          locationId: location,
          receivedEntries: (rProduct.receivedEntries || []).map((entry) => ({
            receivedQuantity: entry.receivedQuantity,
            expiry: entry.expiry || undefined,
          })),
        }));

    authenticatedFetch(`/api/v1/inbound-orders/${params.orderId}/receive`, {
      method: 'PUT',
      body: JSON.stringify({
        services: services.length > 0 ? services : undefined,
        products: productsPayload,
      }),
    }).then((res) => {
      setLoading(false);
      setReceivingFlow(false);
      fetchOrder();
      if (res.success) {
        setShowNotification({
          show: true,
          type: 'success',
          content: 'Order Received Successfully!',
        });
      } else {
        setShowNotification({
          show: true,
          type: 'failure',
          content: res.message || res.errors[0]?.message,
        });
      }
    });
  };

  const checkPerishableExpiryDates = (): boolean => {
    return (
      receivedProducts?.some((receivedProduct) => {
        if (receivedProduct.marketplaceData?.perishable) {
          return (receivedProduct.receivedEntries || []).some((entry) => !entry.expiry);
        }
        return false;
      }) || false
    );
  };

  const deleteOrder = async () => {
    setLoading(true);
    await authenticatedFetch(`/api/v1/inbound-orders/${params.orderId}`, {
      method: 'DELETE',
    }).then((res) => {
      setLoading(false);
      if (res.success) window.location.replace(`/${organization?.organizationId}/inbound`);
      else
        setShowNotification({
          show: true,
          type: 'failure',
          content: res.message || res.errors[0]?.message,
        });
    });
  };
  const updateOrder = async (obj: any) => {
    setLoading(true);
    await authenticatedFetch(`/api/v1/inbound-orders/${params.orderId}`, {
      method: 'PUT',
      body: JSON.stringify(obj),
    }).then((res) => {
      setLoading(false);
      if (res.success) {
        setWarehouseNoteModalOpen(false);
        setShowNotification({
          show: true,
          type: 'success',
          content: 'Order updated successfully!',
        });
        fetchOrder();
      } else
        setShowNotification({
          show: true,
          type: 'failure',
          content: res.message || res.errors[0]?.message,
        });
    });
  };

  return (
    <div className='p-8'>
      <div className='flex justify-between mt-2'>
        <div className='flex gap-2'>
          <h1
            className='text-[#030229] text-[24px] font-bold hover:underline cursor-pointer'
            onClick={() => {
              navigate(-1);
            }}
          >
            Inbound Orders
          </h1>
          <span className='my-auto font-semibold text-[18px]'>{' > Order Details'}</span>
        </div>
        <div className='flex gap-2'>
          <div className='has-tooltip relative text-gray-600'>
            {/* {order.status !== 'processing' && (
              <span className='tooltip rounded top-12 shadow-xl text-[12px] p-1 bg-gray-400 text-white z-40'>
                {'Orders can be received only when they are in processing state'}
              </span>
            )} */}
            {order?.status !== 'completed' && (
              <button
                onClick={() => {
                  setLoading(true);
                  updateOrderStatus(InboundOrderStatus.Processing)
                    .then(() => {
                      setReceivingFlow(true);
                    })
                    .finally(() => setLoading(false));
                }}
                className={`bg-hopstack-blue-700 flex gap-2 rounded-lg text-white py-2 px-4 hover:opacity-80`}
              >
                <ArrowRightIcon className='w-4 my-auto' />
                <div>Add to Inventory</div>
              </button>
            )}
          </div>
          <Menu as={'div'} className='relative overflow-visible'>
            <div>
              <Menu.Button className=''>
                <EllipsisVerticalIcon className='w-8 h-8 px-1' />
              </Menu.Button>
            </div>
            <Menu.Items className='absolute z-20 text-left overflow-visible top-10 p-2 -ml-24 w-40 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none'>
              <div>
                {/* <Menu.Item as='div' className='p-2 hover:bg-gray-100 border-b'>
                  <button onClick={() => {}} className='flex gap-2 w-full'>
                    <div className='text-[14px]'>Edit Order</div>
                  </button>
                </Menu.Item> */}

                {order?.status === InboundOrderStatus.Unprocessed && (
                  <Menu.Item as='div' className='p-2 hover:bg-gray-100 border-b'>
                    <button onClick={() => deleteOrder()} className='flex gap-2 w-full'>
                      <div className='text-[14px]'>Delete Order</div>
                    </button>
                  </Menu.Item>
                )}

                {![InboundOrderStatus.OnHold, InboundOrderStatus.Completed].includes(
                  order?.status,
                ) && (
                  <Menu.Item as='div' className='p-2 hover:bg-gray-100 border-b'>
                    <button
                      onClick={() => updateOrderStatus(InboundOrderStatus.OnHold)}
                      className='flex gap-2 w-full'
                    >
                      <div className='text-[14px]'>Mark as On Hold</div>
                    </button>
                  </Menu.Item>
                )}

                {order?.status === InboundOrderStatus.OnHold && (
                  <Menu.Item as='div' className='p-2 hover:bg-gray-100 border-b'>
                    <button
                      onClick={() => updateOrderStatus(InboundOrderStatus.Processing)}
                      className='flex gap-2 w-full'
                    >
                      <div className='text-[14px]'>Mark as Processing</div>
                    </button>
                  </Menu.Item>
                )}

                {order?.status === InboundOrderStatus.Processing && (
                  <Menu.Item as='div' className='p-2 hover:bg-gray-100 border-b'>
                    <button onClick={() => receive(true)} className='flex gap-2 w-full'>
                      <div className='text-[14px]'>Add all items to inventory</div>
                    </button>
                  </Menu.Item>
                )}

                <Menu.Item as='div' className='p-2 hover:bg-gray-100 border-b'>
                  <button
                    onClick={() => setWarehouseNoteModalOpen(true)}
                    className='flex gap-2 w-full'
                  >
                    <div className='text-[14px] text-left'>Add warehouse note</div>
                  </button>
                </Menu.Item>

                <Menu.Item as='div' className='p-2 hover:bg-gray-100 border-b'>
                  <button
                    onClick={() => setClientNoteModalOpen(true)}
                    className='flex gap-2 w-full'
                  >
                    <div className='text-[14px] text-left'>Add Client note</div>
                  </button>
                </Menu.Item>

                <Menu.Item as='div' className='p-2 hover:bg-gray-100 border-b'>
                  <button
                    onClick={() => {
                      downloadReports('itemList');
                    }}
                    className='flex gap-2 w-full'
                  >
                    <div className='text-[14px]'>Download item list</div>
                  </button>
                </Menu.Item>

                <Menu.Item as='div' className='p-2 hover:bg-gray-100 border-b'>
                  <button
                    onClick={() => {
                      downloadReports('GRN');
                    }}
                    className='flex text-left gap-2 w-full'
                  >
                    <div className='text-[14px]'>Download Goods Received Notes</div>
                  </button>
                </Menu.Item>
              </div>
            </Menu.Items>
          </Menu>
        </div>
      </div>

      <div className='flex gap-16'>
        <div>
          <div className='text-[#717679] text-[14px]'>Order ID</div>
          <div className='text-[#344053] text-[15px]'>{order?.inboundOrderDisplayId}</div>
        </div>

        <div>
          <div className='text-[#717679] text-[14px]'>Client</div>
          <div className='text-[#344053] text-[15px]'>{order?.merchant?.name}</div>
        </div>

        <div>
          <div className='text-[#717679] text-[14px]'>Status</div>
          <div className='text-[#344053] text-[15px]'>{capitalize(order?.status)}</div>
        </div>

        <div>
          <div className='text-[#717679] text-[14px]'>Tracking number</div>
          <div className='text-[#344053] text-[15px]'>
            {capitalize(order?.trackingNumber || 'NA')}
          </div>
        </div>
      </div>

      <div className='flex w-full gap-12 mt-2'>
        <div className='w-full'>
          <div className='text-[#717679] text-[14px] flex justify-between items-center'>
            <span>Warehouse Notes</span>
          </div>
          <div className='border bg-white rounded-lg p-2 w-full h-28 text-[12px] text-[#4A4F6C] relative'>
            {isEditingWarehouseNote ? (
              <textarea
                className='w-full h-full resize-none focus:outline-none text-[12px] text-[#4A4F6C]'
                value={warehouseNote}
                onChange={(e) => setWarehouseNote(e.target.value)}
              />
            ) : (
              <div className='opacity-70'>{warehouseNote}</div>
            )}
            {!isEditingWarehouseNote && (
              <PencilIcon
                className='h-4 w-4 absolute right-2 top-2 cursor-pointer text-hopstack-blue-500 hover:text-hopstack-blue-700'
                onClick={() => setIsEditingWarehouseNote(true)}
              />
            )}
            {isEditingWarehouseNote && (
              <div className='absolute bottom-2 right-2 flex space-x-2'>
                <button
                  className='p-1  text-hopstack-blue-700 rounded-full  flex items-center justify-center'
                  onClick={() => {
                    updateOrder({ warehouseNotes: warehouseNote });
                    setIsEditingWarehouseNote(false);
                  }}
                  title='Save'
                >
                  <CheckIcon className='h-4 w-4' />
                </button>
                <button
                  className='p-1  text-hopstack-blue-300 rounded-full  flex items-center justify-center'
                  onClick={() => {
                    setWarehouseNote(order?.warehouseNotes || 'N/A');
                    setIsEditingWarehouseNote(false);
                  }}
                  title='Cancel'
                >
                  <XMarkIcon className='h-4 w-4' />
                </button>
              </div>
            )}
          </div>
        </div>
        <div className='w-full'>
          <div className='text-[#717679] text-[14px] flex justify-between items-center'>
            <span>Client Notes</span>
          </div>
          <div className='border bg-white rounded-lg p-2 w-full h-28 text-[12px] text-[#4A4F6C] relative'>
            {isEditingClientNote ? (
              <textarea
                className='w-full h-full resize-none focus:outline-none text-[12px] text-[#4A4F6C]'
                value={clientNote}
                onChange={(e) => setClientNote(e.target.value)}
              />
            ) : (
              <div className='opacity-70'>{clientNote}</div>
            )}
            {!isEditingClientNote && (
              <PencilIcon
                className='h-4 w-4 absolute right-2 top-2 cursor-pointer text-hopstack-blue-500 hover:text-hopstack-blue-700'
                onClick={() => setIsEditingClientNote(true)}
              />
            )}
            {isEditingClientNote && (
              <div className='absolute bottom-2 right-2 flex space-x-2'>
                <button
                  className='p-1  text-hopstack-blue-700 rounded-full  flex items-center justify-center'
                  onClick={() => {
                    updateOrder({ clientNotes: clientNote });
                    setIsEditingClientNote(false);
                  }}
                  title='Save'
                >
                  <CheckIcon className='h-4 w-4' />
                </button>
                <button
                  className='p-1  text-hopstack-blue-300 rounded-full  flex items-center justify-center'
                  onClick={() => {
                    setClientNote(order?.clientNotes || 'N/A');
                    setIsEditingClientNote(false);
                  }}
                  title='Cancel'
                >
                  <XMarkIcon className='h-4 w-4' />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className='flex gap-2 mt-2'>
        {itemNavigation.map((item) => (
          <div key={item.name}>
            <button
              onClick={() => setSelectedItemNav(item.name)}
              className={classNames(
                item.name === selectedItemNav
                  ? 'text-hopstack-blue-700'
                  : 'text-gray-400 hover:text-gray-800',
                'group flex gap-x-3 rounded-md py-2 text-sm leading-6 font-semibold',
              )}
            >
              {item.name}
            </button>
          </div>
        ))}
      </div>

      {selectedItemNav === itemNavigation[0].name ? (
        <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg'>
          <ProductDetailsTable order={order} />
        </div>
      ) : (
        <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg'>
          <table className='min-w-full divide-y divide-gray-300'>
            <thead className='bg-gray-50'>
              <tr>
                <th
                  scope='col'
                  className='py-3.5  pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                >
                  Service Name
                </th>
                <th
                  scope='col'
                  className='py-3.5 pl-4 pr-3 max-w-md text-left text-sm font-semibold text-gray-900 sm:pl-6'
                >
                  Cost
                </th>
              </tr>
            </thead>
            <tbody className='divide-y divide-gray-200 bg-white'>
              {order?.services?.map((product, index) => (
                <tr className='relative' key={index}>
                  <td className=' py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                    {truncate(product?.serviceName)}
                  </td>

                  <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                    {product?.serviceCost?.value + ' ' + product?.serviceCost?.currency}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <StatusChangeConfirmationModal
        isOpen={!backdropClickWhenProcessingData}
        onClose={() => {
          setBackdropClickWhenProcessingData(true);
          setReceivingFlow(true);
        }}
        onConfirm={() => {
          setBackdropClickWhenProcessingData(true);
          setReceivingFlow(false);
          updateOrderStatus(InboundOrderStatus.Unprocessed);
        }}
      />
      <SlideOver open={receivingFlow} setOpen={setBackdropClickWhenProcessingData} large={true}>
        <div className='flex justify-between'>
          <h1 className='font-semibold text-[24px]'>Product Details</h1>
          <button
            onClick={() => {
              receive(true);
            }}
            className='bg-hopstack-blue-700 flex gap-2 rounded-lg text-white py-2 capitalize px-4 hover:opacity-80'
          >
            <div>Add all items to inventory</div>
          </button>
        </div>
        <p className='text-[14px] text-gray-600'>
          Specify the quantity of items received and any services offered for this order
        </p>

        <div className='flex justify-start gap-6'>
          {navigation.map((item) => (
            <div key={item.name}>
              <button
                onClick={() => setSelectedNav(item.name)}
                className={classNames(
                  item.name === selectedNav
                    ? 'text-hopstack-blue-700'
                    : 'text-gray-400 hover:text-gray-800',
                  'group flex gap-x-3 rounded-md py-2 text-sm leading-6 font-semibold',
                )}
              >
                {item.name}
              </button>
            </div>
          ))}
        </div>
        {selectedNav === navigation[0].name ? (
          <div className='rounded-xl border mt-8 max-h-[500px] '>
            <ReceivedProductsTable
              order={order}
              receivedProducts={receivedProducts}
              setReceivedProducts={setReceivedProducts}
              setShowNotification={setShowNotification}
            />
          </div>
        ) : (
          <div>
            <div className='rounded-xl border mt-8 max-h-[500px] '>
              <ServicesTable services={services} setServices={setServices} order={order} />
            </div>
            <button
              onClick={() => setServices([...services, newService])}
              className='bg-hopstack-blue-700 float-right mt-8 flex gap-2 rounded-lg text-white py-2 px-4 hover:opacity-80'
            >
              Add Service
            </button>
          </div>
        )}

        <button
          disabled={receivedProducts.length === 0}
          onClick={() => {
            receive(false);
          }}
          className='bg-hopstack-blue-700 mt-8 flex gap-2 rounded-lg text-white py-2 px-4 hover:opacity-80'
        >
          <div className=''>Complete Receiving</div>
        </button>
      </SlideOver>
      <Modal isOpen={warehouseNoteModalOpen} setIsOpen={setWarehouseNoteModalOpen}>
        <div className='w-[500px]'>
          <h2 className='font-bold text-[17px] text-left'>Add warehouse note</h2>
          <textarea
            value={warehouseNote}
            onChange={(e) => setWarehouseNote(e.target.value)}
            className='w-full border rounded p-2 mt-4'
          />
          <div className='flex gap-4 mt-4 justify-center'>
            <button
              onClick={() => {
                updateOrder({ warehouseNotes: warehouseNote });
                setWarehouseNoteModalOpen(false);
              }}
              className='bg-hopstack-blue-700 rounded-lg text-white py-2 px-4 hover:opacity-80'
            >
              Add
            </button>
            <button onClick={() => setWarehouseNoteModalOpen(false)} className='text-red-500'>
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <Modal isOpen={clientNoteModalOpen} setIsOpen={setClientNoteModalOpen}>
        <div className='w-[500px]'>
          <h2 className='font-bold text-[17px] text-left'>Add Client note</h2>
          <textarea
            value={clientNote}
            onChange={(e) => setClientNote(e.target.value)}
            className='w-full border rounded p-2 mt-4'
          />
          <div className='flex gap-4 mt-4 justify-center'>
            <button
              onClick={() => {
                updateOrder({ clientNotes: clientNote });
                setClientNoteModalOpen(false);
              }}
              className='bg-hopstack-blue-700 rounded-lg text-white py-2 px-4 hover:opacity-80'
            >
              Add
            </button>
            <button onClick={() => setClientNoteModalOpen(false)} className='text-red-500'>
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default InboundDetail;
