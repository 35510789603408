import Button from '../components/common/Button';
import React, { useContext, useEffect, useState, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import BaseContext from '../components/common/BaseContext';
import AddIntegration from '../components/IntegrationManagement/AddIntegration';
import useRequest, { RequestParams } from '../hooks/useRequest';
import { apiPaths } from '../utils/ApiPaths';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { PlusIcon } from '@heroicons/react/20/solid';
import ConnectionTable from '../components/Connections/ConnectionTable';
import debounce from 'lodash/debounce';
import ConnectionFilter from '../components/Connections/ConnectionFilter';

function Connections() {
  const { organization, setLoading, setOrganization } = useContext(BaseContext);

  const [merchants, setMerchants] = useState<any[]>([]);
  const [selectedMerchants, setSelectedMerchants] = useState<any[]>([]);
  const [isIntegrationsModalOpen, setIsIntegrationsModalOpen] = useState(false);
  const [searchText, setSearchText] = useState<string>('');
  const [connections, setConnections] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState(false);

  const pageRef = useRef(0);
  const fetchedPagesRef = useRef(new Set());

  const { executeRequest: fetchOrganizationsData } = useRequest(apiPaths.GET_ORGANIZATIONS, []);
  const { executeRequest: fetchMerchantsData } = useRequest(apiPaths.GET_MERCHANTS, []);
  const { executeRequest: fetchIntegrationsData, pending: isFetching } = useRequest(
    apiPaths.GET_INTEGRATIONS,
    [],
  );

  const params = useParams();

  const handleFetchMerchants = async (org) => {
    if (!org) return;

    try {
      const merchantsResponse = await fetchMerchantsData({
        queryParams: { 'filter[organizationId]': org?.organizationId },
        urlParams: {},
      });

      if (merchantsResponse.success) {
        setMerchants(merchantsResponse.data);
      }
    } catch (error) {
      console.error('Error while fetching merchants data:', error);
    }
  };

  const getParams = useCallback((): RequestParams => {
    const params: RequestParams = {
      queryParams: {
        include: 'merchant',
      },
      urlParams: {},
    };

    if (selectedMerchants.length > 0) {
      params.queryParams['filter[merchantId]'] = selectedMerchants
        .map((merchant) => merchant.merchantId)
        .join(',');
    } else {
      params.queryParams['filter[merchantId]'] = merchants
        .map((merchant) => merchant.merchantId)
        .join(',');
    }

    if (searchText.length > 0) {
      params.queryParams.search = searchText;
    }

    return params;
  }, [merchants, selectedMerchants, searchText]);

  useEffect(() => {
    const fetchOrganizations = async () => {
      if (params.orgId && organization === null) {
        try {
          setLoading(true);

          const organizationParams: RequestParams = {
            queryParams: { include: 'subscription' },
            urlParams: {},
          };

          const organizationsResponse = await fetchOrganizationsData(organizationParams);

          if (organizationsResponse.success && organizationsResponse.data) {
            const filteredOrg = organizationsResponse?.data.find(
              (org) => org.organizationId === params.orgId,
            );
            setOrganization(filteredOrg);
            handleFetchMerchants(filteredOrg);
          } else {
            location.replace('/');
          }
        } catch (error) {
          console.error('Error fetching organizations:', error);
          location.replace('/');
        } finally {
          setLoading(false);
        }
      } else if (organization) handleFetchMerchants(organization);
    };

    fetchOrganizations();
  }, []);

  const fetchConnections = useCallback(
    debounce(async (page: number) => {
      console.log(':::', isFetching, fetchedPagesRef.current.has(page), merchants.length);
      if (isFetching || fetchedPagesRef.current.has(page)) return;
      if (!merchants.length) return;

      try {
        const params = getParams();
        params.queryParams = {
          ...params.queryParams,
          limit: '20',
          page: (page + 1).toString(),
        };

        if (page === 0) {
          setConnections([]);
        }

        const integrationsResponse = await fetchIntegrationsData(params);

        if (integrationsResponse.success) {
          setConnections((prevConnections) => {
            const newConnections = integrationsResponse.data.filter(
              (connection: any) =>
                !prevConnections.some((p) => p.integrationId === connection.integrationId),
            );
            return [...prevConnections, ...newConnections];
          });

          setHasMore(integrationsResponse.data.length > 0);
          fetchedPagesRef.current.add(page);
          pageRef.current = page + 1;
        }
      } catch (error) {
        console.error('Error while fetching integrations', error);
      }
    }, 300),
    [getParams, isFetching, merchants],
  );

  useEffect(() => {
    pageRef.current = 0;
    fetchedPagesRef.current.clear();
    fetchConnections(0);
  }, [searchText, merchants, selectedMerchants]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const fetchMoreData = () => {
    if (!isFetching && hasMore) {
      fetchConnections(pageRef.current);
    }
  };

  return (
    <>
      <div className='w-full h-full p-8'>
        <div className='flex justify-between items-center mb-2 h-12'>
          <div>
            <h1 className='text-[#030229] text-[24px] font-bold'>Connections</h1>
            <p className='text-[14px]'>
              Manage the connections with your marketplaces, shipping carriers and other external
              service providers.
            </p>
          </div>
          <Button onClick={() => setIsIntegrationsModalOpen(true)}>
            <div className='inline-flex justify-between'>
              <PlusIcon height={20} className='my-auto mr-2' />
              Add New Connection
            </div>
          </Button>
        </div>
        <AddIntegration
          merchants={merchants}
          isIntegrationsModalOpen={isIntegrationsModalOpen}
          setIsIntegrationsModalOpen={setIsIntegrationsModalOpen}
          fetchIntegrations={fetchConnections}
        />

        <div className='flex gap-3 py-2 mt-8'>
          <ConnectionFilter
            merchants={merchants.map((e) => ({ ...e, label: e.name, value: e.merchantId }))}
            onApply={(checkedMerchants: any) => {
              setSelectedMerchants(checkedMerchants);
            }}
            onReset={() => {}}
          />
          <div className='w-full'>
            <div className='relative text-gray-400 focus-within:text-gray-600 border border-gray-300 shadow-lg w-full rounded-md'>
              <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
                <MagnifyingGlassIcon className='h-5 w-5' aria-hidden='true' />
              </div>
              <input
                id='search'
                value={searchText}
                onChange={handleSearchChange}
                className='block w-full h-10 rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 focus:outline-none sm:text-sm sm:leading-6'
                placeholder='Search'
                type='search'
                name='search'
              />
            </div>
          </div>
        </div>

        <div>
          <ConnectionTable
            connections={connections}
            isFetching={isFetching}
            fetchMoreData={fetchMoreData}
            hasMore={hasMore}
          />
        </div>
      </div>
    </>
  );
}

export default Connections;
